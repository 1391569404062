// src/api/objectInformation.js

import axiosInstance from '../utils/axiosInstance';
import { handleApiResponse } from './common';

/**
 * 取得分頁的物件資訊
 * @param {string} route - 如 "admin" / "businessman" / "super"
 * @param {object} params 包含多個參數
 */
export async function getPagedObjectInformation(route, params) {
  const response = await axiosInstance.get(`/${route}/object`, { params });
  return response.data;
}

/**
 * 新增物件資訊
 * @param {string} route - 如 "admin" / "businessman" / "super"
 * @param {object} payload - 物件資訊內容
 */
export async function createObjectInformation(route, payload) {
  const response = await axiosInstance.post(`/${route}/object`, payload);
  return response.data;
}

/**
 * 編輯物件資訊
 * @param {string} route
 * @param {object} payload
 */
export async function updateObjectInformation(route, payload) {
  const response = await axiosInstance.put(`/${route}/object`, payload);
  return response.data;
}

/**
 * 刪除物件資訊
 * @param {string} route
 * @param {number} id
 */
export async function deleteObjectInformation(route, id) {
  const response = await axiosInstance.delete(`/${route}/object?id=${id}`);
  return response.data;
}

/**
 * 所有下拉式選單 (不含 admin/businessman/super，故不做改動)
 */
export async function getAllOptions() {
  const response = await axiosInstance.get('/options');
  return response.data;
}

export async function getAuth(route) {
  const response = await axiosInstance.get(`${route}/auth`);
  return response.data;
}


/**
 * 綁定業務
 * @param {string} route
 * @param {object} payload
 */
export async function bindBusinessMan(route, payload) {
  const response = await axiosInstance.post(`/${route}/bind`, payload);
  return response.data;
}

/**
 * 刪除綁定業務
 * @param {string} route
 * @param {number} id
 * @param {number} objectInformationId
 */
export async function deleteBindBusiness(route, id, objectInformationId) {
  const response = await axiosInstance.delete(`/${route}/removebinding`, {
    params: { id, objectInformationId },
  });
  return response.data;
}

/**
 * 上傳圖片
 * @param {string} route
 * @param {FormData} formData
 */
export async function postObjectImage(route, formData) {
  const response = await axiosInstance.post(`/${route}/objimg`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

/**
 * 取得該物件的圖片
 * @param {string} route
 * @param {number} objectInformationId
 */
export async function getImagesOfObjectInformation(route, objectInformationId) {
  const response = await axiosInstance.get(`/${route}/objimg?objectInformationId=${objectInformationId}`);
  return response.data;
}

/**
 * 排序物件圖片
 * @param {string} route
 * @param {number} objectInformationId
 * @param {Array} images
 */
export async function sortObjectImages(route, objectInformationId, images) {
  const response = await axiosInstance.put(`/${route}/sortimg`, {
    ObjectInformationId: objectInformationId,
    ObjectPhotos: images,
  });
  return response.data;
}

/**
 * 刪除物件圖片
 * @param {string} route
 * @param {number} imageId
 * @param {number} objectInformationId
 */
export async function deleteObjectPhoto(route, imageId, objectInformationId) {
  const response = await axiosInstance.delete(`/${route}/img`, {
    params: { id: imageId, objectInformationId },
  });
  return response.data;
}

/**
 * 編輯物件類型
 * @param {string} route
 * @param {object} payload
 */
export async function updateObjectType(route, payload) {
  const response = await axiosInstance.put(`/${route}/object/type`, payload);
  return response.data;
}

/**
 * 新增物件類型
 * @param {string} route
 * @param {object} payload
 */
export async function createObjectType(route, payload) {
  const response = await axiosInstance.post(`/${route}/object/type`, payload);
  return response.data;
}

/**
 * 所有物件類型
 * @param {string} route
 */
export async function getAllObjectTypes(route) {
  const response = await axiosInstance.get(`/${route}/object/types`);
  return response.data;
}

/**
 * 刪除物件類型
 * @param {string} route
 * @param {number} id
 */
export async function deleteObjectTypes(route, id) {
  const response = await axiosInstance.delete(`/${route}/object/type`, {
    params: { id },
  });
  return response.data;
}

/**
 * 排序物件類型
 * @param {string} route
 * @param {Array} objectTypes
 */
export async function sortObjectTypes(route, objectTypes) {
  const response = await axiosInstance.put(`/${route}/object/type/sort`, {
    objectTypes,
  });
  return response.data;
}

/**
 * 取得「物件法律資訊」列表
 * @param {string} route
 * @param {number} objectLawInformationId
 */
export async function getObjectLawInfomations(route, objectLawInformationId) {
  const response = await axiosInstance.get(`/${route}/object/lawinfo`, {
    params: { objectLawInformationId },
  });
  return response.data;
}

/**
 * 新增「物件法律資訊」
 * @param {string} route
 * @param {object} payload
 */
export async function createObjectLawInfomation(route, payload) {
  const response = await axiosInstance.post(`/${route}/object/lawinfo`, payload);
  return response.data;
}

/**
 * 更新「物件法律資訊」
 * @param {string} route
 * @param {object} payload
 */
export async function updateObjectLawInfomation(route, payload) {
  const response = await axiosInstance.put(`/${route}/object/lawinfo`, payload);
  return response.data;
}

/**
 * 刪除「物件法律資訊」
 * @param {string} route
 * @param {number} id
 */
export async function deleteObjectLawInfomation(route, id) {
  const response = await axiosInstance.delete(`/${route}/object/lawinfo`, {
    params: { id },
  });
  return response.data;
}

/**
 * 取得全部Area
 * @param {string} route
 */
export async function getAllAreas(route) {
  const response = await axiosInstance.get(`/${route}/area`);
  return response.data;
}

/**
 * 新增Area
 * @param {string} route
 * @param {object} payload
 */
export async function createArea(route, payload) {
  const response = await axiosInstance.post(`/${route}/area`, payload);
  return response.data;
}

/**
 * 刪除Area
 * @param {string} route
 * @param {number} id
 */
export async function deleteArea(route, id) {
  const response = await axiosInstance.delete(`/${route}/area`, {
    params: { id },
  });
  return response.data;
}

////////////////////////////////////////
// 2. 分區詳細 (AreaDetail) 相關 API
////////////////////////////////////////

/**
 * 取得該 Area 的所有 AreaDetail
 * @param {string} route
 * @param {number} areaId
 */
export async function getAllAreaDetails(route, areaId) {
  const response = await axiosInstance.get(`/${route}/area/detail`, {
    params: { areaId },
  });
  return response.data;
}

/**
 * 新增 AreaDetail
 * @param {string} route
 * @param {object} payload
 */
export async function createAreaDetail(route, payload) {
  const response = await axiosInstance.post(`/${route}/area/detail`, payload);
  return response.data;
}

/**
 * 刪除 AreaDetail
 * @param {string} route
 * @param {number} id
 */
export async function deleteAreaDetail(route, id) {
  const response = await axiosInstance.delete(`/${route}/area/detail`, {
    params: { id },
  });
  return response.data;
}

/**
 * 排序 Areas
 * @param {string} route
 * @param {object} payload
 */
export async function sortAreas(route, payload) {
  const response = await axiosInstance.put(`/${route}/area/sort`, payload);
  return response.data;
}

/**
 * 獲取所有詢價表單
 * @param {string} route
 * @param {Object} params - { start, end, status, pageNumber, pageSize }
 */
export const getAllInquiryForms = async (route, { start, end, status, pageNumber, pageSize }) => {
  const response = await axiosInstance.get(`/${route}/form`, {
    params: {
      start,
      end,
      status,
      pageNumber,
      pageSize,
    },
  });
  return response.data;
};

/**
 * 更新表單狀態
 * @param {string} route
 * @param {Object} param - { id, status }
 */
export const updateFormStatus = async (route, { id, status }) => {
  try {
    const response = await axiosInstance.put(`/${route}/form`, {
      Id: id,
      Status: status,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating form status:', error);
    throw error;
  }
};

/**
 * 建立業務
 * @param {string} route
 * @param {Object} userRequest
 */
export async function createBusinessMan(route, userRequest) {
  const response = await axiosInstance.post(`/${route}/businessman`, userRequest);
  return response.data;
}

/**
 * 取得業務列表
 * @param {string} route
 * @param {string} name
 */
export async function getBusinessMans(route, name) {
  const response = await axiosInstance.get(`/${route}/businessmans`, {
    params: { name },
  });
  return response.data;
}

/**
 * 修改業務
 * @param {string} route
 * @param {Object} businessManRequest
 */
export async function updateBusinessMan(route, businessManRequest) {
  const response = await axiosInstance.put(`/${route}/businessman`, businessManRequest);
  return response.data;
}

/**
 * 上傳大頭貼
 * @param {string} route
 * @param {File|Blob} image
 * @param {number} businessManId
 */
export async function uploadBusinessManImg(route, image, businessManId) {
  const formData = new FormData();
  formData.append('Image', image);
  formData.append('BusinessManId', businessManId);

  const response = await axiosInstance.post(`/${route}/businessman/img`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

/**
 * 建立 Admin
 * @param {string} route
 * @param {Object} userRequest
 */
export async function createAdmin(route, userRequest) {
  const response = await axiosInstance.post(`/${route}/admin`, userRequest);
  return response.data;
}

/**
 * 取得 Admin 列表
 * @param {string} route
 * @param {string} name
 */
export async function getAdmins(route, name) {
  const response = await axiosInstance.get(`/${route}/admins`, {
    params: { name },
  });
  return response.data;
}

/**
 * 修改 Admin
 * @param {string} route
 * @param {Object} adminRequest
 */
export async function updateAdmin(route, adminRequest) {
  const response = await axiosInstance.put(`/${route}/admin`, adminRequest);
  return response.data;
}

/**
 * 上傳 Admin 大頭貼
 * @param {string} route
 * @param {File|Blob} image
 * @param {number} adminId
 */
export async function uploadAdminImg(route, image, adminId) {
  const formData = new FormData();
  formData.append('Image', image);
  formData.append('AdminId', adminId);

  const response = await axiosInstance.post(`/${route}/admin/img`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

/**
 * 建立 Super
 * @param {string} route
 * @param {Object} userRequest
 */
export async function createSuper(route, userRequest) {
  const response = await axiosInstance.post(`/${route}/super`, userRequest);
  return response.data;
}

/**
 * 取得 Super 列表
 * @param {string} route
 * @param {string} name
 */
export async function getSupers(route, name) {
  const response = await axiosInstance.get(`/${route}/supers`, {
    params: { name },
  });
  return response.data;
}

/**
 * 修改 Super
 * @param {string} route
 * @param {Object} superRequest
 */
export async function updateSuper(route, superRequest) {
  const response = await axiosInstance.put(`/${route}/super`, superRequest);
  return response.data;
}

/**
 * 上傳 Super 大頭貼
 * @param {string} route
 * @param {File|Blob} image
 * @param {number} superId
 */
export async function uploadSuperImg(route, image, superId) {
  const formData = new FormData();
  formData.append('Image', image);
  formData.append('SuperId', superId);

  const response = await axiosInstance.post(`/${route}/super/img`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

/**
 * 上傳關於我們上方照片
 * @param {string} route
 * @param {File|Blob} image
 * @param {number} sort
 */
export async function uploadAboutImg(route, image, sort) {
  const formData = new FormData();
  formData.append('Image', image);
  formData.append('Sort', sort);

  const response = await axiosInstance.post(`/${route}/about/img`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
}

/**
 * 取得關於我們的圖片列表
 * @param {string} route
 */
export async function getAboutImgs(route) {
  const response = await axiosInstance.get(`/${route}/about/imgs`);
  return response.data;
}

/**
 * 刪除關於我們照片
 * @param {string} route
 * @param {number} id
 */
export async function deleteAboutImg(route, id) {
  const response = await axiosInstance.delete(`/${route}/about/img`, {
    params: { id },
  });
  return response.data;
}

/**
 * 修改關於我們照片排序
 * @param {string} route
 * @param {number} id
 * @param {number} sort
 */
export async function updateAboutSort(route, id, sort) {
  const response = await axiosInstance.put(`/${route}/about/sort`, { id, sort });
  return response.data;
}

/**
 * 取得隊員列表
 * @param {string} route
 */
export async function getTeammates(route) {
  const response = await axiosInstance.get(`/${route}/about/teammates`);
  return response.data;
}

/**
 * 新增隊員
 * @param {string} route
 * @param {Object} teammateData - 含有隊員欄位與檔案的物件
 */
export async function createTeammate(route, teammateData) {
  const formData = new FormData();
  formData.append('Id', teammateData.id || 0);
  formData.append('Name', teammateData.name || '');
  formData.append('NickName', teammateData.nickName || '');
  formData.append('History', teammateData.history || '');
  formData.append('Major', teammateData.major || '');
  formData.append('MajorArea', teammateData.majorArea || '');
  formData.append('Position', teammateData.position || '');
  if (teammateData.img) {
    formData.append('Img', teammateData.img);
  }

  const response = await axiosInstance.post(`/${route}/about/teammate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

/**
 * 修改隊員
 * @param {string} route
 * @param {Object} teammateData
 */
export async function updateTeammate(route, teammateData) {
  const formData = new FormData();
  formData.append('Id', teammateData.id);
  formData.append('Name', teammateData.name || '');
  formData.append('NickName', teammateData.nickName || '');
  formData.append('History', teammateData.history || '');
  formData.append('Major', teammateData.major || '');
  formData.append('MajorArea', teammateData.majorArea || '');
  formData.append('Position', teammateData.position || '');
  if (teammateData.img) {
    formData.append('Img', teammateData.img);
  }

  const response = await axiosInstance.put(`/${route}/about/teammate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

/**
 * 刪除隊員
 * @param {string} route
 * @param {number} teammateId
 */
export async function deleteTeammate(route, teammateId) {
  const response = await axiosInstance.delete(`/${route}/about/teammate`, {
    params: { id: teammateId },
  });
  return response.data;
}

/**
 * 上傳首頁輪播照片
 * @param {string} route
 * @param {File|Blob} image
 * @param {boolean} isFirst
 */
export async function uploadIndexImg(route, image, isFirst) {
  const formData = new FormData();
  formData.append('Image', image);
  formData.append('IsFirst', isFirst);

  const response = await axiosInstance.post(`/${route}/index/img`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

/**
 * 取得首頁輪播照片列表
 * @param {string} route
 */
export async function getIndexImgs(route) {
  const response = await axiosInstance.get(`/${route}/index/imgs`);
  return response.data;
}

/**
 * 刪除首頁輪播照片
 * @param {string} route
 * @param {number} id
 */
export async function deleteIndexImg(route, id) {
  const response = await axiosInstance.delete(`/${route}/index/img`, {
    params: { id },
  });
  return response.data;
}

/**
 * 修改首頁輪播是否為第一張
 * @param {string} route
 * @param {number} id
 * @param {boolean} isFirst
 */
export async function updateIndexIsFirst(route, id, isFirst) {
  const body = { id, isFirst };
  const response = await axiosInstance.put(`/${route}/index/img`, body);
  return response.data;
}

/**
 * 取得 Page 選項 (後端若在 /super/pages，則帶入 route="super")
 * @param {string} route
 */
export async function getPageOptions(route) {
  const response = await axiosInstance.get(`/${route}/pages`);
  return response.data;
}

/**
 * 取得群組 (後端若在 /super/groups，則帶入 route="super")
 * @param {string} route
 */
export async function getGroups(route) {
  const response = await axiosInstance.get(`/${route}/groups`);
  return response.data;
}

/**
 * 創建權限群組
 * @param {string} route
 * @param {Object} groupData
 */
export async function createGroup(route, groupData) {
  const response = await axiosInstance.post(`/${route}/group`, groupData);
  return response.data;
}

/**
 * 更新權限群組
 * @param {string} route
 * @param {Object} groupData
 */
export async function updateGroup(route, groupData) {
  const response = await axiosInstance.put(`/${route}/group`, groupData);
  return response.data;
}

/**
 * 刪除權限群組
 * @param {string} route
 * @param {number} groupId
 */
export async function deleteGroup(route, groupId) {
  const response = await axiosInstance.delete(`/${route}/group?id=${groupId}`);
  return response.data;
}

/**
 * 取得顧客列表
 * @param {string} route
 * @param {string} [name]
 */
export async function getAllCustomers(route, name = '') {
  const response = await axiosInstance.get(`/${route}/customer`, {
    params: { name },
  });
  return response.data;
}

/**
 * 新增顧客
 * @param {string} route
 * @param {Object} customer
 */
export async function createCustomer(route, customer) {
  const response = await axiosInstance.post(`/${route}/customer`, customer);
  return response.data;
}

/**
 * 修改顧客
 * @param {string} route
 * @param {Object} customer
 */
export async function updateCustomer(route, customer) {
  const response = await axiosInstance.put(`/${route}/customer`, customer);
  return response.data;
}

/**
 * 取得需求列表
 * @param {string} route
 * @param {Object} params - { start, end, customerId, pageNumber, pageSize }
 */
export async function getAllDemands(route, params) {
  const response = await axiosInstance.get(`/${route}/demand`, {
    params,
  });
  return response.data;
}

/**
 * 新增需求
 * @param {string} route
 * @param {Object} demand - DemandLogRequest
 */
export async function createDemand(route, demand) {
  const response = await axiosInstance.post(`/${route}/demand`, demand);
  return response.data;
}

/**
 * 修改需求
 * @param {string} route
 * @param {Object} demand - DemandLogRequest
 */
export async function updateDemand(route, demand) {
  const response = await axiosInstance.put(`/${route}/demand`, demand);
  return response.data;
}

/**
 * 取得分區需求列表
 * @param {string} route
 * @param {number} demandLogId
 */
export async function getDemandAreas(route, demandLogId) {
  const response = await axiosInstance.get(`/${route}/demand/area`, {
    params: { demandLogId },
  });
  return response.data;
}

/**
 * 分區需求 - 新增
 * @param {string} route
 * @param {Object} payload - { id, areaId, demandLogId }
 */
export async function createDemandArea(route, payload) {
  const response = await axiosInstance.post(`/${route}/demand/area`, payload);
  return response.data;
}

/**
 * 分區需求 - 刪除
 * @param {string} route
 * @param {number} id
 */
export async function deleteDemandArea(route, id) {
  const response = await axiosInstance.delete(`/${route}/demand/area`, {
    params: { id },
  });
  return response.data;
}

/**
 * 物件類型需求 - 取得列表
 * @param {string} route
 * @param {number} demandLogId
 */
export async function getDemandObjectTypes(route, demandLogId) {
  const response = await axiosInstance.get(`/${route}/demand/objecttype`, {
    params: { demandLogId },
  });
  return response.data;
}

/**
 * 物件類型需求 - 新增
 * @param {string} route
 * @param {Object} payload - { id, objectTypeId, demandLogId }
 */
export async function createDemandObjectType(route, payload) {
  const response = await axiosInstance.post(`/${route}/demand/objecttype`, payload);
  return response.data;
}

/**
 * 物件類型需求 - 刪除
 * @param {string} route
 * @param {number} id
 */
export async function deleteDemandObjectType(route, id) {
  const response = await axiosInstance.delete(`/${route}/demand/objecttype`, {
    params: { id },
  });
  return response.data;
}

/**
 * 取得符合需求的物件 (範例：/businessman/demand/object?demandLogId=xxx)
 * @param {string} route
 * @param {number} demandLogId
 */
export async function getGoodObject(route, demandLogId) {
  const response = await axiosInstance.get(`/${route}/demand/object`, {
    params: { demandLogId },
  });
  return response.data;
}

/**
 * 設置圖片為分享廣告用
 * @param {string} role - 使用者角色(如 "super" 或 "admin")
 * @param {number} id - 圖片的 id
 * @returns {Promise<any>} API 回應
 */
export async function chooseAdImg(route, id) {
  const response = await axiosInstance.put(`/${route}/adimg?id=${id}`);
  return response.data; // 假設後端返回 CommonResult 格式
}

// 取得分區類型列表
export async function getPartitions(role) {
  const response = await axiosInstance.get(`/${role}/partitions`);
  return response.data; // 假設後端返回 CommonResult 格式
}

// 新增分區類型
export async function createPartition(role, payload) {
  const response = await axiosInstance.post(`/${role}/partition`, payload);
  return response.data;
}

// 修改分區類型
export async function updatePartition(role, payload) {
  const response = await axiosInstance.put(`/${role}/partition`, payload);
  return response.data;
}

// 刪除分區類型
export async function deletePartition(role, id) {
  const response = await axiosInstance.delete(`/${role}/partition?id=${id}`);
  return response.data;
}

/**
 * 更新分區排序
 * PUT /super/partition/sort
 * request = { PartitionTypes: [{ id, partitionTypeName, sort, isShow }, ...] }
 */
export async function sortPartitions(role, partitionList) {
  // 準備後端需要的 request body
  const payload = {
    partitionTypes: partitionList
  };
  const response = await axiosInstance.put(`/${role}/partition/sort`, payload);
  return response.data;
}

/**
 * 重設密碼
 * PUT /super/reset
 * @param {string} role - 使用者角色 (通常是 "super")
 * @param {object} payload - { account, oldPassword, newPassword }
 */
export async function resetPassword(role, payload) {
  const response = await axiosInstance.put(`/${role}/reset`, payload);
  return response.data; // 假設後端返回 CommonResult
}

// 取得「關於我們」上方介紹
export async function getAboutContext() {
  const response = await axiosInstance.get(`/aboutcontext`);
  return response.data; // 假設後端返回 CommonResult
}

// 更新「關於我們」文本
export async function updateAboutContext(role, html) {
  // 假設後端是透過 QueryString 接收參數 ( ?html=xxxx )
  const response = await axiosInstance.put(`${role}/aboutcontext?html=${encodeURIComponent(html)}`);
  return response.data;
}

