// src/pages/SuperManagementSubPage.jsx

import React, { useEffect, useState, useContext } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Upload,
  message,
  Row,
  Col,
  Space,
  Avatar,
  Divider,
  Switch,
  Select,
  Checkbox,
} from 'antd';
import {
  UploadOutlined,
  PlusOutlined,
  EditOutlined,
  UserOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';

// 新增 resetPassword API 引入
import {
  getSupers,
  createSuper,
  updateSuper,
  uploadSuperImg,
  getAllOptions,
  resetPassword,    // <-- 重設密碼的函式
} from '../../../api/totalApi';

import { AuthContext } from '../../../context/AuthContext';

const { Option } = Select;
const { Descriptions } = require('antd');

const SuperManagementSubPage = () => {
  const [loading, setLoading] = useState(false);
  const [supers, setSupers] = useState([]);        // 後端回傳的 SuperDTO 列表
  const [authGroups, setAuthGroups] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const { user } = useContext(AuthContext);
  const [form] = Form.useForm();

  // 顯示/隱藏「展開列」的額外欄位
  const [showAllColumns, setShowAllColumns] = useState(true);

  // ========== 重設密碼區域 ==========
  const [resetModalVisible, setResetModalVisible] = useState(false);
  const [resetForm] = Form.useForm();
  const [selectedUserId, setSelectedUserId] = useState(0);

  useEffect(() => {
    if (user) {
      fetchSupers(searchName);
      fetchDropdownOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchSupers = async (name = '') => {
    setLoading(true);
    try {
      const result = await getSupers(user.role, name);
      setSupers(result.data || []);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  const fetchDropdownOptions = async () => {
    try {
      const allOptions = await getAllOptions();
      setAuthGroups(allOptions.authGroups || []);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data || '操作失敗');
    }
  };

  const handleSearch = () => {
    fetchSupers(searchName);
  };

  const openCreateModal = () => {
    setIsEditMode(false);
    setEditRecord(null);
    form.resetFields();
    form.setFieldsValue({
      id: 0,
      account: '',
      password: '',
      authType: 3, // 超級管理者
      name: '',
      position: '',
      status: false,
      authGroupId: 1,
    });
    setIsModalVisible(true);
  };

  const openEditModal = (record) => {
    setIsEditMode(true);
    setEditRecord(record);
    const formValues = {
      id: record.id,
      name: record.name || '',
      position: record.position || '',
      status: record.status || false,
      authGroupId: record.authGroupId || 1,
      account: '',  // 編輯模式下若要改帳號/密碼，可自行斟酌
      password: '',
      authType: 3,
    };
    form.setFieldsValue(formValues);
    setIsModalVisible(true);
  };

  // 點擊 Modal「確定」按鈕
  const handleOkModal = async () => {
    try {
      const values = await form.validateFields();
      if (isEditMode && editRecord) {
        // 編輯
        const superRequest = {
          id: values.id,
          name: values.name,
          position: values.position,
          status: values.status,
          authGroupId: values.authGroupId,
        };
        await updateSuper(user.role, superRequest);
        message.success('修改超級管理者成功');
      } else {
        // 新增
        const userRequest = {
          id: 0,
          account: values.account,
          password: values.password,
          authType: 3, // 超級管理者
          superRequest: {
            id: 0,
            name: values.name,
            position: values.position,
            status: values.status,
            authGroupId: values.authGroupId,
          },
        };
        await createSuper(user.role, userRequest);
        message.success('新增超級管理者成功');
      }
      setIsModalVisible(false);
      fetchSupers(searchName);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data || '操作失敗');
    }
  };

  // 上傳大頭貼
  const handleUploadImg = async (record, file) => {
    try {
      await uploadSuperImg(user.role, file, record.id);
      message.success(`上傳大頭貼成功: ${record.name}`);
      fetchSupers(searchName);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data || '操作失敗');
    }
  };

  // ========== 重設密碼功能 ==========

  // 打開「重設密碼」彈窗
  const openResetPwModal = (record) => {
    // 後端是否回傳 record.account？
    // 如果是 userName, userAccount, 請改相應的值
    setSelectedUserId(record.userId || 0);
    resetForm.resetFields();
    setResetModalVisible(true);
  };

  // 點擊「重設密碼」Modal 的「確定」按鈕
  const handleResetPwOk = async () => {
    try {
      const values = await resetForm.validateFields();
      // payload = { account, oldPassword, newPassword }
      const payload = {
        userId: selectedUserId,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      // 呼叫 resetPassword
      const res = await resetPassword(user.role, payload);
      if (res.success) {
        message.success(res.successMessage || '重設密碼成功');
        setResetModalVisible(false);
      } else {
        message.error(res.errorMessages?.join(',') || '重設密碼失敗');
      }
      setResetModalVisible(false);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data || '操作失敗');
    }
  };

  // 主表格欄位
  const basicColumns = [
    {
      title: '大頭貼',
      dataIndex: 'imgUrl',
      key: 'imgUrl',
      width: 100,
      fixed: 'left',
      render: (imgUrl) => (
        <Avatar
          size={48}
          src={imgUrl}
          icon={<UserOutlined />}
          style={{ backgroundColor: '#87d068' }}
        />
      ),
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: '職位',
      dataIndex: 'position',
      key: 'position',
      width: 120,
    },
    {
      title: '狀態',
      dataIndex: 'status',
      key: 'status',
      width: 80,
      render: (val) => (val ? '啟用' : '停用'),
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 220,
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => openEditModal(record)}
          >
            編輯
          </Button>
          <Upload
            beforeUpload={(file) => {
              handleUploadImg(record, file);
              return false;
            }}
            showUploadList={false}
          >
            <Button type="link" icon={<UploadOutlined />}>
              頭貼
            </Button>
          </Upload>
          {/* 重設密碼 */}
          <Button type="link" danger onClick={() => openResetPwModal(record)}>
            重設密碼
          </Button>
        </Space>
      ),
    },
  ];

  // 顯示其餘欄位
  const expandedRowRender = (record) => {
    return (
      <Descriptions
        bordered
        size="small"
        column={2}
        style={{ backgroundColor: '#fafafa' }}
      >
        <Descriptions.Item label="ID">{record.id}</Descriptions.Item>
        <Descriptions.Item label="權限群組ID">
          {record.authGroupId}
        </Descriptions.Item>
        <Descriptions.Item label="建立者">{record.creator}</Descriptions.Item>
        <Descriptions.Item label="建立日期">
          {record.createDate
            ? dayjs(record.createDate).format('YYYY-MM-DD HH:mm:ss')
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="修改者">{record.editor}</Descriptions.Item>
        <Descriptions.Item label="修改日期">
          {record.editDate
            ? dayjs(record.editDate).format('YYYY-MM-DD HH:mm:ss')
            : '-'}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const tableExpandable = showAllColumns
    ? {
        expandedRowRender,
        rowExpandable: () => true,
      }
    : undefined;

  return (
    <div style={{ padding: 24 }}>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col xs={24} sm={12} md={6} lg={4}>
          <Input
            placeholder="輸入姓名搜尋"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            onPressEnter={handleSearch}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={handleSearch}>
            查詢
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={openCreateModal}
          >
            新增超級管理者
          </Button>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          <Checkbox
            checked={showAllColumns}
            onChange={(e) => setShowAllColumns(e.target.checked)}
          >
            顯示全部欄位
          </Checkbox>
        </Col>
      </Row>

      <Table
        rowKey="id"
        columns={basicColumns}
        dataSource={supers}
        loading={loading}
        expandable={tableExpandable}
      />

      {/* 新增/編輯 Modal */}
      <Modal
        title={isEditMode ? '編輯超級管理者' : '新增超級管理者'}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleOkModal}
        destroyOnClose
        width={600}
      >
        <Form form={form} layout="vertical" name="superForm">
          {!isEditMode && (
            <>
              <Divider>使用者 (UserRequest)</Divider>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="帳號(Account)"
                    name="account"
                    rules={[{ required: true, message: '請輸入帳號' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="密碼(Password)"
                    name="password"
                    rules={[{ required: true, message: '請輸入密碼' }]}
                  >
                    <Input type="password" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Divider>超級管理者 (SuperRequest)</Divider>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="姓名(Name)"
                name="name"
                rules={[{ required: true, message: '請輸入姓名' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="職位(Position)" name="position">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="狀態(Status)"
                name="status"
                valuePropName="checked"
              >
                <Switch checkedChildren="啟用" unCheckedChildren="停用" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="群組(AuthGroupId)"
                name="authGroupId"
                rules={[{ required: true, message: '請選擇群組' }]}
              >
                <Select placeholder="選擇群組">
                  {authGroups.map((group) => (
                    <Option key={group.id} value={group.id}>
                      {group.groupName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="authType" hidden>
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* 重設密碼 Modal */}
      <Modal
        title="重設密碼"
        open={resetModalVisible}
        onCancel={() => setResetModalVisible(false)}
        destroyOnClose
        onOk={handleResetPwOk}
      >
        <Form form={resetForm} layout="vertical">
          <Form.Item
            label="舊密碼"
            name="oldPassword"
            rules={[{ required: true, message: '請輸入舊密碼' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="新密碼"
            name="newPassword"
            rules={[{ required: true, message: '請輸入新密碼' }]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SuperManagementSubPage;
