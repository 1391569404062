// src/pages/IndexCarouselManagementPage.jsx

import React, { useEffect, useState, useContext } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  InputNumber,
  Upload,
  message,
  Row,
  Col,
  Space,
  Image,
  Switch
} from 'antd';
import {
  UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  uploadIndexImg,
  getIndexImgs,
  deleteIndexImg,
  updateIndexIsFirst,
} from '../../../api/totalApi';
import { AuthContext } from '../../../context/AuthContext';

const IndexCarouselManagementPage = () => {
  const [loading, setLoading] = useState(false);
  const [carouselList, setCarouselList] = useState([]);
  const { user } = useContext(AuthContext);
  // 控制「上傳照片」的 Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  // 用於處理表單
  const [form] = Form.useForm();

  // 預設失敗圖片（請根據實際情況調整 URL，確保該檔案存在 public 資料夾）
  const defaultImageUrl = '/default-failed.png';

  useEffect(() => {
    fetchCarouselList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 取得輪播照片列表
   */
  const fetchCarouselList = async () => {
    setLoading(true);
    try {
      const result = await getIndexImgs(user.role);
      setCarouselList(result.data || []);
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  /**
   * 打開「上傳照片」對話框
   */
  const openUploadModal = () => {
    form.resetFields();
    form.setFieldsValue({
      sort: 1, // 預設排序 1
      isFirst: false,
    });
    setIsModalVisible(true);
  };

  /**
   * 「上傳照片」 - 確定按鈕
   */
  const handleOkModal = async () => {
    try {
      const values = await form.validateFields();
      if (!values.imgFile) {
        message.warning('請選擇一張照片');
        return;
      }
      // 取得 Upload 組件回傳的檔案資訊
      const file = values.imgFile.file;
      if (!file) {
        message.warning('請選擇一張照片');
        return;
      }
      await uploadIndexImg(user.role, file, values.isFirst || false);
      message.success('上傳輪播照片成功');
      setIsModalVisible(false);
      fetchCarouselList();
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    }
  };

  /**
   * 刪除輪播照片
   * @param {Object} record
   */
  const handleDelete = async (record) => {
    Modal.confirm({
      title: '確認刪除',
      content: `是否刪除此照片？ (ID: ${record.id})`,
      okText: '刪除',
      okButtonProps: { danger: true },
      cancelText: '取消',
      onOk: async () => {
        try {
          await deleteIndexImg(user.role, record.id);
          message.success('刪除成功');
          fetchCarouselList();
        } catch (error) {
          console.error(error);
                message.error(error.response.data || '操作失敗');
        }
      },
    });
  };

  /**
   * 更新是否為第一張 (isFirst)
   * @param {Object} record
   * @param {boolean} newValue
   */
  const handleUpdateIsFirst = async (record, newValue) => {
    try {
      await updateIndexIsFirst(user.role, record.id, newValue);
      message.success('更新成功');
      fetchCarouselList();
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    }
  };

  /**
   * 圖片載入錯誤時替換成預設圖片
   */
  const handleImgError = (e) => {
    e.target.onerror = null; // 避免重複觸發
    e.target.src = defaultImageUrl;
  };

  // Table 欄位定義
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: '圖片',
      dataIndex: 'imgUrl',
      key: 'imgUrl',
      width: 320,
      render: (url) => {
        return url ? (
          <Image
            width={80}
            height={80}
            src={url}
            alt="輪播圖"
            fallback={defaultImageUrl}
            onError={handleImgError}
            // 啟用 preview 功能，點擊可預覽 300*300 的大圖
            preview={{
              mask: <div style={{ textAlign: 'center', color: '#fff' }}>預覽</div>,
              src: url,
              width: 300,
              height: 300,
            }}
          />
        ) : (
          <span>無圖片</span>
        );
      },
    },
    {
      title: '是否第一張',
      dataIndex: 'isFirst',
      key: 'isFirst',
      width: 120,
      render: (val, record) => (
        <Switch
          checked={val}
          onChange={(checked) => handleUpdateIsFirst(record, checked)}
        />
      ),
    },
    {
      title: '操作',
      key: 'action',
      width: 200,
      render: (_, record) => (
        <Space>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record)}
          >
            刪除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <h2>首頁輪播照片管理</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={openUploadModal}
          >
            上傳新照片
          </Button>
        </Col>
      </Row>

      <Table
        rowKey="id"
        columns={columns}
        dataSource={carouselList}
        loading={loading}
      />

      {/* 上傳圖片 Modal */}
      <Modal
        title="上傳新輪播照片"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleOkModal}
        destroyOnClose
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="選擇照片"
            name="imgFile"
            rules={[{ required: true, message: '請選擇照片' }]}
          >
            <Upload
              beforeUpload={() => false} // 禁止 antd 自動上傳
              multiple={false}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>選擇照片</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="是否為第一張"
            name="isFirst"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="排序"
            name="sort"
            rules={[{ required: true, message: '請輸入排序' }]}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default IndexCarouselManagementPage;
