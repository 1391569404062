// src/components/ManageImagesModal.jsx

import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  Button,
  Upload,
  message,
  Space,
  Checkbox,
  Tooltip
} from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import imageCompression from 'browser-image-compression';
import { MenuOutlined, CopyOutlined, EyeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { AuthContext } from '../../context/AuthContext';

// 引入 API 函式
import {
  postObjectImage,
  getImagesOfObjectInformation,
  sortObjectImages,
  deleteObjectPhoto,
  chooseAdImg, // 單一路由，後端會自動切換 isAd true/false
} from '../../api/totalApi';


// --- Styled Components: 表格外觀 ---
const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;

  thead {
    background-color: #fafafa;
  }

  th,
  td {
    padding: 12px;
    text-align: center;
    border: 1px solid #f0f0f0;
  }

  th {
    background-color: #f5f5f5;
    font-weight: bold;
  }

  .drag-handle {
    cursor: grab;
    padding: 4px;
    color: #1890ff;
  }

  img {
    border-radius: 8px;
  }
`;

/**
 * 主要 Modal 組件
 */
const ManageImagesModal = ({ visible, onClose, objectInformationId, refreshImages }) => {
  const [imagesList, setImagesList] = useState([]);
  const [loadingImages, setLoadingImages] = useState(false);
  const { user } = useContext(AuthContext);

  // 新增：圖片預覽用 State
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  // 點擊圖片 -> 進行預覽
  const handleImageClick = (url) => {
    setPreviewUrl(url);
    setPreviewVisible(true);
  };

  useEffect(() => {
    if (visible) {
      fetchImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // 取得圖片列表
  const fetchImages = async () => {
    setLoadingImages(true);
    try {
      const response = await getImagesOfObjectInformation(user.role, objectInformationId);
      if (response.success) {
        const sortedData = (response.data || []).sort((a, b) => a.sort - b.sort);
        setImagesList(sortedData);
      } else {
        message.error(response.errorMessages.join(','));
      }
    } catch (error) {
      message.error(error?.response?.data || '操作失敗');
    } finally {
      setLoadingImages(false);
    }
  };

  // 刪除圖片
  const handleDeleteImage = async (item) => {
    try {
      const response = await deleteObjectPhoto(user.role, item.id, objectInformationId);
      if (response.success) {
        setImagesList((prev) => prev.filter((i) => i.id !== item.id));
        refreshImages();
        message.success(response.successMessage);
      } else {
        message.error(response.errorMessages.join(','));
      }
    } catch (error) {
      message.error(error?.response?.data || '操作失敗');
    }
  };

  // 上傳圖片
  const handleUploadImage = async ({ file }) => {
    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
      };
      const compressedFile = await imageCompression(file, options);

      const formData = new FormData();
      formData.append('Image', compressedFile);
      formData.append('ObjectInformationId', objectInformationId);

      const response = await postObjectImage(user.role, formData);
      if (response.success) {
        await fetchImages();
        refreshImages();
        message.success(response.successMessage);
      } else {
        message.error(response.errorMessages.join(','));
      }
    } catch (error) {
      message.error(error?.response?.data || '操作失敗');
    }
  };

  // 拖曳排序
  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedList = Array.from(imagesList);
    const [movedItem] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, movedItem);

    // 更新 sort 屬性
    const updatedList = reorderedList.map((img, index) => ({
      ...img,
      sort: index + 1,
    }));

    setImagesList(updatedList);

    // 呼叫後端更新排序
    try {
      const response = await sortObjectImages(user.role, objectInformationId, updatedList);
      if (response.success) {
        message.success(response.successMessage);
      } else {
        message.error(response.errorMessages.join(','));
      }
    } catch (error) {
      message.error(error?.response?.data || '操作失敗');
    }
  };

  /**
   * 勾選/取消廣告 (同一隻 API chooseAdImg)
   * 假設後端會根據當前 isAd 狀態 自動切換
   */
  const handleAdCheckboxChange = async (checked, item) => {
    try {
      const response = await chooseAdImg(user.role, item.id); 
      // 這裡假設後端會自動toggle isAd
      if (response.success) {
        message.success(response.successMessage);
        // 前端狀態：isAd 改成相反
        setImagesList((prev) =>
          prev.map((i) => (i.id === item.id ? { ...i, isAd: !i.isAd } : i))
        );
        refreshImages();
      } else {
        message.error(response.errorMessages.join(','));
      }
    } catch (error) {
      message.error(error?.response?.data || '操作失敗');
    }
  };

  return (
    <>
      <Modal
        open={visible}
        title="管理圖片"
        onCancel={onClose}
        footer={[
          <Button key="close" onClick={onClose}>
            關閉
          </Button>,
        ]}
        width={800}
        centered
        destroyOnClose
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided) => (
              <StyledTable {...provided.droppableProps} ref={provided.innerRef}>
                <thead>
                  <tr>
                    <th>拖曳</th>
                    <th>ID</th>
                    <th>圖片</th>
                    <th>排序</th>
                    <th>分享廣告</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                  {imagesList.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={String(item.id)}
                      index={index}
                    >
                      {(providedDrag, snapshot) => (
                        <tr
                          ref={providedDrag.innerRef}
                          {...providedDrag.draggableProps}
                          style={{
                            ...providedDrag.draggableProps.style,
                            background: snapshot.isDragging
                              ? '#e6f7ff'
                              : '#fff',
                            boxShadow: snapshot.isDragging
                              ? '0 2px 8px rgba(0, 0, 0, 0.15)'
                              : 'none',
                          }}
                        >
                          {/* 拖曳區 */}
                          <td {...providedDrag.dragHandleProps}>
                            <MenuOutlined className="drag-handle" />
                          </td>
                          {/* ID */}
                          <td>{item.id}</td>
                          {/* 圖片 (可點擊預覽) */}
                          <td>
                            <img
                              src={item.imgUrl}
                              style={{
                                width: 100,
                                borderRadius: '8px',
                                cursor: 'pointer',
                              }}
                              alt="img"
                              onClick={() => handleImageClick(item.imgUrl)}
                            />
                          </td>
                          {/* 排序 */}
                          <td>{item.sort}</td>
                          {/* 勾選廣告 */}
                          <td>
                            <AdCheckbox item={item} onChange={handleAdCheckboxChange} />
                          </td>
                          {/* 刪除按鈕 */}
                          <td>
                            <Button type="link" danger onClick={() => handleDeleteImage(item)}>
                              刪除
                            </Button>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              </StyledTable>
            )}
          </Droppable>
        </DragDropContext>

        {/* 上傳圖片按鈕 */}
        <Upload
          customRequest={handleUploadImage}
          showUploadList={false}
          accept="image/*"
          style={{ marginTop: 20 }}
        >
          <Button type="primary">上傳新圖片</Button>
        </Upload>
      </Modal>

      {/* 預覽圖片 Modal */}
      <Modal
        open={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        footer={null}
        width={800}
        centered
        destroyOnClose
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 300,
          }}
        >
          <img
            src={previewUrl}
            alt="preview"
            style={{
              width: 700,
              height: 700,
              objectFit: 'contain',
              borderRadius: '8px',
            }}
          />
        </div>
      </Modal>
    </>
  );
};

ManageImagesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  objectInformationId: PropTypes.number.isRequired,
  refreshImages: PropTypes.func.isRequired,
};

export default ManageImagesModal;


// ========== AdCheckbox Component ==========

const AdCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;  /* 讓 Checkbox 與 URL 分上下兩行 */
  align-items: flex-start; /* 左對齊 */
  gap: 4px;

  .checkbox-row {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .url-row {
    margin-left: 24px;   /* 往右縮排，讓 URL 與按鈕更整齊 */
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    color: #1890ff;
  }
`;

const AdCheckbox = ({ item, onChange }) => {
  const isAd = item.isAd || false;

  return (
    <AdCheckboxWrapper>
      {/* 第一行：Checkbox */}
      <div className="checkbox-row">
        <Checkbox
          checked={isAd}
          onChange={(e) => onChange(e.target.checked, item)}
        >
          分享
        </Checkbox>
      </div>

      {/* 若 isAd = true，第二行顯示 URL 與複製按鈕 */}
      {isAd && (
        <div className="url-row">
          <span>{item.imgUrl}</span>
          <Tooltip title="複製 URL">
            <Button
              icon={<CopyOutlined />}
              size="small"
              type="link"
              onClick={(e) => {
                e.stopPropagation();
                handleCopyUrl(item.imgUrl);
              }}
            />
          </Tooltip>
        </div>
      )}
    </AdCheckboxWrapper>
  );
};

AdCheckbox.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

// 複製 URL 功能
const handleCopyUrl = (url) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      message.success('已複製 URL 到剪貼簿');
    })
    .catch((err) => {
      console.error('複製失敗：', err);
      message.error('複製失敗');
    });
};
