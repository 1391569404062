// src/pages/CustomerManagementPage.jsx

import React, { useEffect, useState, useContext } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Row,
  Col,
  message,
  Space,
} from 'antd';
import styled from 'styled-components';

import {
  getAllCustomers,
  createCustomer,
  updateCustomer,
} from '../../../api/totalApi';
import { AuthContext } from '../../../context/AuthContext';

// (選用) styled-components 做更好的 RWD 佈局
const PageContainer = styled.div`
  padding: 24px;
  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const HeaderRow = styled(Row)`
  margin-bottom: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const Title = styled.h2`
  margin: 0;
`;

// 用於展開列排版
const { Descriptions } = require('antd');

const CustomerManagementSubPage = () => {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]); // 顯示顧客列表
  const [searchName, setSearchName] = useState(''); // 搜尋關鍵字

  // 新增/編輯 Modal 狀態
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRecord, setEditRecord] = useState(null);

  const { user } = useContext(AuthContext);
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      fetchCustomers(); // componentDidMount 時先抓取
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  /**
   * 取得顧客列表
   */
  const fetchCustomers = async (name = '') => {
    setLoading(true);
    try {
      const result = await getAllCustomers(user.role, name);
      if (result.success) {
        setCustomers(result.data || []);
      } else {
        message.error(result.errors.join(', '));
      }
    } catch (error) {
      console.error(error);
      message.error(error.response.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  /**
   * 搜尋
   */
  const handleSearch = () => {
    fetchCustomers(searchName.trim());
  };

  /**
   * 點擊「新增顧客」
   */
  const openCreateModal = () => {
    setIsEditMode(false);
    setEditRecord(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  /**
   * 點擊「編輯」按鈕
   */
  const openEditModal = (record) => {
    setIsEditMode(true);
    setEditRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  /**
   * Modal 取消
   */
  const handleCancelModal = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  /**
   * Modal 確定
   */
  const handleOkModal = async () => {
    try {
      const values = await form.validateFields();
      if (!isEditMode) {
        // 新增
        const payload = { ...values, id: 0 };
        const result = await createCustomer(user.role, payload);
        if (result.success) {
          message.success('新增顧客成功');
          fetchCustomers();
        } else {
          message.error(result.errors.join(', '));
        }
      } else {
        // 編輯
        const payload = { ...values, id: editRecord.id };
        const result = await updateCustomer(user.role, payload);
        if (result.success) {
          message.success('修改顧客成功');
          fetchCustomers();
        } else {
          message.error(result.errors.join(', '));
        }
      }
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error(error.response.data || '操作失敗');
    }
  };

  // ================== 主表格欄位 (精簡) ==================
  // 避免水平捲動，只顯示必要欄位
  const basicColumns = [
    {
      title: '客戶ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: '客戶編號(ClientNo)',
      dataIndex: 'clientNo',
      key: 'clientNo',
      width: 120,
    },
    {
      title: '公司名稱(CompanyName)',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 180,
    },
    {
      title: '公司電話(CompanyNumber)',
      dataIndex: 'companyNumber',
      key: 'companyNumber',
      width: 120,
    },
    {
      title: '動作',
      key: 'action',
      width: 80,
      render: (_, record) => (
        <Button type="link" onClick={() => openEditModal(record)}>
          編輯
        </Button>
      ),
    },
  ];

  // ================== 展開列：顯示其它欄位 ==================
  const expandedRowRender = (record) => {
    return (
      <Descriptions
        bordered
        size="small"
        column={2}
        style={{ backgroundColor: '#fafafa', padding: '8px' }}
      >
        <Descriptions.Item label="負責人">
          {record.bossName || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="統一編號">
          {record.invoiceNumber || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="公司地址" span={2}>
          {record.companyAddress || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="公司傳真">
          {record.companyFax || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="來源">
          {record.source || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="負責人電話">
          {record.bossNumber || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="負責人手機">
          {record.bossPhoneNumber || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="承辦人">
          {record.undertakerName || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="承辦人電話">
          {record.underNumber || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="承辦人手機">
          {record.underPhoneNumber || '-'}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  // 設定可展開列
  const tableExpandable = {
    expandedRowRender,
    rowExpandable: () => true,
  };

  return (
    <PageContainer>
      <HeaderRow justify="space-between" align="middle">
        <Col>
          <Title>顧客管理</Title>
        </Col>
        <Col>
          <Space>
            <Input
              placeholder="輸入公司名等關鍵字搜尋"
              style={{ width: 200 }}
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              onPressEnter={handleSearch}
            />
            <Button type="primary" onClick={handleSearch}>
              搜尋
            </Button>
            <Button type="primary" onClick={openCreateModal}>
              新增顧客
            </Button>
          </Space>
        </Col>
      </HeaderRow>

      <Table
        columns={basicColumns}
        dataSource={customers}
        rowKey="id"
        loading={loading}
        // 移除 scroll，避免水平捲動
        expandable={tableExpandable}
      />

      {/* 新增 / 編輯 Modal */}
      <Modal
        title={isEditMode ? '編輯顧客' : '新增顧客'}
        open={isModalVisible}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        okText="確定"
        cancelText="取消"
        width={700}
      >
        <Form form={form} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="客戶編號"
                name="clientNo"
                rules={[{ required: true, message: '請輸入客戶編號' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="來源" name="source">
                <Input placeholder="如: 網站、自行開發..." />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                label="公司名稱"
                name="companyName"
                rules={[{ required: true, message: '請輸入公司名稱' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="統一編號" name="invoiceNumber" rules={[{ required: true, message: '請輸入統一編號' }]}>
                <Input placeholder="如有則填" />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item label="公司地址" name="companyAddress" rules={[{ required: true, message: '請輸入公司地址' }]}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item label="公司電話" name="companyNumber" rules={[{ required: true, message: '請輸入公司電話' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="公司傳真" name="companyFax" rules={[{ required: true, message: '請輸入公司傳真' }]}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={8}>
              <Form.Item label="負責人" name="bossName" rules={[{ required: true, message: '請輸入負責人' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="負責人電話" name="bossNumber" rules={[{ required: true, message: '請輸入負責人電話' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="負責人手機" name="bossPhoneNumber" rules={[{ required: true, message: '請輸入負責人手機' }]}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={8}>
              <Form.Item label="承辦人" name="undertakerName" rules={[{ required: true, message: '請輸入承辦人' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label="承辦人電話" name="underNumber" rules={[{ required: true, message: '請輸入承辦人電話' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="承辦人手機 "
                name="underPhoneNumber"
                rules={[{ required: true, message: '請輸入承辦人手機' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </PageContainer>
  );
};

export default CustomerManagementSubPage;
