import React, { useState, useEffect,useContext } from 'react';
import { Modal, Table, Button, Form, Select, message,Avatar } from 'antd';
import {
    bindBusinessMan,
    deleteBindBusiness,
  } from '../../api/totalApi.js';
import PropTypes from 'prop-types';
import { useMessage } from '../../context/MessageContext.jsx';
import { AuthContext } from '../../context/AuthContext';
const { Option } = Select;

const BindBusinessModal = ({ options,businessMans,visible, onClose, objectInformationId, refreshBindings }) => {
  const [form] = Form.useForm();
  const { showMessage } = useMessage();
  const [businessMansList, setBusinessMansList] = useState(businessMans); 
  const { user } = useContext(AuthContext);

  const handleAddBusiness = async (values) => {
    const req = {
        objectInformationId,
        businessManId: values.businessManId
    }
      const response = await bindBusinessMan(user.role,req);
      setBusinessMansList(response.data);
      showMessage({ title: '操作成功', message:response.successMessage, isError: false });
      refreshBindings();
  };

  const handleDeleteBusiness = async (id,objectInformationId) => {
    const response =await deleteBindBusiness(user.role,id, objectInformationId);
    setBusinessMansList(response.data);
    showMessage({ title: '操作成功', message:response.successMessage, isError: false });
    refreshBindings();
  };

  const businessColumns = [
    { title: '大頭貼', dataIndex: 'businessMan', key: 'id',    
        render: (businessMan) => (
        <Avatar
          src={businessMan.imgUrl}
          size={50} // 設定大頭貼大小
          style={{ border: '1px solid #d9d9d9' }} // 可選：設定邊框
        />
      )
    },
    { title: '業務員編', dataIndex: 'businessMan', key: 'id',render: (businessMan) => businessMan.businessNo },
    { title: '業務名稱', dataIndex: 'businessMan', key: 'id',render: (businessMan) => businessMan.name },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <Button type="link" onClick={() => handleDeleteBusiness(record.id,record.objectInformationId)}>刪除</Button>
      )
    }
  ];

  return (
    <Modal
      open={visible}
      title="綁定業務"
      onCancel={onClose}
      footer={null}
      width={600}
      centered
    >
      <Table
        rowKey="businessManId"
        dataSource={businessMansList}
        columns={businessColumns}
        pagination={false}
      />
      <Form form={form} onFinish={handleAddBusiness} layout="inline" style={{ marginTop: 20 }}>
        <Form.Item
        name="businessManId"
        label="綁定業務"
        rules={[{ required: true, message: '請選擇產品類型' }]}
        >
        <Select placeholder="選擇業務">
            {options?.map((man) => (
            <Option key={man.id} value={man.id}>
                {man.name}
            </Option>
            ))}
        </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">綁定</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

BindBusinessModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  objectInformationId: PropTypes.number.isRequired,
  refreshBindings: PropTypes.func.isRequired
};

export default BindBusinessModal;
