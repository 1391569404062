// src/pages/PageControllSubPage.jsx

import React, { useEffect, useState ,useContext} from 'react';
import {
  Checkbox,
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  message,
  Spin,
  Divider,
  Modal,
  List,
  Typography,
} from 'antd';
import styled from 'styled-components';
import {
  getPageOptions,
  getGroups,
  createGroup,
  updateGroup,
  deleteGroup,
} from '../../../api/totalApi';
import { AuthContext } from '../../../context/AuthContext';
// Styled-components
const PageContainer = styled.div`
  padding: 24px;
  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const PageTitle = styled.h2`
  margin-bottom: 16px;
  text-align: center;
  font-size: 24px;
  color: #333;
`;

const StyledCard = styled(Card)`
  margin-bottom: 16px;
  @media (max-width: 768px) {
    .ant-card-head {
      font-size: 14px;
    }
  }
`;

const GroupListContainer = styled.div`
  margin-bottom: 32px;
`;

const PageControllSubPage = () => {
  const [form] = Form.useForm();

  // 從後端取得的「主頁面 + 子頁面」資料
  const [pageData, setPageData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [editingGroup, setEditingGroup] = useState(null);
  const { user } = useContext(AuthContext);
  /**
   * checkedPages 的結構預計如下：
   * {
   *   [pageId]: {
   *     main: boolean,          // 代表該大類是否勾選
   *     subPages: number[]      // 代表大類底下勾選的子頁面 ID
   *   },
   *   ...
   * }
   */
  const [checkedPages, setCheckedPages] = useState({});

  // 一進入頁面時，先抓取頁面與群組資料
  useEffect(() => {
    fetchPageData(user.role);
    fetchGroups(user.role);
  }, []);

  const fetchPageData = async () => {
    setLoading(true);
    try {
      const result = await getPageOptions(user.role); // 從後端取得資料
      if (result.success) {
        setPageData(result.data || []);
      } else {
        message.error('取得頁面資料失敗');
      }
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  const fetchGroups = async () => {
    setGroupsLoading(true);
    try {
      const result = await getGroups(user.role);
      if (result.success) {
        setGroups(result.data || []);
      } else {
        message.error('取得群組資料失敗');
      }
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    } finally {
      setGroupsLoading(false);
    }
  };

  /**
   * 將字串轉換為陣列
   */
  const parseIds = (idString) => {
    if (!idString) return [];
    return idString.split(',').map((id) => parseInt(id, 10)).filter((id) => !isNaN(id));
  };

  /**
   * 將陣列轉換為字串
   */
  const stringifyIds = (ids) => {
    if (!ids || ids.length === 0) return '';
    return ids.join(',');
  };

  /**
   * 設置表單數據及勾選狀態
   */
  const setFormData = (group) => {
    form.setFieldsValue({
      id: group.id, 
      groupName: group.groupName,
    });
  
    // 解析字串 => 陣列
    const pageIds = parseIds(group.pageIds);
    const subPageIds = parseIds(group.subPageIds);
  
    // 初始化 checkedPages 狀態
    const newCheckedPages = {};
  
    // 遍歷每個大類頁面，並設置勾選狀態
    pageData.forEach((page) => {
      if (pageIds.includes(page.id)) {
        // 找出已勾選的子頁面
        const groupSubPages = page.subPage
          .filter((sp) => subPageIds.includes(sp.id))
          .map((sp) => sp.id);
  
        // 只要後端給的 pageIds 有包含這個 page.id，就把主頁面勾選
        newCheckedPages[page.id] = {
          main: true,
          subPages: groupSubPages,
        };
      }
    });
  
    setCheckedPages(newCheckedPages);
  };

  /**
   * 大類(主頁面)勾選或取消勾選
   */
  const handleMainCheckboxChange = (pageId, isChecked) => {
    setCheckedPages((prev) => {
      const current = prev[pageId] || { main: false, subPages: [] };

      const newSubPages = isChecked
        ? (pageData.find((p) => p.id === pageId)?.subPage || []).map((sp) => sp.id)
        : [];

      return {
        ...prev,
        [pageId]: {
          ...current,
          main: isChecked,
          subPages: newSubPages,
        },
      };
    });
  };

  /**
   * 子頁面(子類別)勾選或取消勾選
   */
  const handleSubCheckboxChange = (pageId, subPageId, isChecked) => {
    setCheckedPages((prev) => {
      const current = prev[pageId] || { main: false, subPages: [] };
      let newSubPages;

      if (isChecked) {
        newSubPages = [...current.subPages, subPageId];
      } else {
        newSubPages = current.subPages.filter((id) => id !== subPageId);
      }

      const isMainChecked = newSubPages.length > 0;

      return {
        ...prev,
        [pageId]: {
          main: isMainChecked,
          subPages: newSubPages,
        },
      };
    });
  };

  /**
   * 表單送出
   */
  const onFinish = async (values) => {
    const { id, groupName } = values;

    const pageIds = [];
    const subPageIds = [];

    Object.entries(checkedPages).forEach(([pageId, pageValue]) => {
      if (pageValue.main) {
        pageIds.push(parseInt(pageId, 10));
      }
      pageValue.subPages.forEach((spId) => subPageIds.push(spId));
    });

    const requestData = {
      Id: id || 0,
      GroupName: groupName || '',
      PageIds: pageIds,
      SubPageIds: subPageIds,
    };

    try {
      let result;
      if (id) {
        result = await updateGroup(user.role,requestData);
      } else {
        result = await createGroup(user.role,requestData);
      }

      if (result.success) {
        message.success(id ? '更新群組成功' : '新增群組成功');
        fetchGroups(user.role);
        form.resetFields();
        setCheckedPages({});
        setEditingGroup(null);
      } else {
        message.error(result.errors || '操作失敗');
      }
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    }
  };

  /**
   * 刪除群組
   */
  const handleDelete = (id) => {
    Modal.confirm({
      title: '確認刪除',
      content: '確定要刪除此群組嗎？',
      okText: '刪除',
      okType: 'danger',
      cancelText: '取消',
      onOk: async () => {
        try {
          const result = await deleteGroup(user.role,id);
          if (result.success) {
            message.success('刪除成功');
            fetchGroups(user.role);
          } else {
            message.error(result.errors || '刪除失敗');
          }
        } catch (error) {
          console.error(error);
                message.error(error.response.data || '操作失敗');
        }
      },
    });
  };

  /**
   * 編輯群組
   */
  const handleEdit = (group) => {
    setEditingGroup(group);
    setFormData(group);
  };

  /**
   * 重置表單
   */
  const handleReset = () => {
    form.resetFields();
    setCheckedPages({});
    setEditingGroup(null);
  };

  return (
    <PageContainer>
      <Spin spinning={loading || groupsLoading}>
        <PageTitle>權限群組管理</PageTitle>

        {/* 群組列表 */}
        <GroupListContainer>
          <Typography.Title level={4}>現有群組</Typography.Title>
          <List
            bordered
            dataSource={groups}
            renderItem={(group) => (
              <List.Item
                actions={[
                  <Button type="link" onClick={() => handleEdit(group)}>
                    編輯
                  </Button>,
                  <Button type="link" danger onClick={() => handleDelete(group.Id)}>
                    刪除
                  </Button>,
                ]}
              >
                {group.groupName}
              </List.Item>
            )}
          />
        </GroupListContainer>

        {/* 群組表單 */}
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          style={{ maxWidth: 800, margin: '0 auto' }}
        >
          <Form.Item label="群組 ID (可不填)" name="id" hidden>
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="群組名稱"
            name="groupName"
            rules={[{ required: true, message: '請輸入群組名稱' }]}
          >
            <Input placeholder="例如：業務部門、系統管理群組等" />
          </Form.Item>

          <Divider />

          {pageData.map((page) => (
            <StyledCard
              key={page.id}
              bordered={true}
              title={
                <Checkbox
                  checked={checkedPages[page.id]?.main || false}
                  onChange={(e) => handleMainCheckboxChange(page.id, e.target.checked)}
                >
                  {page.pageName}
                </Checkbox>
              }
            >
              <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                {page.subPage.map((subPage) => (
                  <Col xs={24} sm={12} md={8} key={subPage.id}>
                    <Checkbox
                      checked={checkedPages[page.id]?.subPages?.includes(subPage.id) || false}
                      onChange={(e) =>
                        handleSubCheckboxChange(page.id, subPage.id, e.target.checked)
                      }
                      disabled={!checkedPages[page.id]?.main}
                    >
                      {subPage.subPageName}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </StyledCard>
          ))}

          <Form.Item style={{ textAlign: 'center', marginTop: 24 }}>
            <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
              {editingGroup ? '更新群組' : '新增群組'}
            </Button>
            {editingGroup && (
              <Button onClick={handleReset} style={{ marginLeft: 8 }}>
                取消編輯
              </Button>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </PageContainer>
  );
};

export default PageControllSubPage;
