import axios from 'axios';
import { getToken } from './jwt';

// 錯誤緩存
const errorCache = [];

// 原始 XMLHttpRequest.send 方法的引用
const originalSend = XMLHttpRequest.prototype.send;

// 覆寫 XMLHttpRequest.prototype.send
XMLHttpRequest.prototype.send = function (...args) {
  const xhr = this;

  // 監聽 readyState 變化
  xhr.addEventListener('readystatechange', function () {
    if (xhr.readyState === 4 && xhr.status >= 400) {
      // 將錯誤緩存起來
      const errorData = {
        status: xhr.status,
        statusText: xhr.statusText,
        responseURL: xhr.responseURL,
        responseText: xhr.responseText,
      };
      errorCache.push(errorData);

      // 可以在此處通知錯誤
      console.error('XHR Error:', errorData);
    }
  });

  // 調用原始 send 方法
  return originalSend.apply(this, args);
};

// 建立 Axios 實例
const instance = axios.create({
  baseURL: 'https://api.hopeoffice.net', 
  // baseURL: 'http://localhost:22635', 
  headers: {
    'Content-Type': 'application/json',
  }
});

// 請求攔截器
instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    alert('Unauthorized access. Redirecting to homepage.');
    window.location.href = '/login';
    return Promise.reject(error);
  }
);

// 響應攔截器
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorMsg = '';

    // 當沒有 response 時，可能是網路錯誤
    if (!error.response) {
      errorMsg = `Network Error: ${error.message}`;
      alert(errorMsg);
      return Promise.reject(error);
    }
    const { status, data } = error.response;
    try {
      if (status === 500) {
        // 500 回傳數據可能來自 ErrorDetails 類別
        const message = data.message || data.Message || 'Internal Server Error';
        const innerMessage = data.innerMessage || data.InnerMessage || '';
        errorMsg = `Error ${status}: ${message}${innerMessage ? '\nDetails: ' + innerMessage : ''}`;
        alert(errorMsg);
      } else if (status === 400) {
        // 400 錯誤通常來自 CommonResult，檢查 data.errors 與 data.message
        if (data.errors && typeof data.errors === 'object') {
          const errors = Object.values(data.errors);
          errorMsg = errors.flat().join(', ');
        } else if (data.message || data.Message) {
          errorMsg = data.message || data.Message;
        } else {
          errorMsg = 'Bad Request';
        }
        alert(`Error ${status}: ${errorMsg}`);
      } else {
        // 其他狀態碼
        errorMsg = data.message || data.Message || 'Error occurred';
        alert(`Error ${status}: ${errorMsg}`);
      }
    } catch (ex) {
      // 若解析錯誤時，也顯示基本錯誤訊息
      alert(`Error ${status}: ${error.message}`);
    }

    // 緩存 Axios 錯誤
    errorCache.push({
      status,
      message: data.message || data.Message || 'Unknown Error',
      url: error.config.url,
    });

    return Promise.reject(error);
  }
);

// 提供檢視錯誤緩存的函數
export function getCachedErrors() {
  console.table(errorCache);
  return errorCache;
}

export default instance;
