// src/pages/AreaManagement.jsx
import React, { useState, useEffect,useContext } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Checkbox,
  message,
  Popconfirm,
  Drawer,
  Space,
  Row,
  Col,
  InputNumber
} from 'antd';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import {
  getAllAreas,
  createArea,
  deleteArea,
  getAllAreaDetails,
  createAreaDetail,
  deleteAreaDetail,
  sortAreas
} from '../../../api/totalApi';
import { AuthContext } from '../../../context/AuthContext';
//////////////////////////////////////////
// Styled Components
//////////////////////////////////////////
const PageContainer = styled.div`
  padding: 24px;
  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const HeaderRow = styled(Row)`
  margin-bottom: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  margin: 0;
`;

/**
 * 用於在陣列中重新排序
 * @param {array} list 原陣列
 * @param {number} startIndex 拖曳起始索引
 * @param {number} endIndex 拖曳結束索引
 */
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const AreaManagementSubPage = () => {
  // 狀態：Area列表
  const [areaList, setAreaList] = useState([]);
  const [loadingArea, setLoadingArea] = useState(false);

  // Modal：控制「新增 / 編輯 分區」的對話框
  const [areaModalVisible, setAreaModalVisible] = useState(false);
  const [areaForm] = Form.useForm();

  // Drawer：控制「分區詳細」的顯示
  const [detailDrawerVisible, setDetailDrawerVisible] = useState(false);
  const [currentAreaId, setCurrentAreaId] = useState(null);
  const [areaDetails, setAreaDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [detailForm] = Form.useForm();
  const { user } = useContext(AuthContext);
  //////////////////////////////////////////
  // 1. 取得所有分區
  //////////////////////////////////////////
  const fetchAreas = async () => {
    setLoadingArea(true);
    try {
      const res = await getAllAreas(user.role);
      // 假設後端回傳 { success: true, data: [...] } 或直接 array
      setAreaList(res.data || res);
    } catch (error) {
      message.error(error.response.data || '操作失敗');
    }
    setLoadingArea(false);
  };

  useEffect(() => {
    fetchAreas(user.role);
  }, []);

  //////////////////////////////////////////
  // 2. 新增分區
  //////////////////////////////////////////
  const handleOpenAreaModal = () => {
    areaForm.resetFields();
    setAreaModalVisible(true);
  };

  const handleCloseAreaModal = () => {
    setAreaModalVisible(false);
  };

  const handleAreaSubmit = async () => {
    try {
      const values = await areaForm.validateFields();
      const payload = {
        id: 0,
        sort: values.sort,
        areaName: values.areaName,
        isDiscount: values.isDiscount || false,
        isShow: values.isShow || false,
      };
      await createArea(user.role,payload);
      message.success('新增分區成功');
      setAreaModalVisible(false);
      fetchAreas();
    } catch (error) {
      message.error(error.response.data || '操作失敗');
    }
  };

  //////////////////////////////////////////
  // 3. 刪除分區
  //////////////////////////////////////////
  const handleDeleteArea = async (id) => {
    try {
      await deleteArea(user.role,id);
      message.success('刪除分區成功');
      fetchAreas();
    } catch (error) {
      message.error(error.response.data || '操作失敗');
    }
  };

  //////////////////////////////////////////
  // 4. 顯示「分區詳細」列表
  //////////////////////////////////////////
  const handleShowDetailDrawer = async (areaId) => {
    setCurrentAreaId(areaId);
    setDetailDrawerVisible(true);
    fetchAreaDetails(areaId);
  };

  const handleCloseDetailDrawer = () => {
    setDetailDrawerVisible(false);
    setAreaDetails([]);
    setCurrentAreaId(null);
  };

  const fetchAreaDetails = async (areaId) => {
    setLoadingDetail(true);
    try {
      const res = await getAllAreaDetails(user.role,areaId);
      setAreaDetails(res.data || res);
    } catch (error) {
      message.error(error.response.data || '操作失敗');
    }
    setLoadingDetail(false);
  };

  //////////////////////////////////////////
  // 5. 新增「分區詳細」(AreaDetail)
  //////////////////////////////////////////
  const handleCreateDetail = async () => {
    try {
      const values = await detailForm.validateFields();
      const payload = {
        areaId: currentAreaId,
        areaDetailDescription: values.areaDetailDescription,
      };
      await createAreaDetail(user.role,payload);
      message.success('新增分區詳細成功');
      detailForm.resetFields();
      fetchAreaDetails(currentAreaId);
    } catch (error) {
      message.error(error.response.data || '操作失敗');
    }
  };

  //////////////////////////////////////////
  // 6. 刪除「分區詳細」
  //////////////////////////////////////////
  const handleDeleteDetail = async (id) => {
    try {
      await deleteAreaDetail(user.role,id);
      message.success('刪除分區詳細成功');
      fetchAreaDetails(currentAreaId);
    } catch (error) {
      message.error(error.response.data || '操作失敗');
    }
  };

  //////////////////////////////////////////
  // 7. 拖曳排序
  //////////////////////////////////////////
  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const newList = reorder(
      areaList,
      result.source.index,
      result.destination.index
    );
    setAreaList(newList);

    // 更新 sort
    const updatedAreas = newList.map((item, idx) => ({
      ...item,
      sort: idx + 1,
    }));

    try {
      const res = await sortAreas(user.role,{ areas: updatedAreas });
      if (res.success) {
        message.success('排序更新成功');
        fetchAreas();
      } else {
        message.error(res.errorMessages.join(','));
      }
    } catch (error) {
      message.error(error.response.data || '操作失敗');
    }
  };

  //////////////////////////////////////////
  // 使用與 ObjectTypesSubPage 相同的「row」實作：
  // 根據 data-row-key 找到該筆資料在 areaList 的位置
  //////////////////////////////////////////
  const components = {
    body: {
      wrapper: (props) => (
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <tbody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}
            >
              {props.children}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      ),
      row: ({ children, ...restProps }) => {
        // 以 data-row-key 找出 index
        const rowKey = restProps['data-row-key'];
        const index = areaList.findIndex((item) => item.id === rowKey);
        if (index === -1) {
          return <tr {...restProps}>{children}</tr>;
        }

        return (
          <Draggable key={rowKey} draggableId={String(rowKey)} index={index}>
            {(provided, snapshot) => (
              <tr
                {...restProps}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps} // 直接綁到整行 or 改綁到某一欄
                style={{
                  ...provided.draggableProps.style,
                  background: snapshot.isDragging ? '#e6f7ff' : undefined,
                }}
              >
                {children}
              </tr>
            )}
          </Draggable>
        );
      }
    }
  };

  //////////////////////////////////////////
  // 定義表格欄位
  //////////////////////////////////////////
  const columns = [
    {
      title: '拖曳',
      dataIndex: 'drag',
      key: 'drag',
      width: '5%',
      render: () => <MenuOutlined style={{ cursor: 'grab' }} />,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: '排序(sort)',
      dataIndex: 'sort',
      key: 'sort',
      width: '10%',
    },
    {
      title: '分區名稱(areaName)',
      dataIndex: 'areaName',
      key: 'areaName',
      width: '25%',
    },
    {
      title: '折扣(isDiscount)',
      dataIndex: 'isDiscount',
      key: 'isDiscount',
      width: '10%',
      render: (val) => (val ? '是' : '否'),
    },
    {
      title: '顯示(isShow)',
      dataIndex: 'isShow',
      key: 'isShow',
      width: '10%',
      render: (val) => (val ? '是' : '否'),
    },
    {
      title: '操作',
      key: 'action',
      width: '20%',
      render: (text, record) => (
        <Space>
          <Button size="small" onClick={() => handleShowDetailDrawer(record.id)}>
            查看詳細
          </Button>
          <Popconfirm
            title="確定刪除？"
            onConfirm={() => handleDeleteArea(record.id)}
            okText="是"
            cancelText="否"
          >
            <Button size="small" danger>
              刪除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <PageContainer>
      <HeaderRow justify="space-between" align="middle">
        <Col>
          <Title>分區管理</Title>
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleOpenAreaModal}>
            新增分區
          </Button>
        </Col>
      </HeaderRow>

      <DragDropContext onDragEnd={onDragEnd}>
        <Table
          columns={columns}
          dataSource={areaList}
          rowKey="id"
          loading={loadingArea}
          pagination={false}
          components={components}
        />
      </DragDropContext>

      {/* 新增 / 編輯 分區 Modal */}
      <Modal
        title="新增分區"
        visible={areaModalVisible}
        onOk={handleAreaSubmit}
        onCancel={handleCloseAreaModal}
        okText="確定"
        cancelText="取消"
        destroyOnClose
      >
        <Form form={areaForm} layout="vertical">
          <Form.Item
            label="排序(sort)"
            name="sort"
            rules={[{ required: true, message: '請輸入排序' }]}
          >
            <InputNumber style={{ width: '100%' }} min={0} />
          </Form.Item>
          <Form.Item
            label="分區名稱(areaName)"
            name="areaName"
            rules={[{ required: true, message: '請輸入分區名稱' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="是否折扣(isDiscount)"
            name="isDiscount"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>是</Checkbox>
          </Form.Item>
          <Form.Item
            label="是否顯示(isShow)"
            name="isShow"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>是</Checkbox>
          </Form.Item>
        </Form>
      </Modal>

      {/* 分區詳細 Drawer */}
      <Drawer
        title="分區詳細管理"
        placement="right"
        width={480}
        onClose={handleCloseDetailDrawer}
        visible={detailDrawerVisible}
        destroyOnClose
      >
        <div style={{ marginBottom: 16 }}>
          <Table
            dataSource={areaDetails}
            loading={loadingDetail}
            rowKey="id"
            pagination={false}
            columns={[
              {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 60,
              },
              {
                title: '描述(AreaDetailDescription)',
                dataIndex: 'areaDetailDescription',
                key: 'areaDetailDescription',
              },
              {
                title: '操作',
                key: 'action',
                width: 100,
                render: (text, record) => (
                  <Popconfirm
                    title="確定刪除？"
                    onConfirm={() => handleDeleteDetail(record.id)}
                    okText="是"
                    cancelText="否"
                  >
                    <Button size="small" danger>
                      刪除
                    </Button>
                  </Popconfirm>
                ),
              },
            ]}
          />
        </div>

        <Form layout="inline" form={detailForm}>
          <Form.Item
            label="描述"
            name="areaDetailDescription"
            rules={[{ required: true, message: '請輸入描述' }]}
          >
            <Input placeholder="輸入分區詳情" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleCreateDetail}>
              新增
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </PageContainer>
  );
};

export default AreaManagementSubPage;
