import { jwtDecode } from 'jwt-decode'; 

const TOKEN_KEY = 'app_token';

// 設定 Token
export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

// 取得 Token
export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

// 移除 Token (登出時)
export function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
}

// 解析使用者資訊
export function getUserFromToken() {
  const token = getToken();
  if (!token) return null;
  try {
    const decoded = jwtDecode(token);
    // 假設後端 token payload 包含 { sub: 用戶ID, name: 用戶名稱, avatar: 頭像URL, authType: 1|2|3 }
    // 將 authType 數字轉為對應字串角色與職位名稱
    let roleStr = 'BusinessMan';
    if (decoded.AuthType === '2') {
      roleStr = 'Admin';
    } else if (decoded.AuthType === '3') {
      roleStr = 'Super';
    }

    return {
      userId: decoded.Id,
      name: decoded.Name,
      avatar: decoded.Avatar,
      role: roleStr,
      position: decoded.Position,
    };
  } catch (e) {
    return null;
  }
}
