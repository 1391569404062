// src/pages/TeammateManagementPage.jsx

import React, { useEffect, useState, useContext } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Upload,
  message,
  Row,
  Col,
  Space,
  Image,
  Divider,
} from 'antd';
import {
  UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import {
  getTeammates,
  createTeammate,
  updateTeammate,
  deleteTeammate,
} from '../../../api/totalApi';
import { AuthContext } from '../../../context/AuthContext';

const { TextArea } = Input;
const { Descriptions } = require('antd');

const TeammateManagementSubPage = () => {
  const [loading, setLoading] = useState(false);
  const [teammates, setTeammates] = useState([]);

  // Modal 狀態
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRecord, setEditRecord] = useState(null);

  const { user } = useContext(AuthContext);
  const [form] = Form.useForm();

  // 預設圖片(載入失敗時替換)
  const defaultImageUrl = '/default-failed.png';

  useEffect(() => {
    if (user) {
      fetchTeammateList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  /**
   * 取得「關於我們團隊」列表
   */
  const fetchTeammateList = async () => {
    setLoading(true);
    try {
      const result = await getTeammates(user.role);
      setTeammates(result.data || []);
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  /**
   * 打開「新增隊員」Modal
   */
  const openCreateModal = () => {
    setIsEditMode(false);
    setEditRecord(null);
    form.resetFields();

    form.setFieldsValue({
      id: 0,
      name: '',
      nickName: '',
      history: '',
      major: '',
      majorArea: '',
      position: '',
      imgFile: null,
      sort: 1,
    });

    setIsModalVisible(true);
  };

  /**
   * 打開「編輯隊員」Modal
   */
  const openEditModal = (record) => {
    setIsEditMode(true);
    setEditRecord(record);

    form.resetFields();
    form.setFieldsValue({
      id: record.id,
      name: record.name || '',
      nickName: record.nickName || '',
      history: record.history || '',
      major: record.major || '',
      majorArea: record.majorArea || '',
      position: record.position || '',
      sort: record.sort || 1,
      imgFile: null, // 不會自動顯示舊檔
    });

    setIsModalVisible(true);
  };

  /**
   * Modal「確定」 - 新增或編輯
   */
  const handleOkModal = async () => {
    try {
      const values = await form.validateFields();
      const fileObj = values.imgFile?.file || null; // 取得 Upload 檔案

      const teammateData = {
        id: values.id,
        name: values.name,
        nickName: values.nickName,
        history: values.history,
        major: values.major,
        majorArea: values.majorArea,
        position: values.position,
        img: fileObj || null, // 若有上傳檔
        sort: values.sort,
      };

      if (isEditMode && editRecord) {
        // 編輯
        await updateTeammate(user.role, teammateData);
        message.success('編輯隊員成功');
      } else {
        // 新增
        await createTeammate(user.role, teammateData);
        message.success('新增隊員成功');
      }

      setIsModalVisible(false);
      fetchTeammateList();
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    }
  };

  /**
   * 刪除隊員
   */
  const handleDelete = (record) => {
    Modal.confirm({
      title: '確認刪除',
      content: `確定要刪除隊員「${record.name}」嗎？`,
      okText: '刪除',
      okButtonProps: { danger: true },
      cancelText: '取消',
      onOk: async () => {
        try {
          await deleteTeammate(user.role, record.id);
          message.success('隊員已刪除');
          fetchTeammateList();
        } catch (error) {
          console.error(error);
                message.error(error.response.data || '操作失敗');
        }
      },
    });
  };

  /**
   * 圖片載入錯誤時替換成預設圖
   */
  const handleImgError = (e) => {
    e.target.onerror = null;
    e.target.src = defaultImageUrl;
  };

  // =================== 主表格 (基礎欄位) ===================
  // 只顯示最常需要的欄位，以免欄位過多產生水準捲動
  const basicColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: '專業(Major)',
      dataIndex: 'major',
      key: 'major',
      width: 120,
    },
    {
      title: '職位(Position)',
      dataIndex: 'position',
      key: 'position',
      width: 120,
    },
    {
      title: '照片',
      dataIndex: 'imgUrl', // 後端回傳該欄位
      key: 'imgUrl',
      width: 110,
      render: (url) =>
        url ? (
          <Image
            width={80}
            height={80}
            src={url}
            fallback={defaultImageUrl}
            onError={handleImgError}
          />
        ) : (
          <span>無照片</span>
        ),
    },
    {
      title: '操作',
      key: 'action',
      width: 180,
      render: (_, record) => (
        <Space>
          <Button icon={<EditOutlined />} onClick={() => openEditModal(record)}>
            編輯
          </Button>
          <Button danger icon={<DeleteOutlined />} onClick={() => handleDelete(record)}>
            刪除
          </Button>
        </Space>
      ),
    },
  ];

  // =================== 展開列：顯示其餘欄位 ===================
  const expandedRowRender = (record) => {
    return (
      <Descriptions
        bordered
        size="small"
        column={2}
        style={{ backgroundColor: '#fafafa' }}
      >
        <Descriptions.Item label="暱稱(NickName)">{record.nickName}</Descriptions.Item>
        <Descriptions.Item label="專精地區(MajorArea)">{record.majorArea}</Descriptions.Item>

        <Descriptions.Item label="經歷(History)" span={2}>
          {record.history}
        </Descriptions.Item>

        <Descriptions.Item label="排序(Sort)" span={2}>
          {record.sort}
        </Descriptions.Item>

        {/* 顯示大圖 (300×300) */}
        {record.imgUrl && (
          <Descriptions.Item label="照片(300x300預覽)" span={2}>
            <Image
              width={300}
              height={300}
              src={record.imgUrl}
              fallback={defaultImageUrl}
              onError={handleImgError}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  };

  // 開啟展開列
  const tableExpandable = {
    expandedRowRender,
    rowExpandable: () => true,
  };

  return (
    <div style={{ padding: 24 }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <h2>關於我們 - 團隊管理</h2>
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={openCreateModal}>
            新增隊員
          </Button>
        </Col>
      </Row>

      <Table
        rowKey="id"
        columns={basicColumns}
        dataSource={teammates}
        loading={loading}
        // 移除 scroll={{ x: 1300 }}，避免水平捲動
        expandable={tableExpandable} // 讓每列可展開
      />

      {/* 新增 / 編輯 Modal */}
      <Modal
        title={isEditMode ? '編輯隊員' : '新增隊員'}
        open={isModalVisible}
        onOk={handleOkModal}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
        width={700}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Divider>基本資料</Divider>
          <Row gutter={16}>
            <Col xs={24} sm={8}>
              <Form.Item
                label="姓名(Name)"
                name="name"
                rules={[{ required: true, message: '請輸入姓名' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="暱稱(NickName)"
                name="nickName"
                rules={[{ required: true, message: '請輸入暱稱' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="排序(Sort)"
                name="sort"
                rules={[{ required: true, message: '請輸入排序' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="專業(Major)"
                name="major"
                rules={[{ required: true, message: '請輸入專業' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="專精地區(MajorArea)"
                name="majorArea"
                rules={[{ required: true, message: '請輸入專精地區' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="職位(Position)"
            name="position"
            rules={[{ required: true, message: '請輸入職稱' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="經歷(History)"
            name="history"
            rules={[{ required: true, message: '請輸入經歷' }]}
          >
            <TextArea rows={3} />
          </Form.Item>

          <Divider>上傳照片 (Img)</Divider>
          <Form.Item name="imgFile">
            <Upload beforeUpload={() => false} multiple={false} maxCount={1}>
              <Button icon={<UploadOutlined />}>選擇照片</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TeammateManagementSubPage;
