// src/pages/LoginPage.jsx
import React, { useState, useContext } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import styled, { keyframes } from 'styled-components';
import { loginApi } from '../api/auth';
import { MessageModal } from '../components/MessageModal';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

// 動畫定義
const rotateAnim = keyframes`
  0% { transform: rotateX(0deg) rotateY(0deg); }
  100% { transform: rotateX(360deg) rotateY(360deg); }
`;

const twinkleAnim = keyframes`
  0%, 100% { opacity: 0.8; }
  50% { opacity: 1; }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const glowAnim = keyframes`
  0% {
    text-shadow: 0 0 5px #4ca1af, 0 0 10px #4ca1af, 0 0 20px #4ca1af, 0 0 30px #2c3e50;
  }
  50% {
    text-shadow: 0 0 10px #4ca1af, 0 0 20px #4ca1af, 0 0 30px #4ca1af, 0 0 40px #2c3e50;
  }
  100% {
    text-shadow: 0 0 5px #4ca1af, 0 0 10px #4ca1af, 0 0 20px #4ca1af, 0 0 30px #2c3e50;
  }
`;

// 頁面主要容器
const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000; /* 設置背景為黑色 */
  overflow: hidden;
`;

// 主標題
const MainTitle = styled(Title)`
  position: absolute;
  top: 5%;
  color: #fff;
  text-align: center;
  z-index: 2;
  animation: ${fadeIn} 2s ease-in, ${glowAnim} 3s ease-in-out infinite;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;

  @media(max-width:768px) {
    font-size: 24px;
    top: 3%;
  }
`;

// 背景層
const BackgroundLayer = styled.div`
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 1;
  overflow: hidden;
`;

// 建築容器
const BuildingContainer = styled.div`
  position: absolute;
  perspective: 800px;
  ${(props) => `
    top: ${props.top};
    left: ${props.left};
    width: ${props.size};
    height: ${props.size};
  `}
`;

// 建築線條
const BuildingLines = styled.div`
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; height: 100%;
  border: 1px solid #4ca1af;
  transform-style: preserve-3d;
  animation: ${rotateAnim} 20s linear infinite;
  opacity: 0.5;
`;

// 星星裝飾
const Star = styled.div`
  position: absolute;
  background: #fff;
  border-radius: 50%;
  opacity: 0.8;
  animation: ${twinkleAnim} 3s infinite;
  ${(props) => `
    width: ${props.size};
    height: ${props.size};
    top: ${props.top};
    left: ${props.left};
    animation-delay: ${props.delay};
  `}
`;

// 登入框
const LoginBox = styled.div`
  position: relative;
  z-index: 2;
  background: rgba(40, 40, 40, 0.6);
  padding: 40px;
  border-radius: 16px;
  max-width: 350px;
  width: 90%;
  box-sizing: border-box;
  box-shadow: 0 12px 24px rgba(0,0,0,0.3);
  backdrop-filter: blur(10px);
  text-align: center;
  
  @media(max-width:768px) {
    padding: 30px;
    max-width: 280px;
  }
`;

// 自訂表單樣式
const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    color: #fff;
    font-weight: bold;
  }

  .ant-input, .ant-input-password {
    background: rgba(255,255,255,0.1);
    border: none;
    color: #fff;
    border-radius: 4px;
  }

  .ant-input:hover, .ant-input:focus,
  .ant-input-password:hover .ant-input, .ant-input-password:focus .ant-input {
    border: none;
    outline: none;
    box-shadow: 0 0 5px rgba(255,255,255,0.5);
  }

  .ant-btn-primary {
    background: #4ca1af;
    border-color: #4ca1af;
    border-radius: 4px;
    font-weight: bold;
    &:hover {
      background: #2c3e50;
      border-color: #2c3e50;
    }
  }
`;

// 表單容器，避免左右滾動
const FormContainer = styled.div`
  .ant-form-item-label > label {
    white-space: normal;
  }
`;

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({ visible: false, title:'', message:'', isError:false });
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const onFinish = async (values) => {
    setLoading(true);
    const { account, password } = values;
    try {
      const res = await loginApi(account, password);
      login(res.data); // 確保 data 是 login 成功所需的數據
      setModalInfo({
        visible: true,
        title: '成功',
        message: '登入成功！',
        isError: false
      });
      // 延遲後導向
      setTimeout(() => {
        navigate('/');
      }, 1500);
    } catch(err) {
      console.error('login error:', err);
      setModalInfo({
        visible: true,
        title: '錯誤',
        message: err.response.data || '登入失敗',
        isError: true
      });
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalInfo({ ...modalInfo, visible:false });
  };

  return (
    <Container>
      {/* 主標題 */}
      <MainTitle level={1}>苑景後台系統</MainTitle>

      <BackgroundLayer>
        {/* 散佈多組BuildingContainer於畫面不同位置 */}
        <BuildingContainer top="20%" left="10%" size="200px">
          <BuildingLines/>
        </BuildingContainer>

        <BuildingContainer top="50%" left="80%" size="300px">
          <BuildingLines/>
        </BuildingContainer>

        <BuildingContainer top="70%" left="30%" size="150px">
          <BuildingLines/>
        </BuildingContainer>

        <BuildingContainer top="30%" left="50%" size="250px">
          <BuildingLines/>
        </BuildingContainer>

        {/* 增加更多建築物 */}
        <BuildingContainer top="10%" left="70%" size="180px">
          <BuildingLines/>
        </BuildingContainer>

        <BuildingContainer top="60%" left="40%" size="220px">
          <BuildingLines/>
        </BuildingContainer>

        {/* 添加星星裝飾 */}
        <Star size="5px" top="15%" left="25%" delay="0s"/>
        <Star size="3px" top="40%" left="60%" delay="1s"/>
        <Star size="4px" top="70%" left="20%" delay="2s"/>
        <Star size="2px" top="35%" left="80%" delay="0.5s"/>
        <Star size="6px" top="55%" left="45%" delay="1.5s"/>
        <Star size="3px" top="25%" left="75%" delay="2.5s"/>
        <Star size="4px" top="80%" left="50%" delay="1s"/>
      </BackgroundLayer>

      <LoginBox>
        <Title level={2} style={{color:'#fff', marginBottom:'20px'}}>登入</Title>
        <StyledForm name="loginForm" onFinish={onFinish} layout="vertical">
          <FormContainer>
            <Form.Item 
              name="account" 
              label="帳號" 
              rules={[{ required:true, message:'請輸入您的帳號！' }]}
            >
              <Input placeholder="帳號"/>
            </Form.Item>

            <Form.Item 
              name="password" 
              label="密碼" 
              rules={[{ required:true, message:'請輸入您的密碼！' }]}
            >
              <Input.Password placeholder="密碼"/>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                登入
              </Button>
            </Form.Item>
          </FormContainer>
        </StyledForm>
      </LoginBox>

      <MessageModal
        visible={modalInfo.visible}
        title={modalInfo.title}
        message={modalInfo.message}
        isError={modalInfo.isError}
        onClose={handleModalClose}
      />
    </Container>
  );
}
