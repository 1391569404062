// src/components/Draft.jsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';

// 定義 Styled Components
const StyledWrapper = styled.div`
  z-index: 9999 !important;
  border: 2px solid #1890ff;
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
  min-height: 300px;
`;

const StyledEditor = styled(Editor)`
  min-height: 300px;
  padding: 8px;
  background-color: #fff;
  font-size: 16px;

  .rdw-editor-toolbar {
    border: none;
    border-bottom: 1px solid #d9d9d9;
    background-color: #fafafa;
  }
`;

const DraftEditor = ({ value = '', onChange = () => {} }) => {
  // 僅在 mount 或父層真正換物件(重建此元件)時，轉換 HTML → EditorState
  const [editorState, setEditorState] = useState(() => {
    if (value) {
      const blocksFromHtml = htmlToDraft(value);
      if (blocksFromHtml) {
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        return EditorState.createWithContent(contentState);
      }
    }
    return EditorState.createEmpty();
  });

  // 當使用者輸入或編輯時，只更新本地的 editorState
  const handleEditorChange = (newState) => {
    setEditorState(newState);

    // 將當前內容轉成 HTML 傳給父層
    const rawContent = convertToRaw(newState.getCurrentContent());
    const html = draftToHtml(rawContent);
    onChange(html);
  };

  return (
    <StyledWrapper>
      <StyledEditor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        // 重點：在 toolbar 中加入 fontSize、colorPicker、fontFamily 等
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'colorPicker',
            'list',
            'textAlign',
            'history',
          ],
          inline: {
            inDropdown: false,
            options: [
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'monospace',
            ],
          },
          fontSize: {
            // 可自訂你想要顯示的字體大小
            options: [
              8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
            ],
          },
          fontFamily: {
            // 可自訂想要的字體選單
            options: [
              'Arial',
              'Georgia',
              'Impact',
              'Tahoma',
              'Times New Roman',
              'Verdana',
            ],
          },
          colorPicker: {
            // colorPicker 選單可以客製化，但若無特別需求，預設即可
            // colors: ['#000000', '#FFFFFF', '#FF0000', '#00FF00', '#0000FF']
          },
        }}
      />
    </StyledWrapper>
  );
};

DraftEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DraftEditor;
