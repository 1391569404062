import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Switch,
  message,
  Popconfirm
} from 'antd';
import { MenuOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import {
  getPartitions,
  createPartition,
  updatePartition,
  deletePartition,
  sortPartitions
} from '../../../api/totalApi';
import { AuthContext } from '../../../context/AuthContext';

// Styled Components 例：自定義表格樣式
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;

  thead {
    background-color: #fafafa;
  }
  th, td {
    padding: 12px;
    text-align: center;
    border: 1px solid #f0f0f0;
  }
  th {
    background-color: #f5f5f5;
    font-weight: 600;
  }
  .drag-handle {
    cursor: grab;
    color: #1890ff;
  }
`;

const PartitionManagementSubPage = () => {
  const [partitions, setPartitions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Modal 狀態
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRecord, setEditRecord] = useState(null);

  const [form] = Form.useForm();
  const { user } = useContext(AuthContext); // 假設 user.role 會是 "super" 

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 取得分區列表
  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await getPartitions(user.role);
      if (result.success) {
        setPartitions(result.data || []);
      } else {
        message.error(result.errorMessages?.join(',') || '取得資料失敗');
      }
    } catch (error) {
      message.error(error?.response?.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  // 開啟「新增」對話框
  const handleOpenCreateModal = () => {
    setIsEditMode(false);
    setEditRecord(null);
    form.resetFields();
    form.setFieldsValue({
      id: 0,
      partitionTypeName: '',
      sort: 1,
      isShow: true
    });
    setIsModalVisible(true);
  };

  // 開啟「編輯」對話框
  const handleOpenEditModal = (record) => {
    setIsEditMode(true);
    setEditRecord(record);
    form.resetFields();
    form.setFieldsValue({
      id: record.id,
      partitionTypeName: record.partitionTypeName,
      sort: record.sort,
      isShow: record.isShow
    });
    setIsModalVisible(true);
  };

  // Modal 「確定」按鈕
  const handleOkModal = async () => {
    try {
      const values = await form.validateFields();
      if (isEditMode && editRecord) {
        // 編輯模式
        const payload = {
          id: values.id,
          partitionTypeName: values.partitionTypeName,
          sort: values.sort,
          isShow: values.isShow
        };
        const result = await updatePartition(user.role, payload);
        if (result.success) {
          message.success(result.successMessage || '修改成功');
          setIsModalVisible(false);
          fetchData();
        } else {
          message.error(result.errorMessages?.join(',') || '修改失敗');
        }
      } else {
        // 新增
        const payload = {
          id: 0,
          partitionTypeName: values.partitionTypeName,
          sort: values.sort,
          isShow: values.isShow
        };
        const result = await createPartition(user.role, payload);
        if (result.success) {
          message.success(result.successMessage || '新增成功');
          setIsModalVisible(false);
          fetchData();
        } else {
          message.error(result.errorMessages?.join(',') || '新增失敗');
        }
      }
    } catch (error) {
      message.error(error?.response?.data || '操作失敗');
    }
  };

  // 刪除
  const handleDelete = async (record) => {
    try {
      const result = await deletePartition(user.role, record.id);
      if (result.success) {
        message.success(result.successMessage || '刪除成功');
        fetchData();
      } else {
        message.error(result.errorMessages?.join(',') || '刪除失敗');
      }
    } catch (error) {
      message.error(error?.response?.data || '操作失敗');
    }
  };

  // 拖曳排序結束
  const onDragEnd = async (result) => {
    if (!result.destination) return;
    
    const newList = Array.from(partitions);
    const [movedItem] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, movedItem);

    // 重新計算 sort
    const updatedList = newList.map((item, index) => ({
      ...item,
      sort: index + 1
    }));

    setPartitions(updatedList);

    // 呼叫後端更新排序
    try {
      const res = await sortPartitions(user.role, updatedList);
      if (res.success) {
        message.success(res.successMessage || '排序更新成功');
      } else {
        message.error(res.errorMessages?.join(',') || '排序更新失敗');
      }
    } catch (error) {
      message.error(error?.response?.data || '操作失敗');
    }
  };

  return (
    <div style={{ padding: 24 }}>
      <h2>建物使用（分區類型）管理</h2>

      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleOpenCreateModal}
        style={{ marginBottom: 16 }}
      >
        新增分區
      </Button>

      {/* 拖曳容器 */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="partitionTable" direction="vertical">
          {(provided) => (
            <StyledTable {...provided.droppableProps} ref={provided.innerRef}>
              <thead>
                <tr>
                  <th>拖曳</th>
                  <th>ID</th>
                  <th>名稱</th>
                  <th>排序</th>
                  <th>顯示</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {partitions.map((record, index) => (
                  <Draggable
                    key={record.id}
                    draggableId={record.id.toString()}
                    index={index}
                  >
                    {(providedDrag, snapshot) => (
                      <tr
                        ref={providedDrag.innerRef}
                        {...providedDrag.draggableProps}
                        style={{
                          ...providedDrag.draggableProps.style,
                          backgroundColor: snapshot.isDragging ? '#e6f7ff' : '#fff',
                          boxShadow: snapshot.isDragging ? '0 2px 8px rgba(0,0,0,0.15)' : 'none'
                        }}
                      >
                        <td {...providedDrag.dragHandleProps}>
                          <MenuOutlined className="drag-handle" />
                        </td>
                        <td>{record.id}</td>
                        <td>{record.partitionTypeName}</td>
                        <td>{record.sort}</td>
                        <td>{record.isShow ? '是' : '否'}</td>
                        <td>
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => handleOpenEditModal(record)}
                          >
                            編輯
                          </Button>
                          <Popconfirm
                            title="確定刪除？"
                            onConfirm={() => handleDelete(record)}
                            okText="是"
                            cancelText="否"
                          >
                            <Button type="link" icon={<DeleteOutlined />} danger>
                              刪除
                            </Button>
                          </Popconfirm>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </StyledTable>
          )}
        </Droppable>
      </DragDropContext>

      {/* 新增/編輯 Modal */}
      <Modal
        title={isEditMode ? '編輯分區類型' : '新增分區類型'}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleOkModal}
        destroyOnClose
      >
        <Form form={form} layout="vertical">
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label="分區名稱"
            name="partitionTypeName"
            rules={[{ required: true, message: '請輸入分區名稱' }]}
          >
            <Input placeholder="例如：辦公室 / 住宅 / 廠辦 ..." />
          </Form.Item>

          <Form.Item
            label="排序"
            name="sort"
            rules={[{ required: true, message: '請輸入排序數字' }]}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            label="是否顯示"
            name="isShow"
            valuePropName="checked"
          >
            <Switch checkedChildren="是" unCheckedChildren="否" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PartitionManagementSubPage;
