// src/components/ManageLawInfoModal.jsx

import React, { useState, useEffect,useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Table,
  Form,
  Input,
  InputNumber,
  Button,
  message,
  Popconfirm,
  Select,
  Row,
  Col
} from 'antd';
import {
  getObjectLawInfomations,
  createObjectLawInfomation,
  updateObjectLawInfomation,
  deleteObjectLawInfomation
} from '../../api/totalApi.js';
import { AuthContext } from '../../context/AuthContext';
const { Option } = Select;

/**
 * 物件法律資訊管理 Modal
 */
const ManageLawInfoModal = ({ visible, onClose, objectInformationId }) => {
  const [form] = Form.useForm();
  const [lawInfoList, setLawInfoList] = useState([]); // 「物件法律資訊」清單
  const [isEditMode, setIsEditMode] = useState(false); // 判斷表單是「新增」或「更新」模式
  const [editId, setEditId] = useState(null);          // 正在編輯的那筆資料 Id
  const { user } = useContext(AuthContext);
  // 彈窗打開時，若有 objectInformationId，就去抓該物件的法律資訊列表
  useEffect(() => {
    if (visible && objectInformationId) {
      fetchLawInfoList(user.role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, objectInformationId]);

  /**
   * 從後端取得該物件的法律資訊清單
   */
  const fetchLawInfoList = async () => {
    try {
      const res = await getObjectLawInfomations(user.role,objectInformationId);
      if (res.success) {
        setLawInfoList(res.data || []);
      } else {
        message.error(res.errorMessages?.join(',') || '取得清單失敗');
      }
    } catch (error) {
      message.error(error.response.data || '操作失敗');
    }
  };

  /**
   * 清空表單並回到「新增」模式
   */
  const resetForm = () => {
    form.resetFields();
    setIsEditMode(false);
    setEditId(null);
  };

  /**
   * 監聽表單的值變動，並即時更新「總面積」(TotalArea) 欄位
   */
  const handleValuesChange = (_, allValues) => {
    const {
      mainBuilding = 0,
      subBuilding = 0,
      rainBuilding = 0,
      publicArea = 0,
    } = allValues;

    // 即時計算
    const total = Number(mainBuilding) + Number(subBuilding) + Number(rainBuilding) + Number(publicArea);

    // 設定到表單的 totalArea 欄位
    form.setFieldsValue({
      totalArea: total
    });
  };

  /**
   * [新增 / 更新] 表單送出
   */
  const onFinish = async (values) => {
    try {
      // 組成後端需要的 payload：ObjectInformationId + 各欄位
      // 若是新增，Id 設為 0；若是編輯，Id 設為 editId
      const payload = {
        id: isEditMode && editId ? editId : 0,
        objectInformationId,
        viewType: values.viewType,
        viewName: values.viewName,
        mainBuilding: values.mainBuilding,
        subBuilding: values.subBuilding,
        rainBuilding: values.rainBuilding,
        publicArea: values.publicArea,
        totalArea: values.totalArea,
        useArea: values.useArea,
      };

      // 根據當前模式呼叫不同 API
      if (isEditMode && editId) {
        // 更新
        const res = await updateObjectLawInfomation(user.role,payload);
        if (res.success) {
          message.success(res.successMessage || '更新成功');
          resetForm();
          fetchLawInfoList();
        } else {
          message.error(res.errorMessages?.join(',') || '更新失敗');
        }
      } else {
        // 新增
        const res = await createObjectLawInfomation(user.role,payload);
        if (res.success) {
          message.success(res.successMessage || '新增成功');
          resetForm();
          fetchLawInfoList();
        } else {
          message.error(res.errorMessages?.join(',') || '新增失敗');
        }
      }
    } catch (error) {
      message.error(error.response.data || '操作失敗');
    }
  };

  /**
   * 點「編輯」按鈕
   */
  const handleEdit = (record) => {
    setIsEditMode(true);
    setEditId(record.id);

    // 將該筆資料載入到表單
    form.setFieldsValue({
      viewType: record.viewType,
      viewName: record.viewName,
      mainBuilding: record.mainBuilding,
      subBuilding: record.subBuilding,
      rainBuilding: record.rainBuilding,
      publicArea: record.publicArea,
      totalArea: record.totalArea,
      useArea: record.useArea,
    });
  };

  /**
   * 點「刪除」按鈕
   */
  const handleDelete = async (id) => {
    try {
      const res = await deleteObjectLawInfomation(user.role,id);
      if (res.success) {
        message.success(res.successMessage || '刪除成功');
        fetchLawInfoList();
      } else {
        message.error(res.errorMessages?.join(',') || '刪除失敗');
      }
    } catch (error) {
      message.error(error.response.data || '操作失敗');
    }
  };

  /**
   * 表格欄位
   * 注意：依需求顯示中文欄位名稱
   */
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id', width: 60 },
    { 
      title: '截面型態', 
      dataIndex: 'viewType', 
      key: 'viewType', 
      width: 100,
      render: (val) => (val === 0 ? '橫切面' : '縱切面')
    },
    { title: '戶別', dataIndex: 'viewName', key: 'viewName', width: 120 },
    { title: '主建物面積', dataIndex: 'mainBuilding', key: 'mainBuilding', width: 110 },
    { title: '附屬建物面積', dataIndex: 'subBuilding', key: 'subBuilding', width: 110 },
    { title: '遮雨棚面積', dataIndex: 'rainBuilding', key: 'rainBuilding', width: 110 },
    { title: '公設面積', dataIndex: 'publicArea', key: 'publicArea', width: 100 },
    { title: '總面積', dataIndex: 'totalArea', key: 'totalArea', width: 100 },
    { title: '使用面積', dataIndex: 'useArea', key: 'useArea', width: 100 },
    {
      title: '操作',
      key: 'action',
      width: 130,
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>
            編輯
          </Button>
          <Popconfirm
            title="確定刪除？"
            onConfirm={() => handleDelete(record.id)}
            okText="是"
            cancelText="否"
          >
            <Button type="link" danger>
              刪除
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      onCancel={() => {
        resetForm();
        onClose();
      }}
      footer={null}
      title="管理物件法律資訊"
      width={900}
      destroyOnClose
    >
      {/* 物件法律資訊清單 */}
      <Table
        dataSource={lawInfoList}
        columns={columns}
        rowKey="id"
        pagination={false}
        style={{ marginBottom: 16 }}
      />

      {/* 新增 / 編輯 表單 */}
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={handleValuesChange} // 每次任一欄位改變時，就自動計算「總面積」
      >
        <Row gutter={16}>
          {/* 截面型態：下拉式選單 0=橫切面、1=縱切面 */}
          <Col span={12}>
            <Form.Item
              label="截面型態"
              name="viewType"
              rules={[{ required: true, message: '請選擇截面型態' }]}
            >
              <Select placeholder="請選擇截面型態">
                <Option value={0}>橫切面</Option>
                <Option value={1}>縱切面</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* 戶別：文字輸入框 */}
          <Col span={12}>
            <Form.Item
              label="戶別"
              name="viewName"
              rules={[{ required: true, message: '請輸入戶別' }]}
            >
              <Input placeholder="範例：A 戶 / B 戶" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          {/* 主建物面積 */}
          <Col span={12}>
            <Form.Item
              label="主建物面積"
              name="mainBuilding"
              rules={[{ required: true, message: '請輸入主建物面積' }]}
            >
              <InputNumber
                placeholder="請輸入數字"
                style={{ width: '100%' }}
                min={0}
                step={0.01}
              />
            </Form.Item>
          </Col>

          {/* 附屬建物面積 */}
          <Col span={12}>
            <Form.Item
              label="附屬建物面積"
              name="subBuilding"
              rules={[{ required: true, message: '請輸入附屬建物面積' }]}
            >
              <InputNumber
                placeholder="請輸入數字"
                style={{ width: '100%' }}
                min={0}
                step={0.01}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          {/* 遮雨棚面積 */}
          <Col span={12}>
            <Form.Item
              label="遮雨棚面積"
              name="rainBuilding"
              rules={[{ required: true, message: '請輸入遮雨棚面積' }]}
            >
              <InputNumber
                placeholder="請輸入數字"
                style={{ width: '100%' }}
                min={0}
                step={0.01}
              />
            </Form.Item>
          </Col>

          {/* 公設面積 */}
          <Col span={12}>
            <Form.Item
              label="公設面積"
              name="publicArea"
              rules={[{ required: true, message: '請輸入公設面積' }]}
            >
              <InputNumber
                placeholder="請輸入數字"
                style={{ width: '100%' }}
                min={0}
                step={0.01}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          {/* 總面積 (自動計算, 唯讀) */}
          <Col span={12}>
            <Form.Item label="總面積" name="totalArea">
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                step={0.01}
                readOnly
              />
            </Form.Item>
          </Col>

          {/* 使用面積 */}
          <Col span={12}>
            <Form.Item
              label="使用面積"
              name="useArea"
              rules={[{ required: true, message: '請輸入使用面積' }]}
            >
              <InputNumber
                placeholder="請輸入數字"
                style={{ width: '100%' }}
                min={0}
                step={0.01}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginTop: 16 }}>
          <Button type="primary" htmlType="submit">
            {isEditMode ? '更新' : '新增'}
          </Button>
          <Button style={{ marginLeft: 8 }} onClick={resetForm}>
            清除表單
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ManageLawInfoModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  objectInformationId: PropTypes.number,
};

ManageLawInfoModal.defaultProps = {
  objectInformationId: null,
};

export default ManageLawInfoModal;
