// src/pages/InquiryFormsSubPage.jsx
import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import {
  Table,
  Button,
  Modal,
  Space,
  Row,
  Col,
  message,
  DatePicker,
  Checkbox,
  Tooltip,
} from 'antd';
import { EyeOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { getAllInquiryForms, updateFormStatus } from '../../../api/totalApi'; // 調整路徑根據實際情況
import { AuthContext } from '../../../context/AuthContext';

const { RangePicker } = DatePicker;

// Styled Components
const PageContainer = styled.div`
  padding: 24px;
  background: #f0f2f5; /* 淺灰色背景提升對比 */
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const HeaderRow = styled(Row)`
  margin-bottom: 16px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: 16px;
  color: #333; /* 深灰色標題 */

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const ExpandableContent = styled.div`
  padding: 16px;
  background: #fafafa;
  border-left: 3px solid #4ca1af;
`;

const StyledCheckbox = styled(Checkbox)`
  color: #333;

  .ant-checkbox-inner {
    border-color: #4ca1af;
  }

  &.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4ca1af;
    border-color: #4ca1af;
  }
`;

// InquiryFormsSubPage Component
const InquiryFormsSubPage = () => {
  // State Hooks
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filters, setFilters] = useState({
    start: dayjs().subtract(1, 'day').startOf('day'), // 昨日
    end: dayjs().endOf('day'), // 今日
    status: false, // 預設為 false
  });
  const { user } = useContext(AuthContext);

  // 將日期格式化抽成工具函式
  const formatDate = (dateTime) => {
    return dateTime ? dateTime.format('YYYY-MM-DD HH:mm') : '-';
  };

  // 核心欄位定義
  const coreColumns = [
    {
      title: '操作',
      key: 'action',
      width: 160,
      fixed: 'left',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="查看詳細資訊">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => handleViewDetail(record)}
            >
              查看
            </Button>
          </Tooltip>
          <Tooltip title={record.dealStatus ? '標記為未完成' : '標記為已完成'}>
            <Button
              type="link"
              icon={record.dealStatus ? <CloseOutlined /> : <CheckOutlined />}
              onClick={() => handleStatusToggle(record)}
            >
              {record.dealStatus ? '取消完成' : '標記完成'}
            </Button>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
      sorter: true,
      responsive: ['md'],
    },
    {
      title: '公司名稱',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 150,
      sorter: true,
      ellipsis: true,
    },
    {
      title: '聯絡人姓名',
      dataIndex: 'personName',
      key: 'personName',
      width: 150,
      sorter: true,
      ellipsis: true,
    },
    {
      title: '創建日期',
      dataIndex: 'createDate',
      key: 'createDate',
      width: 150,
      sorter: true,
      render: (value) => formatDate(dayjs(value)),
      responsive: ['md'],
    },
    {
      title: '交易狀態',
      dataIndex: 'dealStatus',
      key: 'dealStatus',
      width: 100,
      sorter: true,
      render: (value) => (value ? '已完成' : '未完成'),
      responsive: ['md'],
    },
  ];

  // Helper Functions to Convert Enum Values to Labels
  const getServiceTypeLabel = (value) => {
    const serviceTypes = {
      1: '類型A',
      2: '類型B',
      3: '類型C',
      // 添加其他類型...
    };
    return serviceTypes[value] || '未知';
  };

  const getParkingTypeLabel = (value) => {
    const parkingTypes = {
      1: '類型A',
      2: '類型B',
      3: '類型C',
      // 添加其他類型...
    };
    return parkingTypes[value] || '未知';
  };

  // Fetch Data Function
  const fetchData = async (page = 1, pageSize = 10) => {
    if (!user || !user.role) {
      console.warn('User role is undefined.');
      return;
    }

    setLoading(true);
    try {
      const params = {
        start: filters.start ? filters.start.format('YYYY-MM-DD') : undefined,
        end: filters.end ? filters.end.format('YYYY-MM-DD') : undefined,
        status: filters.status,
        pageNumber: page,
        pageSize: pageSize,
      };

      const response = await getAllInquiryForms(user.role, params);
      // 假設後端回傳: { pageNumber, pageSize, totalRecords, items }
      const { pageNumber: respPageNumber, pageSize: respPageSize, totalRecords, items } = response;

      setDataSource(items || []);
      setPagination({
        current: respPageNumber,
        pageSize: respPageSize,
        total: totalRecords,
      });

    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  // Initial Data Fetch and Refetch on Filters Change
  useEffect(() => {
    if (user && user.role) {
      // 當篩選條件改變時，重新從第1頁開始獲取資料
      fetchData(1, pagination.pageSize);
      setPagination((prev) => ({
        ...prev,
        current: 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, user]);

  // Handle Table Change (Pagination, Sorting)
  const handleTableChange = (newPagination, tableFilters, sorter) => {
    fetchData(newPagination.current, newPagination.pageSize);
  };

  // Handle Date Range Change
  const handleDateChange = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      setFilters((prev) => ({
        ...prev,
        start: dates[0],
        end: dates[1],
      }));
    }
  };

  // Handle Status Change
  const handleStatusChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      status: e.target.checked,
    }));
  };

  // Handle View Detail
  const handleViewDetail = (record) => {
    Modal.info({
      title: '表單詳細資訊',
      width: 600,
      content: (
        <ExpandableContent>
          <Row gutter={[16, 16]}>
            <Col span={12}><strong>公司名稱：</strong> {record.companyName}</Col>
            <Col span={12}><strong>聯絡人姓名：</strong> {record.personName}</Col>
            <Col span={12}><strong>職位：</strong> {record.position}</Col>
            <Col span={12}><strong>電話號碼：</strong> {record.phoneNumber}</Col>
            <Col span={12}><strong>電子郵件：</strong> {record.email}</Col>
            <Col span={12}><strong>服務類型：</strong> {getServiceTypeLabel(record.serviceType)}</Col>
            <Col span={12}><strong>停車類型：</strong> {getParkingTypeLabel(record.parkingType)}</Col>
            <Col span={12}><strong>需求停車位數量：</strong> {record.needParkingAmount || '-'}</Col>
            <Col span={12}><strong>委託停車位數量：</strong> {record.entrustParkingAmount || '-'}</Col>
            <Col span={12}><strong>需求面積上限：</strong> {record.needAreaUpper} 平方米</Col>
            <Col span={12}><strong>需求面積下限：</strong> {record.needAreaBottom} 平方米</Col>
            <Col span={24}><strong>需求描述：</strong> {record.needDescription || '-'}</Col>
            <Col span={12}><strong>創建日期：</strong> {formatDate(dayjs(record.createDate))}</Col>
            <Col span={12}><strong>交易狀態：</strong> {record.dealStatus ? '已完成' : '未完成'}</Col>
            <Col span={12}><strong>處理人：</strong> {record.userName}</Col>
          </Row>
        </ExpandableContent>
      ),
      onOk() {},
    });
  };

  // Handle Status Toggle
  const handleStatusToggle = async (record) => {
    const newStatus = !record.dealStatus;
    try {
      await updateFormStatus(user.role, { id: record.id, status: newStatus });
      message.success(`表單狀態已更新為 ${newStatus ? '已完成' : '未完成'}`);
      // 更新本地數據源
      setDataSource((prevData) =>
        prevData.map((item) =>
          item.id === record.id ? { ...item, dealStatus: newStatus } : item
        )
      );
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    }
  };

  // Expanded Row Render: 顯示額外詳細資訊（保留以備未來使用）
  const expandedRowRender = (record) => (
    <ExpandableContent>
      <Row gutter={[16, 16]}>
        <Col span={12}><strong>職位：</strong> {record.position}</Col>
        <Col span={12}><strong>電話號碼：</strong> {record.phoneNumber}</Col>
        <Col span={12}><strong>電子郵件：</strong> {record.email}</Col>
        <Col span={12}><strong>服務類型：</strong> {getServiceTypeLabel(record.serviceType)}</Col>
        <Col span={12}><strong>停車類型：</strong> {getParkingTypeLabel(record.parkingType)}</Col>
        <Col span={12}><strong>需求停車位數量：</strong> {record.needParkingAmount || '-'}</Col>
        <Col span={12}><strong>委託停車位數量：</strong> {record.entrustParkingAmount || '-'}</Col>
        <Col span={12}><strong>需求面積上限：</strong> {record.needAreaUpper} 平方米</Col>
        <Col span={12}><strong>需求面積下限：</strong> {record.needAreaBottom} 平方米</Col>
        <Col span={24}><strong>需求描述：</strong> {record.needDescription || '-'}</Col>
        <Col span={12}><strong>處理人 :</strong> {record.userName}</Col>
      </Row>
    </ExpandableContent>
  );

  return (
    <PageContainer>
      <HeaderRow justify="space-between" align="middle">
        <Col>
          <Title>詢價表單管理</Title>
        </Col>
        <Col>
          <Space>
            <RangePicker
              value={[filters.start, filters.end]}
              onChange={handleDateChange}
              allowClear={false}
              format="YYYY-MM-DD"
              disabledDate={(current) => current && current > dayjs().endOf('day')}
            />
            <StyledCheckbox checked={filters.status} onChange={handleStatusChange}>
              僅顯示已完成
            </StyledCheckbox>
          </Space>
        </Col>
      </HeaderRow>

      <Table
        columns={coreColumns}
        dataSource={dataSource}
        rowKey="id"
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `總共 ${total} 筆`,
        }}
        onChange={handleTableChange}
        bordered
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => record.needDescription || false,
        }}
      />
    </PageContainer>
  );
};

export default InquiryFormsSubPage;
