// src/pages/AboutImageManagementPage.jsx

import React, { useEffect, useState, useContext } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  InputNumber,
  Upload,
  message,
  Row,
  Col,
  Space,
  Image,
  Card,
} from 'antd';
import {
  UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import {
  // 原有圖片API
  getAboutImgs,
  uploadAboutImg,
  deleteAboutImg,
  updateAboutSort,
  // 以下為新加入的「關於我們文本」API
  getAboutContext,
  updateAboutContext,
} from '../../../api/totalApi';

import { AuthContext } from '../../../context/AuthContext';

// 引入 DraftEditor (富文本編輯器) — 請根據實際路徑調整
import DraftEditor from '../../../components/DraftEditor'; 

const AboutImageManagementSubPage = () => {
  const [loading, setLoading] = useState(false);

  // 關於我們圖片列表
  const [aboutImages, setAboutImages] = useState([]);

  // 關於我們的文本（HTML）
  const [aboutHtml, setAboutHtml] = useState('');
  const [initialLoaded, setInitialLoaded] = useState(false);
  // 控制「上傳圖片」Modal 顯示狀態
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);

  // 控制「修改排序」Modal 顯示狀態
  const [isSortModalVisible, setIsSortModalVisible] = useState(false);

  // 目前正在修改排序的那張圖片資訊
  const [currentImage, setCurrentImage] = useState(null);

  // 取得權限(若有需要)
  const { user } = useContext(AuthContext);

  // antd 內建的 Form 實例
  const [uploadForm] = Form.useForm();
  const [sortForm] = Form.useForm();

  // 預設失敗的圖片 URL
  const defaultImageUrl = '/default-failed.png';

  // 進入頁面後抓取「關於我們文本」以及「圖片列表」
  useEffect(() => {
    fetchAboutImages();
    fetchAboutContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 取得「關於我們」上方介紹文本
   */
  const fetchAboutContext = async () => {
    try {
      const result = await getAboutContext(user.role);
      const htmlFromBackend = result.data?.aboutHtml || '';
      setAboutHtml(htmlFromBackend);
      // 只在第一次載入時使用 key 強制初始化 DraftEditor
      if (!initialLoaded) {
        setInitialLoaded(true);
      }
    } catch (error) {
      console.error(error);
      message.error(error.response?.data || '取得關於我們文本失敗');
    }
  };

  /**
   * 更新「關於我們」文本
   */
  const handleSaveAboutContext = async () => {
    try {
      if (!aboutHtml) {
        message.warning('請先輸入關於我們的文本');
        return;
      }
      await updateAboutContext(user.role, aboutHtml);
      message.success('關於我們文本更新成功');
      // 若需要更新畫面，可再呼叫 fetchAboutContext() 驗證結果
    } catch (error) {
      console.error(error);
      message.error(error.response?.data || '更新關於我們文本失敗');
    }
  };

  /**
   * 取得所有「關於我們」圖片列表
   */
  const fetchAboutImages = async () => {
    setLoading(true);
    try {
      const result = await getAboutImgs(user.role);
      setAboutImages(result.data || []);
    } catch (error) {
      console.error(error);
      message.error(error.response?.data || '取得圖片列表失敗');
    } finally {
      setLoading(false);
    }
  };

  /**
   * 打開「上傳圖片」Modal
   */
  const openUploadModal = () => {
    uploadForm.resetFields();
    // 預設排序給 1
    uploadForm.setFieldsValue({ sort: 1 });
    setIsUploadModalVisible(true);
  };

  /**
   * 「上傳圖片」 - 確定按鈕
   */
  const handleUploadOk = async () => {
    try {
      const values = await uploadForm.validateFields();
      if (!values.imageFile) {
        message.warning('請選擇一張圖片');
        return;
      }
      const file = values.imageFile.file;
      if (!file) {
        message.warning('請選擇一張圖片');
        return;
      }

      await uploadAboutImg(user.role, file, values.sort || 1);
      message.success('圖片上傳成功');
      setIsUploadModalVisible(false);
      // 更新列表
      fetchAboutImages();
    } catch (error) {
      console.error(error);
      message.error(error.response?.data || '圖片上傳失敗');
    }
  };

  /**
   * 打開「修改排序」Modal
   */
  const openSortModal = (record) => {
    setCurrentImage(record);
    sortForm.resetFields();
    sortForm.setFieldsValue({ sort: record.sort });
    setIsSortModalVisible(true);
  };

  /**
   * 「修改排序」 - 確定按鈕
   */
  const handleSortOk = async () => {
    try {
      const values = await sortForm.validateFields();
      if (!currentImage) return;
      await updateAboutSort(user.role, currentImage.id, values.sort);
      message.success('排序修改成功');
      setIsSortModalVisible(false);
      fetchAboutImages();
    } catch (error) {
      console.error(error);
      message.error(error.response?.data || '修改排序失敗');
    }
  };

  /**
   * 刪除某張圖片
   */
  const handleDelete = async (record) => {
    try {
      await deleteAboutImg(user.role, record.id);
      message.success('圖片已刪除');
      fetchAboutImages();
    } catch (error) {
      console.error(error);
      message.error(error.response?.data || '刪除圖片失敗');
    }
  };

  /**
   * Table 欄位定義
   */
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      width: 80,
    },
    {
      title: '圖片',
      dataIndex: 'imgUrl',
      key: 'imgUrl',
      width: 320,
      render: (url) => (
        <Image
          width={300}
          height={300}
          src={url}
          fallback={defaultImageUrl}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultImageUrl;
          }}
        />
      ),
    },
    {
      title: '操作',
      key: 'action',
      width: 200,
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => openSortModal(record)}
          >
            調整排序
          </Button>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record)}
          >
            刪除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      {/* ======================== 關於我們文本編輯區 ======================== */}
      <Card title="關於我們 - 文本編輯" style={{ marginBottom: 24 }}>
        {/* DraftEditor 用於顯示 / 編輯 HTML 文字 */}
        <DraftEditor
          key={initialLoaded ? undefined : aboutHtml}
          value={aboutHtml}
          onChange={(newHtml) => setAboutHtml(newHtml)}
        />
        <div style={{ marginTop: 16 }}>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={handleSaveAboutContext}
          >
            儲存文字
          </Button>
        </div>
      </Card>

      {/* ======================== 圖片管理區 ======================== */}
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <h2>關於我們 - 圖片管理</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={openUploadModal}
          >
            上傳新圖片
          </Button>
        </Col>
      </Row>

      <Table
        rowKey="id"
        columns={columns}
        dataSource={aboutImages}
        loading={loading}
      />

      {/* ======================== 上傳圖片 Modal ======================== */}
      <Modal
        title="上傳關於我們圖片"
        open={isUploadModalVisible}
        onOk={handleUploadOk}
        onCancel={() => setIsUploadModalVisible(false)}
        destroyOnClose
      >
        <Form form={uploadForm} layout="vertical">
          <Form.Item
            label="選擇圖片"
            name="imageFile"
            rules={[{ required: true, message: '請選擇圖片' }]}
          >
            <Upload
              beforeUpload={() => false} // 禁止自動上傳
              multiple={false}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>選擇圖片</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="排序"
            name="sort"
            rules={[{ required: true, message: '請輸入排序' }]}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>

      {/* ======================== 修改排序 Modal ======================== */}
      <Modal
        title="修改圖片排序"
        open={isSortModalVisible}
        onOk={handleSortOk}
        onCancel={() => setIsSortModalVisible(false)}
        destroyOnClose
      >
        <Form form={sortForm} layout="vertical">
          <Form.Item
            label="排序"
            name="sort"
            rules={[{ required: true, message: '請輸入排序' }]}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AboutImageManagementSubPage;
