
// 定義所有選項的陣列格式

// 現況選項
export const CurrentSituation_OPTIONS = [
    { id: 1, name: "空屋" },
    { id: 2, name: "有隔間" },
    { id: 3, name: "裝潢" },
    { id: 4, name: "其他" },
];

export const BulidingSituation_OPTIONS = [
    { id: 1, name: "空調及送風機" },
    { id: 2, name: "燈具" },
    { id: 3, name: "地毯" },
    { id: 4, name: "天花板" },
    { id: 5, name: "其他" },
];

// 面相類型選項
export const Face_OPTIONS = [
    { id: 1, name: "坐北朝北" },
    { id: 2, name: "坐北朝南" },
    { id: 3, name: "坐北朝東" },
    { id: 4, name: "坐北朝西" },
    { id: 5, name: "坐南朝北" },
    { id: 6, name: "坐南朝南" },
    { id: 7, name: "坐南朝東" },
    { id: 8, name: "坐南朝西" },
    { id: 9, name: "坐東朝北" },
    { id: 10, name: "坐東朝南" },
    { id: 11, name: "坐東朝東" },
    { id: 12, name: "坐東朝西" },
    { id: 13, name: "坐西朝北" },
    { id: 14, name: "坐西朝南" },
    { id: 15, name: "坐西朝東" },
    { id: 16, name: "坐西朝西" },
];
  

// 委託類型選項
export const ObjectEntrustType_OPTIONS = [
  { id: 1, name: "一般物件" },        // NORMAL
  { id: 2, name: "專屬物件" },        // EXCLUSIVE
  { id: 3, name: "公檔" },            // PUBLIC
];

// 帶看方式選項
export const ShowWay_OPTIONS = [
  { id: 1, name: "直接帶看" },        // DIRECT
  { id: 2, name: "key in" },          // KEY_IN
  { id: 3, name: "聯絡業務員" },      // CONTACT
  { id: 4, name: "其他" },            // OTHER
];

// 管理費單位類型選項
export const ManagementFeeUnitType_OPTIONS = [
  { id: 1, name: "元/坪" },           // NUMBER_OF_SQUARE_METERS
  { id: 2, name: "元/月" },           // MONTH
];

// 管理費單位類型選項
export const SellPriceUnitType_OPTIONS = [
    { id: 1, name: "元/坪" },           // NUMBER_OF_SQUARE_METERS
    { id: 2, name: "元/戶" },           // MONTH
];

// 建築結構選項
export const BuildingStructure_OPTIONS = [
  { id: 1, name: "RC鋼筋混凝土" },     // RC
  { id: 2, name: "SC鋼骨結構" },       // SC
  { id: 3, name: "SRC鋼骨+鋼筋混凝土" }, // SRC
  { id: 4, name: "鐵皮" },              // IRON
  { id: 5, name: "空地" },              // EMPTY_LAND
  { id: 6, name: "磚造" },              // BRICK
  { id: 7, name: "RC+鐵皮" },          // RC_IRON
  { id: 8, name: "其他" },              // OTHER
];

// 物件狀態選項
export const ObjectStatus_OPTIONS = [
  { id: -1, name: "已結案" },          // OVER
  { id: 0, name: "準備中" },          // READY
  { id: 1, name: "出售中" },          // IN_SELLING
  { id: 2, name: "出租中" },          // IN_RENTING
  { id: -2, name: "下架中" },          // REMOVED
];

export const Demand_Type_OPTIONS = [
    { value: 0, label: '都可以' },
    { value: 1, label: '出售' },
    { value: 2, label: '出租' },
  ];

// 產品類型選項
export const ProductType_OPTIONS = [
  { id: 0, name: "混和" },             // ALL
  { id: 1, name: "出售" },             // SELLING
  { id: 2, name: "出租" },             // RENTING
];

/**
 * 根據給定的值獲取對應的委託類型名稱
 * @param {number} value - 委託類型的值
 * @returns {string} 委託類型的名稱
 */
export const getObjectEntrustType = (value) => {
  const type = ObjectEntrustType_OPTIONS.find(option => option.id === value);
  return type ? type.name : "未知類型";
};

/**
 * 根據給定的值獲取對應的帶看方式名稱
 * @param {number} value - 帶看方式的值
 * @returns {string} 帶看方式的名稱
 */
export const getShowWay = (value) => {
  const type = ShowWay_OPTIONS.find(option => option.id === value);
  return type ? type.name : "未知類型";
};

/**
 * 根據給定的值獲取對應的管理費單位類型名稱
 * @param {number} value - 管理費單位類型的值
 * @returns {string} 管理費單位類型的名稱
 */
export const getManagementFeeUnitType = (value) => {
  const type = ManagementFeeUnitType_OPTIONS.find(option => option.id === value);
  return type ? type.name : "未知類型";
};

/**
 * 根據給定的值獲取對應的建築結構名稱
 * @param {number} value - 建築結構的值
 * @returns {string} 建築結構的名稱
 */
export const getBuildingStructure = (value) => {
  const type = BuildingStructure_OPTIONS.find(option => option.id === value);
  return type ? type.name : "未知類型";
};

/**
 * 根據給定的值獲取對應的物件狀態名稱
 * @param {number} value - 物件狀態的值
 * @returns {string} 物件狀態的名稱
 */
export const getObjectStatus = (value) => {
  const type = ObjectStatus_OPTIONS.find(option => option.id === value);
  return type ? type.name : "未知類型";
};

/**
 * 根據給定的值獲取對應的產品類型名稱
 * @param {number} value - 產品類型的值
 * @returns {string} 產品類型的名稱
 */
export const getProductType = (value) => {
  const type = ProductType_OPTIONS.find(option => option.id === value);
  return type ? type.name : "未知類型";
};
