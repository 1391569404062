// src/pages/BusinessManManagementSubPage.jsx

import React, { useEffect, useState, useContext } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Upload,
  message,
  Row,
  Col,
  Space,
  DatePicker,
  Avatar,
  Divider,
  Switch,
  Radio,
  Select,
  Checkbox,
} from 'antd';
import {
  UploadOutlined,
  PlusOutlined,
  EditOutlined,
  UserOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';

// 新增 resetPassword API
import {
  createBusinessMan,
  getBusinessMans,
  updateBusinessMan,
  uploadBusinessManImg,
  getAllOptions,
  resetPassword,  // <<<--- 新增重設密碼
} from '../../../api/totalApi';

import { AuthContext } from '../../../context/AuthContext';

const { Option } = Select;
const { Descriptions } = require('antd');

export default function BusinessManManagementSubPage() {
  const [loading, setLoading] = useState(false);
  const [businessMans, setBusinessMans] = useState([]);  // 業務列表
  const [authGroups, setAuthGroups] = useState([]);       // 群組下拉選單
  const [searchName, setSearchName] = useState('');       // 搜尋用

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRecord, setEditRecord] = useState(null);

  // antd 的 Form：CRUD Modal
  const [form] = Form.useForm();

  // 取登入者資訊
  const { user } = useContext(AuthContext);

  // 「是否展開全部欄位」的狀態
  const [showAllColumns, setShowAllColumns] = useState(false);

  // ============== 重設密碼 ==============
  const [resetModalVisible, setResetModalVisible] = useState(false);
  const [resetForm] = Form.useForm();
  const [selectedUserId, setSelectedUserId] = useState(0);

  useEffect(() => {
    if (user) {
      fetchBusinessMans('');
      fetchDropdownOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  /**
   * 取得業務列表
   */
  const fetchBusinessMans = async (name = '') => {
    setLoading(true);
    try {
      const result = await getBusinessMans(user?.role, name);
      setBusinessMans(result.data || []);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  /**
   * 取得群組下拉選單
   */
  const fetchDropdownOptions = async () => {
    try {
      const allOptions = await getAllOptions();
      setAuthGroups(allOptions.authGroups || []);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data || '操作失敗');
    }
  };

  /**
   * 搜尋業務（依姓名查詢）
   */
  const handleSearch = () => {
    fetchBusinessMans(searchName);
  };

  /**
   * 打開「新增業務」對話框
   */
  const openCreateModal = () => {
    setIsEditMode(false);
    setEditRecord(null);
    form.resetFields();
    form.setFieldsValue({
      id: 0,
      account: '',
      password: '',
      authType: 1, // 業務
      businessNo: '',
      name: '',
      birthday: null,
      phone: '',
      onBoardDate: null,
      position: '',
      status: false,
      emergencyContactPerson: '',
      emergencyContactPhone: '',
      gender: 0,
      memo: '',
      authGroupId: 1,
    });
    setIsModalVisible(true);
  };

  /**
   * 打開「編輯業務」對話框
   */
  const openEditModal = (record) => {
    setIsEditMode(true);
    setEditRecord(record);

    const formValues = {
      id: record.id || 0,
      businessNo: record.businessNo || '',
      name: record.name || '',
      phone: record.phone || '',
      position: record.position || '',
      status: record.status || false,
      emergencyContactPerson: record.emergencyContactPerson || '',
      emergencyContactPhone: record.emergencyContactPhone || '',
      gender: record.gender !== undefined ? record.gender : 0,
      memo: record.memo || '',
      birthday: record.birthday ? dayjs(record.birthday) : null,
      onBoardDate: record.onBoardDate ? dayjs(record.onBoardDate) : null,
      authGroupId: record.authGroupId || 1,

      // 使用者資訊 (編輯時不顯示密碼)
      account: '',
      password: '',
      authType: 1,
    };

    form.setFieldsValue(formValues);
    setIsModalVisible(true);
  };

  /**
   * Modal 「確定」按鈕
   */
  const handleOkModal = async () => {
    try {
      const values = await form.validateFields();

      const birthdayStr = values.birthday
        ? values.birthday.format('YYYY-MM-DD')
        : null;
      const onBoardDateStr = values.onBoardDate
        ? values.onBoardDate.format('YYYY-MM-DD')
        : null;

      if (isEditMode && editRecord) {
        // 編輯
        const businessManRequest = {
          id: values.id,
          businessNo: values.businessNo,
          name: values.name,
          birthday: birthdayStr,
          phone: values.phone,
          onBoardDate: onBoardDateStr,
          position: values.position,
          status: values.status,
          emergencyContactPerson: values.emergencyContactPerson,
          emergencyContactPhone: values.emergencyContactPhone,
          gender: values.gender,
          memo: values.memo || '',
          authGroupId: values.authGroupId,
        };
        await updateBusinessMan(user.role, businessManRequest);
        message.success('修改業務成功');
      } else {
        // 新增
        const userRequest = {
          id: 0,
          account: values.account,
          password: values.password,
          authType: 1, // 業務
          businessManRequest: {
            id: 0,
            businessNo: values.businessNo,
            name: values.name,
            birthday: birthdayStr,
            phone: values.phone,
            onBoardDate: onBoardDateStr,
            position: values.position,
            status: values.status,
            emergencyContactPerson: values.emergencyContactPerson,
            emergencyContactPhone: values.emergencyContactPhone,
            gender: values.gender,
            memo: values.memo || '',
            authGroupId: values.authGroupId,
          },
        };
        await createBusinessMan(user.role, userRequest);
        message.success('新增業務成功');
      }
      setIsModalVisible(false);
      fetchBusinessMans(searchName);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data || '操作失敗');
    }
  };

  /**
   * 上傳大頭貼
   */
  const handleUploadImg = async (record, file) => {
    try {
      await uploadBusinessManImg(user.role, file, record.id);
      message.success(`上傳大頭貼成功: ${record.name}`);
      fetchBusinessMans(searchName);
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data || '操作失敗');
    }
  };

  // ============= 重設密碼 =============

  // 打開重設密碼 Modal
  const openResetPwModal = (record) => {
    // 假設後端返回了 record.account，若無需則另外調整
    setSelectedUserId(record.userId || 0);
    resetForm.resetFields();
    setResetModalVisible(true);
  };

  // 提交重設密碼
  const handleResetPwOk = async () => {
    try {
      const values = await resetForm.validateFields();
      // payload: { account, oldPassword, newPassword }
      // 由於是業務 => role=user.role
      const payload = {
        userId: selectedUserId,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };

      setResetModalVisible(false);
      // 如需呼叫API:
      const res = await resetPassword(user.role, payload);
      if (res.success) {
        message.success(res.successMessage || '重設密碼成功');
        setResetModalVisible(false);
      } else {
        message.error(res.errorMessages?.join(',') || '重設密碼失敗');
      }
    } catch (error) {
      console.error(error);
      message.error(error?.response?.data || '操作失敗');
    }
  };

  // ============ 主表格：僅放「少數關鍵欄位」 ============
  const basicColumns = [
    {
      title: '大頭貼',
      dataIndex: 'imgUrl',
      key: 'imgUrl',
      width: 90,
      render: (imgUrl) => (
        <Avatar
          size={48}
          src={imgUrl}
          icon={<UserOutlined />}
          style={{ backgroundColor: '#87d068' }}
        />
      ),
    },
    {
      title: '員編',
      dataIndex: 'businessNo',
      key: 'businessNo',
      width: 100,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: '電話',
      dataIndex: 'phone',
      key: 'phone',
      width: 110,
    },
    {
      title: '操作',
      key: 'action',
      width: 220,
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => openEditModal(record)}
          >
            編輯
          </Button>
          <Upload
            beforeUpload={(file) => {
              handleUploadImg(record, file);
              return false; 
            }}
            showUploadList={false}
          >
            <Button type="link" icon={<UploadOutlined />}>
              頭貼
            </Button>
          </Upload>

          {/* 重設密碼 */}
          <Button type="link" danger onClick={() => openResetPwModal(record)}>
            重設密碼
          </Button>
        </Space>
      ),
    },
  ];

  // ============ 展開列：顯示剩餘欄位 ============
  const expandedRowRender = (record) => {
    return (
      <Descriptions
        bordered
        size="small"
        column={2}
        style={{ backgroundColor: '#fafafa' }}
      >
        <Descriptions.Item label="職位">{record.position}</Descriptions.Item>
        <Descriptions.Item label="狀態">
          {record.status ? '啟用' : '停用'}
        </Descriptions.Item>
        <Descriptions.Item label="緊急聯絡人">
          {record.emergencyContactPerson}
        </Descriptions.Item>
        <Descriptions.Item label="緊急聯絡電話">
          {record.emergencyContactPhone}
        </Descriptions.Item>
        <Descriptions.Item label="性別">
          {record.gender === 0 ? '男' : record.gender === 1 ? '女' : '其他'}
        </Descriptions.Item>
        <Descriptions.Item label="備註">{record.memo}</Descriptions.Item>
        <Descriptions.Item label="生日" span={2}>
          {record.birthday || ''}
        </Descriptions.Item>
        <Descriptions.Item label="到職日" span={2}>
          {record.onBoardDate || ''}
        </Descriptions.Item>
        <Descriptions.Item label="群組" span={2}>
          {record.groupName || `(ID: ${record.authGroupId})`}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  // 若勾選「顯示全部欄位」，就啟用 expandedRowRender
  const tableExpandable = showAllColumns
    ? {
        expandedRowRender,
        rowExpandable: () => true,
      }
    : undefined;

  return (
    <div style={{ padding: 24 }}>
      {/* 搜尋區域 */}
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col xs={24} sm={12} md={6} lg={4}>
          <Input
            placeholder="輸入姓名搜尋"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            onPressEnter={handleSearch}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={handleSearch}>
            查詢
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={openCreateModal}
          >
            新增業務
          </Button>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          {/* 切換是否顯示全部欄位 */}
          <Checkbox
            checked={showAllColumns}
            onChange={(e) => setShowAllColumns(e.target.checked)}
          >
            顯示全部欄位
          </Checkbox>
        </Col>
      </Row>

      <Table
        rowKey="id"
        columns={basicColumns} 
        dataSource={businessMans}
        loading={loading}
        expandable={tableExpandable}
      />

      {/* 新增 / 編輯 Modal */}
      <Modal
        title={isEditMode ? '編輯業務' : '新增業務'}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleOkModal}
        destroyOnClose
        width={800}
      >
        <Form form={form} layout="vertical" name="businessManForm">
          {/* 新增時需輸入帳號＆密碼 */}
          {!isEditMode && (
            <>
              <Divider>使用者資訊 (UserRequest)</Divider>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="帳號(Account)"
                    name="account"
                    rules={[{ required: true, message: '請輸入帳號' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="密碼(Password)"
                    name="password"
                    rules={[{ required: true, message: '請輸入密碼' }]}
                  >
                    <Input type="password" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Divider>業務資訊 (BusinessManRequest)</Divider>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="員編(BusinessNo)"
                name="businessNo"
                rules={[{ required: true, message: '請輸入員編' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="姓名(Name)"
                name="name"
                rules={[{ required: true, message: '請輸入姓名' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="生日(Birthday)"
                name="birthday"
                rules={[{ required: true, message: '請選擇生日!' }]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="到職日(OnBoardDate)"
                name="onBoardDate"
                rules={[{ required: true, message: '請選擇到職日!' }]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="電話(Phone)"
                name="phone"
                rules={[{ required: true, message: '請輸入電話' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="職位(Position)"
                name="position"
                rules={[{ required: true, message: '請輸入職位!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="狀態(Status)"
                name="status"
                valuePropName="checked"
              >
                <Switch checkedChildren="啟用" unCheckedChildren="停用" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="性別(Gender)" name="gender">
                <Radio.Group>
                  <Radio value={0}>男</Radio>
                  <Radio value={1}>女</Radio>
                  <Radio value={2}>其他</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="緊急聯絡人(EmergencyContactPerson)"
                name="emergencyContactPerson"
                rules={[{ required: true, message: '請輸入緊急聯絡人!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="緊急聯絡電話(EmergencyContactPhone)"
                name="emergencyContactPhone"
                rules={[{ required: true, message: '請輸入緊急聯絡電話!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item label="備註(Memo)" name="memo" initialValue="">
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="群組(AuthGroupId)"
                name="authGroupId"
                rules={[{ required: true, message: '請選擇群組' }]}
              >
                <Select placeholder="選擇群組">
                  {authGroups.map((group) => (
                    <Option key={group.id} value={group.id}>
                      {group.groupName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* 隱藏欄位: id, authType */}
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="authType" hidden>
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* 重設密碼 Modal */}
      <Modal
        title="重設密碼"
        open={resetModalVisible}
        onCancel={() => setResetModalVisible(false)}
        onOk={handleResetPwOk}
        destroyOnClose
      >
        <Form form={resetForm} layout="vertical">
          <Form.Item
            label="舊密碼(OldPassword)"
            name="oldPassword"
            rules={[{ required: true, message: '請輸入舊密碼' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="新密碼(NewPassword)"
            name="newPassword"
            rules={[{ required: true, message: '請輸入新密碼' }]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
