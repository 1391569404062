import React from 'react';
import { Modal, Typography } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const { Paragraph } = Typography;

const StyledParagraph = styled(Paragraph)`
  font-size: 16px;
`;

export function MessageModal({ visible, onClose, title, message, isError }) {
  return (
    <Modal
      open={visible}
      onOk={onClose}
      onCancel={onClose}
      title={title}
      okText="OK"
      cancelButtonProps={{ style: { display: 'none' } }}
      centered={true}
    >
      <StyledParagraph type={isError ? 'danger' : 'success'}>
        {message}
      </StyledParagraph>
    </Modal>
  );
}

MessageModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired
};
