// src/pages/ObjectInformationSubPage.js
import React, { useEffect, useState,useContext } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Col,
  Drawer,
  DatePicker,
  Select,
  InputNumber,
  Checkbox,
  Tooltip,
  Descriptions
} from 'antd';
import styled from 'styled-components';
import {
  getPagedObjectInformation,
  createObjectInformation,
  updateObjectInformation,
  deleteObjectInformation,
  getAllOptions,
} from '../../../api/totalApi';
import { formatDateToYmd } from '../../../utils/date';
import {
  getObjectEntrustType,
  getShowWay,
  getProductType,
  getManagementFeeUnitType,
  getBuildingStructure,
  getObjectStatus,
  BuildingStructure_OPTIONS,
  ProductType_OPTIONS,
  Face_OPTIONS,
  ManagementFeeUnitType_OPTIONS,
  SellPriceUnitType_OPTIONS,
  CurrentSituation_OPTIONS,
  BulidingSituation_OPTIONS,
  ShowWay_OPTIONS,
  ObjectEntrustType_OPTIONS,
  ObjectStatus_OPTIONS
} from '../../../utils/constants';
import {
  EditOutlined,
  LinkOutlined,
  PictureOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  SaveOutlined,
  CopyOutlined
} from '@ant-design/icons';
import DraftEditor from '../../../components/DraftEditor';
import BindBusinessModal from '../../modal/BindBusinessModal';
import ManageImagesModal  from '../../modal/ManageImagesModal';
import ManageLawInfoModal from '../../modal/ManageLawInfoModal';
import moment from 'moment';
import { AuthContext } from '../../../context/AuthContext';

const { Option } = Select;
const { RangePicker } = DatePicker;

// Styled Components
const PageContainer = styled.div`
  pointer-events: auto;
  padding: 24px;
  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const HeaderRow = styled(Row)`
  margin-bottom: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    > div {
      margin-bottom: 8px;
    }
  }
`;

const Title = styled.h2`
  margin: 0;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const StyledTable = styled(Table)`
  margin-bottom: 16px;
  .ant-table {
    overflow-x: auto;
  }
`;

const FilterDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 320px !important;
  }
`;

const OperationContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ObjectInformationSubPage = () => {
  // 資料相關狀態
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  // 分頁、排序、篩選狀態
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null); // 'asc' or 'desc'
  const [filterParams, setFilterParams] = useState({}); // 篩選參數

  const [loading, setLoading] = useState(false);

  // 控制「物件法律資訊」彈窗
  const [lawInfoModalVisible, setLawInfoModalVisible] = useState(false);

  // Modal 狀態 (新增/編輯用)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRecord, setEditRecord] = useState(null);

  // Drawer 狀態 (篩選用)
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();

  // 下拉選單選項狀態
  const [objectTypes, setObjectTypes] = useState([]);
  const [partitionTypes, setPartitionTypes] = useState([]);
  const [areas, setAreas] = useState([]);
  const [areaDetails, setAreaDetails] = useState([]);
  const [businessMans, setBusinessMans] = useState([]);

  // 控制子彈窗的顯示與隱藏
  const [businessModalVisible, setBusinessModalVisible] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const { user } = useContext(AuthContext);

  const basicColumns = [
    {
      title: '操作',
      key: 'action',
      width: 120,
      fixed: 'left', // 視需要可固定
      render: (text, record) => (
        <OperationContainer>
          <Tooltip title="編輯">
            <Button type="text" icon={<EditOutlined />} onClick={() => handleEdit(record)} size="small" />
          </Tooltip>
          <Tooltip title="綁定業務">
            <Button type="text" icon={<LinkOutlined />} onClick={() => handleBindBusiness(record)} size="small" />
          </Tooltip>
          <Tooltip title="上傳圖片">
            <Button type="text" icon={<PictureOutlined />} onClick={() => handleUploadImgModal(record)} size="small" />
          </Tooltip>
          <Tooltip title="面積資訊">
            <Button type="text" icon={<InfoCircleOutlined />} onClick={() => handleManageLawInfo(record)} size="small" />
          </Tooltip>
          <Tooltip title="複製">
            <Button type="text" icon={<CopyOutlined  />} onClick={() => handleCopy(record)} size="small" />
          </Tooltip>
          <Tooltip title="刪除">
            <Popconfirm
              title="確定刪除？"
              onConfirm={() => handleDelete(record.id)}
              okText="是"
              cancelText="否"
            >
              <Button type="text" icon={<DeleteOutlined />} danger size="small" />
            </Popconfirm>
          </Tooltip>
        </OperationContainer>
      ),
    },
    {
      title: '產品類型(productType)',
      dataIndex: 'productType',
      key: 'productType',
      width: 130,
      render: (val) => getProductType(val),
    },
    {
      title: '物件編號(objectNo)',
      dataIndex: 'objectNo',
      key: 'objectNo',
      width: 120,
    },
    {
      title: '顯示起始日期(showStartDate)',
      dataIndex: 'showStartDate',
      key: 'showStartDate',
      width: 130,
      render: (val) => <span style={{ color: '#1890ff' }}>{formatDateToYmd(val)}</span>,
    },
    {
      title: '顯示結束日期(showEndDate)',
      dataIndex: 'showEndDate',
      key: 'showEndDate',
      width: 130,
      render: (val) => <span style={{ color: '#CE0000' }}>{formatDateToYmd(val)}</span>,
    },
    {
      title: '是否精選(isMain)',
      dataIndex: 'isMain',
      key: 'isMain',
      width: 110,
      render: (val) => (val ? '是' : '否'),
    },
  ];
  

  const expandedRowRender = (record) => {
    setEditRecord(record);
    return (
      <Descriptions bordered size="small" column={2} style={{ backgroundColor: '#fafafa' }}>
        <Descriptions.Item label="產品類型">
          {getProductType(record.productType)}
        </Descriptions.Item>
        <Descriptions.Item label="物件類型">
          {record.objectType?.objectTypeName || ''}
        </Descriptions.Item>
        <Descriptions.Item label="地區">
          {record.area ? (
            <>
              <div>{record.area.areaName}</div>
              {Array.isArray(record.area.areaDetail) && record.area.areaDetail.length > 0 && (
                <ul style={{ margin: 0, paddingLeft: '20px' }}>
                  {record.area.areaDetail.map((detail) => (
                    <li key={detail.id}>{detail.areaDetailDescription}</li>
                  ))}
                </ul>
              )}
            </>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item label="案件名稱" span={2}>
          {record.objectName}
        </Descriptions.Item>
        <Descriptions.Item label="結案狀態">
          {getObjectStatus(record.objectStatus)}
        </Descriptions.Item>
        <Descriptions.Item label="簡易地址" span={2}>
          {record.obfuscatedAddress}
        </Descriptions.Item>
        <Descriptions.Item label="詳細地址" span={2}>
          {record.detailedAddress}
        </Descriptions.Item>
        <Descriptions.Item label="GOOGLE地址" span={2}>
          {record.gooleMapAddress}
        </Descriptions.Item>
        <Descriptions.Item label="樓層規劃">
          {record.floorPlan}
        </Descriptions.Item>
        <Descriptions.Item label="臨路條件">
          {record.distance}
        </Descriptions.Item>
        <Descriptions.Item label="建築結構">
          {getBuildingStructure(record.buildingStructure)}
        </Descriptions.Item>
        <Descriptions.Item label="完工年份">
          {record.completionYear}
        </Descriptions.Item>
        <Descriptions.Item label="客梯部數">
          {record.passengerElevator}
        </Descriptions.Item>
        <Descriptions.Item label="載客數">
          {record.passengerAmount}
        </Descriptions.Item>
        <Descriptions.Item label="客梯備註" span={2}>
          {record.passengerElevatorMemo}
        </Descriptions.Item>
        <Descriptions.Item label="貨梯部數">
          {record.freightElevator}
        </Descriptions.Item>
        <Descriptions.Item label="載貨噸數">
          {record.freightAmount}
        </Descriptions.Item>
        <Descriptions.Item label="貨梯備註" span={2}>
          {record.freightElevatorMemo}
        </Descriptions.Item>
        <Descriptions.Item label="大樓座向">
          {record.buildingFace}
        </Descriptions.Item>
        <Descriptions.Item label="樓上座向">
          {record.upstairsFace}
        </Descriptions.Item>
        <Descriptions.Item label="管理費">
          {record.managementFee}
        </Descriptions.Item>
        <Descriptions.Item label="管理費單位">
          {getManagementFeeUnitType(record.managementFeeUnitType)}
        </Descriptions.Item>
        <Descriptions.Item label="含公共用電">
          {record.managementFeeIsContainPublicWateAndElectricity ? '是' : '否'}
        </Descriptions.Item>
        <Descriptions.Item label="現況">
          {record.currentSituation}
        </Descriptions.Item>
        <Descriptions.Item label="屋況" span={6}>
          {record.bulidingSituation}
        </Descriptions.Item>
        <Descriptions.Item label="房屋租金">{record.rent}</Descriptions.Item>
        <Descriptions.Item label="租金單位">{record.rentUnit}</Descriptions.Item>
        <Descriptions.Item label="是否含稅">
          {record.hasRentTax ? '是' : '否'}
        </Descriptions.Item>
        <Descriptions.Item label="租金稅率">{record.rentTaxRate}</Descriptions.Item>
        <Descriptions.Item label="房屋售價">
          {record.sellPrice}
        </Descriptions.Item>
        <Descriptions.Item label="出售單位">{record.sellPriceUnit}</Descriptions.Item>
        <Descriptions.Item label="是否含稅">
          {record.hasSellPriceTax ? '是' : '否'}
        </Descriptions.Item>
        <Descriptions.Item label="售價稅率">{record.sellPriceTaxRate}</Descriptions.Item>
        <Descriptions.Item label="機械車位數">
          {record.mechanicalParkingAmount}
        </Descriptions.Item>
        <Descriptions.Item label="平面車位數">
          {record.flatParkingAmount}
        </Descriptions.Item>
        <Descriptions.Item label="車位租金">{record.parkingRent}</Descriptions.Item>
        <Descriptions.Item label="車位售價">{record.parkingPrice}</Descriptions.Item>
        <Descriptions.Item label="房屋押金月數">
          {record.depositMonths}
        </Descriptions.Item>
        <Descriptions.Item label="押金備註">
          {record.depositMonthsMemo}
        </Descriptions.Item>
        <Descriptions.Item label="免租裝潢期">
          {record.freeDaysForDecoration} 天
        </Descriptions.Item>
        <Descriptions.Item label="免租裝潢期備註">
          {record.freeDaysForDecorationMemo}
        </Descriptions.Item>
        <Descriptions.Item label="特色說明" span={2}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <DraftEditor 
              value={editRecord && editRecord.id === record.id ? editRecord.detailedInformation : record.detailedInformation} 
              onChange={handleDetailedInfoChange}
            />
            <Button type="primary" icon={<SaveOutlined />}  style={{ alignSelf: 'center' }} onClick={() => handleUpdateHtml(editRecord)}>儲存特色說明</Button>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="租賃佣金">
          {record.rentCommission}
        </Descriptions.Item>
        <Descriptions.Item label="出售佣金">{record.sellCommission}</Descriptions.Item>
        <Descriptions.Item label="屋主">{record.homeowner}</Descriptions.Item>
        <Descriptions.Item label="屋主手機">{record.homeownerPhone}</Descriptions.Item>
        <Descriptions.Item label="屋主電話">{record.homeownerNumber}</Descriptions.Item>
        <Descriptions.Item label="帶看方式">{getShowWay(record.showWay)}</Descriptions.Item>
        <Descriptions.Item label="物件委託類型">{getObjectEntrustType(record.objectEntrustType)}</Descriptions.Item>
        <Descriptions.Item label="委託起始日">
          <span style={{ color: '#1890ff' }}>
            {formatDateToYmd(record.objectEntrustStartDate)}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="委託結束日">
          <span style={{ color: '#CE0000' }}>
            {formatDateToYmd(record.objectEntrustEndDate)}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="廣告物" span={2}>
          {record.ad}
        </Descriptions.Item>
        <Descriptions.Item label="備註說明" span={2}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <DraftEditor 
              value={editRecord && editRecord.id === record.id ? editRecord.memo : record.memo} 
              onChange={handleMemoChange}
            />
            <Button type="primary" icon={<SaveOutlined />}  style={{ alignSelf: 'center' }} onClick={() => handleUpdateHtml(editRecord)}>儲存備註</Button>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="關鍵字" span={2}>
          {record.keyWords}
        </Descriptions.Item>
        <Descriptions.Item label="是否熱搜">{record.isHot ? '是' : '否'}</Descriptions.Item>
        <Descriptions.Item label="是否必看">{record.mustSee ? '是' : '否'}</Descriptions.Item>
        <Descriptions.Item label="建檔日期">
          <span style={{ color: '#CE0000' }}>{formatDateToYmd(record.createDate)}</span>
        </Descriptions.Item>
        <Descriptions.Item label="建檔人">{record.creatorName}</Descriptions.Item>
        <Descriptions.Item label="後台備註說明" span={2}>
          {record.backstageMemo}
        </Descriptions.Item>
        <Descriptions.Item label="聯絡人1">{record.contactPersonName1}</Descriptions.Item>
        <Descriptions.Item label="聯絡人1職稱">{record.contactPersonPosition1}</Descriptions.Item>
        <Descriptions.Item label="聯絡人1手機">{record.contactPersonPhone1}</Descriptions.Item>
        <Descriptions.Item label="聯絡人1電話">{record.contactPersonNumber1}</Descriptions.Item>
        <Descriptions.Item label="聯絡人2">{record.contactPersonName2}</Descriptions.Item>
        <Descriptions.Item label="聯絡人2職稱">{record.contactPersonPosition2}</Descriptions.Item>
        <Descriptions.Item label="聯絡人2手機">{record.contactPersonPhone2}</Descriptions.Item>
        <Descriptions.Item label="聯絡人2電話">{record.contactPersonNumber2}</Descriptions.Item>
      </Descriptions>
    );
  };
  const handleDetailedInfoChange = (newHtml) => {
    if(editRecord) {
      editRecord.detailedInformation = newHtml;
      setEditRecord(editRecord);
    }
  };
  const handleMemoChange = (newHtml) => {
    if(editRecord) {
      editRecord.memo = newHtml;
      setEditRecord(editRecord);
    }
  };
  const handleBindBusiness = (record) => {
    setCurrentRecord(record);
    setBusinessModalVisible(true);
  };

  const handleUploadImgModal = (record) => {
    setCurrentRecord(record);
    setImageModalVisible(true);
  };

  // 取得下拉選單選項 (從 API)
  const fetchDropdownOptions = async () => {
    try {
      // 使用 await 確保獲取到資料
      const allOptions = await getAllOptions();
      // 確保資料存在且為陣列
      setObjectTypes(allOptions.objectTypes || []);
      setPartitionTypes(allOptions.partitionTypes || []);
      setAreas(allOptions.areas || []);
      setBusinessMans(allOptions.businessMans || []);
    } catch (error) {
            message.error(error.response.data || '操作失敗');
    }
  };

  // 取得資料
  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        pageNumber,
        pageSize,
        ...filterParams,
      };
      if (sortField && sortOrder) {
        params.sortField = sortField;
        params.sortOrder = sortOrder;
      }

      const result = await getPagedObjectInformation(user.role,params);
      // result 為 PagedResult<ObjectInformationDTO>
      setData(result.items);
      setTotalRecords(result.totalRecords);
    } catch (error) {
            message.error(error.response.data || '操作失敗');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDropdownOptions(user.role); // 取得下拉選單選項
    fetchData(user.role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, sortField, sortOrder, filterParams]);

  // 處理分頁、排序變更
  const handleTableChange = (pagination, filters, sorter) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);

    if (sorter && sorter.field) {
      setSortField(sorter.field);
      if (sorter.order === 'ascend') {
        setSortOrder('asc');
      } else if (sorter.order === 'descend') {
        setSortOrder('desc');
      } else {
        setSortField(null);
        setSortOrder(null);
      }
    } else {
      setSortField(null);
      setSortOrder(null);
    }
  };

  const handleAreaChange = (value) => {
    if (value) {
      const selectedArea = areas.find(area => area.id === value);
      if (selectedArea) {
        setAreaDetails(selectedArea.areaDetail || []);
        // 重置 areaDetailId 的值
        form.setFieldsValue({ areaDetailId: undefined });
      } else {
        setAreaDetails([]);
        form.setFieldsValue({ areaDetailId: undefined });
      }
    } else {
      setAreaDetails([]);
      form.setFieldsValue({ areaDetailId: undefined });
    }
  };


  // 開啟新增Modal
  const handleOpenModal = () => {
    setIsEditMode(false);
    setEditRecord(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  // 關閉Modal
  const handleCancelModal = () => {
    // form.resetFields();
    setIsModalVisible(false);
  };

  const handleUpdateHtml =async (record) => { 
    const safeString = (val) => (val === undefined || val === null || val === '' ? '尚未編輯' : val);
    const safeInt = (val) => (val === undefined || val === null ? 0 : val);
    const payload = {
      // int 欄位
      Id: safeInt(record.id),
      ObjectTypeId: safeInt(record.objectTypeId),
      AreaId: safeInt(record.areaId),
      AreaDetailId: safeInt(record.areaDetailId),
      PartitionTypeId: safeInt(record.partitionTypeId),
      Distance: safeInt(record.distance),
      BuildingStructure: safeInt(record.buildingStructure),
      PassengerElevator: safeInt(record.passengerElevator),
      PassengerAmount: safeInt(record.passengerAmount),
      FreightElevator: safeInt(record.freightElevator),
      FreightAmount: safeInt(record.freightAmount),
      ManagementFee: safeInt(record.managementFee),
      Rent: record.rent !== undefined && record.rent !== null ? safeInt(record.rent) : null,
      RentUnit: record.rent !== undefined && record.rent !== null ? safeInt(record.rentUnit) : null,
      RentTaxRate:
      record.rentTaxRate !== undefined && record.rentTaxRate !== null ? safeInt(record.rentTaxRate) : null,
      MechanicalParkingAmount: safeInt(record.mechanicalParkingAmount),
      FlatParkingAmount: safeInt(record.flatParkingAmount),
      ParkingRent: safeInt(record.parkingRent),
      ParkingPrice: safeInt(record.parkingPrice),
      DepositMonths: safeInt(record.depositMonths),
      FreeDaysForDecoration: safeInt(record.freeDaysForDecoration),
      SellPrice: record.sellPrice !== undefined && record.sellPrice !== null ? safeInt(record.sellPrice) : null,
      SellPriceUnit: record.sellPriceUnit !== undefined && record.sellPriceUnit !== null ? safeInt(record.sellPriceUnit) : null,
      SellPriceTaxRate:
      record.sellPriceTaxRate !== undefined && record.sellPriceTaxRate !== null
          ? safeInt(record.sellPriceTaxRate)
          : null,
      ProductType: safeInt(record.productType),
      ManagementFeeUnitType: safeInt(record.managementFeeUnitType),

      // string 欄位
      ObjectNo: safeString(record.objectNo),
      ObjectName: safeString(record.objectName),
      ObfuscatedAddress: safeString(record.obfuscatedAddress),
      DetailedAddress: safeString(record.detailedAddress),
      GooleMapAddress: safeString(record.gooleMapAddress),
      Memo: safeString(record.memo),
      KeyWords: safeString(record.keyWords),
      Ad: safeString(record.ad),
      Homeowner: safeString(record.homeowner),
      HomeownerPhone: safeString(record.homeownerPhone),
      HomeownerNumber: safeString(record.homeownerNumber),
      FloorPlan: safeString(record.floorPlan),
      BuildingFace: safeString(record.buildingFace),
      UpstairsFace: safeString(record.upstairsFace),
      CurrentSituation: safeString(record.currentSituation),
      BulidingSituation: safeString(record.bulidingSituation),
      DepositMonthsMemo: safeString(record.depositMonthsMemo),
      DetailedInformation: safeString(record.detailedInformation),
      FreightElevatorMemo: safeString(record.freightElevatorMemo),
      PassengerElevatorMemo: safeString(record.passengerElevatorMemo),
      FreeDaysForDecorationMemo: safeString(record.freeDaysForDecorationMemo),
      CompletionYear: safeString(record.completionYear ? record.completionYear.toString() : ''),
      BackstageMemo: safeString(record.backstageMemo),
      ContactPersonName1: safeString(record.contactPersonName1),
      ContactPersonPosition1: safeString(record.contactPersonPosition1),
      ContactPersonPhone1: safeString(record.contactPersonPhone1),
      ContactPersonNumber1: safeString(record.contactPersonNumber1),
      ContactPersonName2: safeString(record.contactPersonName2),
      ContactPersonPosition2: safeString(record.contactPersonPosition2),
      ContactPersonPhone2: safeString(record.contactPersonPhone2),
      ContactPersonNumber2: safeString(record.contactPersonNumber2),

      // boolean 欄位
      HasRentTax: record.hasRentTax ?? false,
      HasSellPriceTax: record.hasSellPriceTax ?? false,
      IsMain: record.isMain ?? false,
      IsHot: record.isHot ?? false,
      MustSee: record.mustSee ?? false,
      ManagementFeeIsContainPublicWateAndElectricity:
      record.managementFeeIsContainPublicWateAndElectricity ?? false,

      // date 欄位
      ShowStartDate: record.showStartDate
        ? moment(record.showStartDate).format('YYYY-MM-DD')
        : null,
      ShowEndDate: record.showEndDate
        ? moment(record.showEndDate).format('YYYY-MM-DD')
        : null,
      ObjectEntrustStartDate: record.objectEntrustStartDate
        ? moment(record.objectEntrustStartDate).format('YYYY-MM-DD')
        : null,
      ObjectEntrustEndDate: record.objectEntrustEndDate
        ? moment(record.objectEntrustEndDate).format('YYYY-MM-DD')
        : null,
    };
    await updateObjectInformation(user.role, payload);
    await fetchData(user.role);
    message.success('更新成功');
  };

  // 確定Modal送出(新增或編輯)
// 範例：示意 handleOkModal
const handleOkModal = async () => {
  try {
    const values = await form.validateFields();

    // Helper functions to ensure non-null values
    const safeString = (val) => (val === undefined || val === null || val === '' ? '尚未編輯' : val);
    const safeInt = (val) => (val === undefined || val === null ? 0 : val);

    // 構建 payload，確保欄位名稱與後端一致，並且類型正確
    const payload = {
      // int 欄位
      Id: safeInt(values.id),
      ObjectTypeId: safeInt(values.objectTypeId),
      AreaId: safeInt(values.areaId),
      AreaDetailId: safeInt(values.areaDetailId),
      PartitionTypeId: safeInt(values.partitionTypeId),
      Distance: safeInt(values.distance),
      BuildingStructure: safeInt(values.buildingStructure),
      PassengerElevator: safeInt(values.passengerElevator),
      PassengerAmount: safeInt(values.passengerAmount),
      FreightElevator: safeInt(values.freightElevator),
      FreightAmount: safeInt(values.freightAmount),
      ManagementFee: safeInt(values.managementFee),
      Rent: values.rent !== undefined && values.rent !== null ? safeInt(values.rent) : null,
      RentUnit: values.rent !== undefined && values.rent !== null ? safeInt(values.rentUnit) : null,
      RentTaxRate:
        values.rentTaxRate !== undefined && values.rentTaxRate !== null ? safeInt(values.rentTaxRate) : null,
      MechanicalParkingAmount: safeInt(values.mechanicalParkingAmount),
      FlatParkingAmount: safeInt(values.flatParkingAmount),
      ParkingRent: safeInt(values.parkingRent),
      ParkingPrice: safeInt(values.parkingPrice),
      DepositMonths: safeInt(values.depositMonths),
      FreeDaysForDecoration: safeInt(values.freeDaysForDecoration),
      SellPrice: values.sellPrice !== undefined && values.sellPrice !== null ? safeInt(values.sellPrice) : null,
      SellPriceUnit: values.sellPriceUnit !== undefined && values.sellPriceUnit !== null ? safeInt(values.sellPriceUnit) : null,
      SellPriceTaxRate:
        values.sellPriceTaxRate !== undefined && values.sellPriceTaxRate !== null
          ? safeInt(values.sellPriceTaxRate)
          : null,
      ProductType: safeInt(values.productType),
      ManagementFeeUnitType: safeInt(values.managementFeeUnitType),

      // string 欄位
      ObjectNo: safeString(values.objectNo),
      ObjectName: safeString(values.objectName),
      ObfuscatedAddress: safeString(values.obfuscatedAddress),
      DetailedAddress: safeString(values.detailedAddress),
      GooleMapAddress: safeString(values.gooleMapAddress),
      Memo: safeString(values.memo),
      KeyWords: safeString(values.keyWords),
      Ad: safeString(values.ad),
      Homeowner: safeString(values.homeowner),
      HomeownerPhone: safeString(values.homeownerPhone),
      HomeownerNumber: safeString(values.homeownerNumber),
      FloorPlan: safeString(values.floorPlan),
      BuildingFace: safeString(values.buildingFace),
      UpstairsFace: safeString(values.upstairsFace),
      CurrentSituation: safeString(values.currentSituation),
      BulidingSituation: safeString(values.bulidingSituation),
      DepositMonthsMemo: safeString(values.depositMonthsMemo),
      DetailedInformation: safeString(values.detailedInformation),
      FreightElevatorMemo: safeString(values.freightElevatorMemo),
      PassengerElevatorMemo: safeString(values.passengerElevatorMemo),
      FreeDaysForDecorationMemo: safeString(values.freeDaysForDecorationMemo),
      CompletionYear: safeString(values.completionYear ? values.completionYear.toString() : ''),
      BackstageMemo: safeString(values.backstageMemo),
      ContactPersonName1: safeString(values.contactPersonName1),
      ContactPersonPosition1: safeString(values.contactPersonPosition1),
      ContactPersonPhone1: safeString(values.contactPersonPhone1),
      ContactPersonNumber1: safeString(values.contactPersonNumber1),
      ContactPersonName2: safeString(values.contactPersonName2),
      ContactPersonPosition2: safeString(values.contactPersonPosition2),
      ContactPersonPhone2: safeString(values.contactPersonPhone2),
      ContactPersonNumber2: safeString(values.contactPersonNumber2),

      // boolean 欄位
      HasRentTax: values.hasRentTax ?? false,
      HasSellPriceTax: values.hasSellPriceTax ?? false,
      IsMain: values.isMain ?? false,
      IsHot: values.isHot ?? false,
      MustSee: values.mustSee ?? false,
      ManagementFeeIsContainPublicWateAndElectricity:
        values.managementFeeIsContainPublicWateAndElectricity ?? false,

      // date 欄位
      ShowStartDate: values.showStartDate ? values.showStartDate.format('YYYY-MM-DD') : null,
      ShowEndDate: values.showEndDate ? values.showEndDate.format('YYYY-MM-DD') : null,
      ObjectEntrustStartDate: values.objectEntrustStartDate
        ? values.objectEntrustStartDate.format('YYYY-MM-DD')
        : null,
      ObjectEntrustEndDate: values.objectEntrustEndDate
        ? values.objectEntrustEndDate.format('YYYY-MM-DD')
        : null,
    };

    // 確保必填欄位有合理的值
    // (如果後端有更多必填欄位，請在此處設置預設值或從表單獲取)

    if (isEditMode && editRecord) {
      payload.Id = editRecord.id;
      await updateObjectInformation(user.role, payload);
      message.success('更新成功');
    } else {
      payload.Id = 0;
      await createObjectInformation(user.role, payload);
      message.success('新增成功');
    }

    // form.resetFields();
    setIsModalVisible(false);
    await fetchData(user.role);
  } catch (error) {
    if (error.response && error.response.data) {
      const errorData = error.response.data;
      if (errorData.errors) {
        const messages = Object.values(errorData.errors).flat().join(', ');
        message.error(messages || '操作失敗');
      } else if (errorData.message) {
        message.error(errorData.message);
      } else {
        message.error('操作失敗');
      }
    } else {
      message.error('操作失敗');
    }
  }
};


  // 編輯
  const handleEdit = (record) => {
    setIsEditMode(true);
    setEditRecord(record);
    // 將 record 的值設定到 form 中
    form.setFieldsValue({
      productType: record.productType,
      objectNo: record.objectNo,
      objectName: record.objectName,
      objectTypeId: record.objectTypeId,
      areaId: record.areaId,
      areaDetailId: record.areaDetailId,
      obfuscatedAddress: record.obfuscatedAddress,
      detailedAddress: record.detailedAddress,
      gooleMapAddress: record.gooleMapAddress,
      showStartDate: record.showStartDate ? moment(record.showStartDate) : null,
      showEndDate: record.showEndDate ? moment(record.showEndDate) : null,
      partitionTypeId: record.partitionTypeId,
      floorPlan: record.floorPlan,
      distance: record.distance,
      buildingStructure: record.buildingStructure,
      completionYear: record.completionYear,
      passengerElevator: record.passengerElevator,
      passengerAmount: record.passengerAmount,
      passengerElevatorMemo: record.passengerElevatorMemo,
      freightElevator: record.freightElevator,
      freightAmount: record.freightAmount,
      freightElevatorMemo: record.freightElevatorMemo,
      buildingFace: record.buildingFace,
      upstairsFace: record.upstairsFace,
      managementFee: record.managementFee,
      managementFeeUnitType: record.managementFeeUnitType,
      managementFeeIsContainPublicWateAndElectricity: record.managementFeeIsContainPublicWateAndElectricity,
      currentSituation: record.currentSituation,
      bulidingSituation: record.bulidingSituation,
      rent: record.rent,
      hasRentTax: record.hasRentTax,
      rentTaxRate: record.rentTaxRate,
      mechanicalParkingAmount: record.mechanicalParkingAmount,
      flatParkingAmount: record.flatParkingAmount, // 確保使用正確名稱 flatParking
      parkingRent: record.parkingRent,
      parkingPrice: record.parkingPrice,
      depositMonths: record.depositMonths,
      depositMonthsMemo: record.depositMonthsMemo,
      freeDaysForDecoration: record.freeDaysForDecoration,
      freeDaysForDecorationMemo: record.freeDaysForDecorationMemo,
      detailedInformation: record.detailedInformation,
      rentCommission: record.rentCommission,
      sellCommission: record.sellCommission,
      homeowner: record.homeowner,
      homeownerPhone: record.homeownerPhone,
      homeownerNumber: record.homeownerNumber,
      showWay: record.showWay,
      objectEntrustType: record.objectEntrustType,
      objectEntrustStartDate: record.objectEntrustStartDate ? moment(record.objectEntrustStartDate) : null,
      objectEntrustEndDate: record.objectEntrustEndDate ? moment(record.objectEntrustEndDate) : null,
      ad: record.ad,
      memo: record.memo,
      keyWords: record.keyWords,
      objectStatus: record.objectStatus,
      isMain: record.isMain,
      rentUnit: record.rentUnit,
      sellPrice: record.sellPrice,
      sellPriceUnit: record.sellPriceUnit,
      hasSellPriceTax: record.hasSellPriceTax,
      sellPriceTaxRate: record.sellPriceTaxRate,
      mustSee: record.mustSee, // 這是後端新加入的屬性
      isHot: record.isHot, // 這是後端新加入的屬性
      backstageMemo: record.backstageMemo, // 這是後端新加入的屬性
      contactPersonName1: record.contactPersonName1, // 後端的欄位
      contactPersonPosition1: record.contactPersonPosition1, // 後端的欄位
      contactPersonPhone1: record.contactPersonPhone1, // 後端的欄位
      contactPersonNumber1: record.contactPersonNumber1, // 後端的欄位
      contactPersonName2: record.contactPersonName2, // 後端的欄位
      contactPersonPosition2: record.contactPersonPosition2, // 後端的欄位
      contactPersonPhone2: record.contactPersonPhone2, // 後端的欄位
      contactPersonNumber2: record.contactPersonNumber2, // 後端的欄位
    });
    setIsModalVisible(true);
  };
  const handleCopy = (record) => {
    setIsEditMode(false);
    setEditRecord(record);
    // 將 record 的值設定到 form 中
    form.setFieldsValue({
      productType: record.productType,
      objectNo: record.objectNo,
      objectName: record.objectName,
      objectTypeId: record.objectTypeId,
      areaId: record.areaId,
      areaDetailId: record.areaDetailId,
      obfuscatedAddress: record.obfuscatedAddress,
      detailedAddress: record.detailedAddress,
      gooleMapAddress: record.gooleMapAddress,
      showStartDate: record.showStartDate ? moment(record.showStartDate) : null,
      showEndDate: record.showEndDate ? moment(record.showEndDate) : null,
      partitionTypeId: record.partitionTypeId,
      floorPlan: record.floorPlan,
      distance: record.distance,
      buildingStructure: record.buildingStructure,
      completionYear: record.completionYear,
      passengerElevator: record.passengerElevator,
      passengerAmount: record.passengerAmount,
      passengerElevatorMemo: record.passengerElevatorMemo,
      freightElevator: record.freightElevator,
      freightAmount: record.freightAmount,
      freightElevatorMemo: record.freightElevatorMemo,
      buildingFace: record.buildingFace,
      upstairsFace: record.upstairsFace,
      managementFee: record.managementFee,
      managementFeeUnitType: record.managementFeeUnitType,
      managementFeeIsContainPublicWateAndElectricity: record.managementFeeIsContainPublicWateAndElectricity,
      currentSituation: record.currentSituation,
      bulidingSituation: record.bulidingSituation,
      rent: record.rent,
      hasRentTax: record.hasRentTax,
      rentTaxRate: record.rentTaxRate,
      mechanicalParkingAmount: record.mechanicalParkingAmount,
      flatParkingAmount: record.flatParkingAmount, // 確保使用正確名稱 flatParking
      parkingRent: record.parkingRent,
      parkingPrice: record.parkingPrice,
      depositMonths: record.depositMonths,
      depositMonthsMemo: record.depositMonthsMemo,
      freeDaysForDecoration: record.freeDaysForDecoration,
      freeDaysForDecorationMemo: record.freeDaysForDecorationMemo,
      detailedInformation: record.detailedInformation,
      rentCommission: record.rentCommission,
      sellCommission: record.sellCommission,
      homeowner: record.homeowner,
      homeownerPhone: record.homeownerPhone,
      homeownerNumber: record.homeownerNumber,
      showWay: record.showWay,
      objectEntrustType: record.objectEntrustType,
      objectEntrustStartDate: record.objectEntrustStartDate ? moment(record.objectEntrustStartDate) : null,
      objectEntrustEndDate: record.objectEntrustEndDate ? moment(record.objectEntrustEndDate) : null,
      ad: record.ad,
      memo: record.memo,
      keyWords: record.keyWords,
      objectStatus: record.objectStatus,
      isMain: record.isMain,
      rentUnit: record.rentUnit,
      sellPrice: record.sellPrice,
      sellPriceUnit: record.sellPriceUnit,
      hasSellPriceTax: record.hasSellPriceTax,
      sellPriceTaxRate: record.sellPriceTaxRate,
      mustSee: record.mustSee, // 這是後端新加入的屬性
      isHot: record.isHot, // 這是後端新加入的屬性
      backstageMemo: record.backstageMemo, // 這是後端新加入的屬性
      contactPersonName1: record.contactPersonName1, // 後端的欄位
      contactPersonPosition1: record.contactPersonPosition1, // 後端的欄位
      contactPersonPhone1: record.contactPersonPhone1, // 後端的欄位
      contactPersonNumber1: record.contactPersonNumber1, // 後端的欄位
      contactPersonName2: record.contactPersonName2, // 後端的欄位
      contactPersonPosition2: record.contactPersonPosition2, // 後端的欄位
      contactPersonPhone2: record.contactPersonPhone2, // 後端的欄位
      contactPersonNumber2: record.contactPersonNumber2, // 後端的欄位
    });
    setIsModalVisible(true);
  };

  // 刪除
  const handleDelete = async (id) => {
    try {
      await deleteObjectInformation(id);
      message.success('刪除成功');
      await fetchData();
    } catch (error) {
      message.error(error.response.data || '操作失敗');
    }
  };

  // 開啟篩選Drawer
  const openFilter = () => {
    filterForm.setFieldsValue({
      // 每個欄位請對應後端 API 參數
      objectNo: filterParams.objectNo || '',
      objectTypeId: filterParams.objectTypeId || undefined,
      areaId: filterParams.areaId || undefined,
      partitionTypeId: filterParams.partitionTypeId || undefined,
      showDateRange:
        filterParams.showStartDate && filterParams.showEndDate
          ? [moment(filterParams.showStartDate), moment(filterParams.showEndDate)]
          : [],
      elevatorType: filterParams.elevatorType || undefined,
      buildingFace: filterParams.buildingFace || '',
      upstairsFace: filterParams.upstairsFace || '',
      contactKeywords: filterParams.contactKeywords || '',
      houseOwnerKeywords: filterParams.houseOwnerKeywords || '',
      useAreaAmountBottomLimit: filterParams.useAreaAmountBottomLimit || undefined,
      useAreaAmountUpperLimit: filterParams.useAreaAmountUpperLimit || undefined,
      businessManId: filterParams.businessManId || undefined,
      objectEntrustType: filterParams.objectEntrustType || undefined,
      objectEntrustDateRange:
        filterParams.objectEntrustStartDate && filterParams.objectEntrustEndDate
          ? [moment(filterParams.objectEntrustStartDate), moment(filterParams.objectEntrustEndDate)]
          : [],
      createDateRange:
        filterParams.createDateStart && filterParams.createDateEnd
          ? [moment(filterParams.createDateStart), moment(filterParams.createDateEnd)]
          : [],
      ad: filterParams.ad || false,
      objectStatus: filterParams.objectStatus || undefined,
      isMain: filterParams.isMain || false,
      productType: filterParams.productType || undefined,
    });
    setIsFilterVisible(true);
  };

  // 關閉篩選Drawer
  const closeFilter = () => {
    filterForm.resetFields();
    setFilterParams({});
    setIsFilterVisible(false);
  };

  /**
   * 提交篩選表單
   */
  const handleFilterSubmit = async () => {
    try {
      const values = await filterForm.validateFields();
      const newParams = {};

      // -- 1. 物件編號
      if (values.objectNo) newParams.objectNo = values.objectNo;

      // -- 2. 物件類型
      if (values.objectTypeId !== undefined) {
        newParams.objectTypeId = values.objectTypeId;
      }

      // -- 3. 區域
      if (values.areaId !== undefined) {
        newParams.areaId = values.areaId;
      }

      // -- 4. 分隔類型
      if (values.partitionTypeId !== undefined) {
        newParams.partitionTypeId = values.partitionTypeId;
      }

      // -- 5. 展示日期 (showStartDate, showEndDate)
      if (values.showDateRange && values.showDateRange.length === 2) {
        newParams.showStartDate = values.showDateRange[0].format('YYYY-MM-DD');
        newParams.showEndDate = values.showDateRange[1].format('YYYY-MM-DD');
      }

      // -- 6. 電梯類型
      if (values.elevatorType !== undefined) {
        newParams.elevatorType = values.elevatorType;
      }

      // -- 7. 大樓朝向
      if (values.buildingFace) {
        newParams.buildingFace = values.buildingFace;
      }

      // -- 8. 樓上朝向
      if (values.upstairsFace) {
        newParams.upstairsFace = values.upstairsFace;
      }

      // -- 9. 聯繫關鍵字
      if (values.contactKeywords) {
        newParams.contactKeywords = values.contactKeywords;
      }

      // -- 10. 屋主關鍵字
      if (values.houseOwnerKeywords) {
        newParams.houseOwnerKeywords = values.houseOwnerKeywords;
      }

      // -- 11. 使用坪數下限
      if (values.useAreaAmountBottomLimit !== undefined) {
        newParams.useAreaAmountBottomLimit = values.useAreaAmountBottomLimit;
      }

      // -- 12. 使用坪數上限
      if (values.useAreaAmountUpperLimit !== undefined) {
        newParams.useAreaAmountUpperLimit = values.useAreaAmountUpperLimit;
      }

      // -- 13. 業務
      if (values.businessManId !== undefined) {
        newParams.businessManId = values.businessManId;
      }

      // -- 14. 委託類型
      if (values.objectEntrustType !== undefined) {
        newParams.objectEntrustType = values.objectEntrustType;
      }

      // -- 15. 委託日期區間 (objectEntrustStartDate, objectEntrustEndDate)
      if (values.objectEntrustDateRange && values.objectEntrustDateRange.length === 2) {
        newParams.objectEntrustStartDate = values.objectEntrustDateRange[0].format('YYYY-MM-DD');
        newParams.objectEntrustEndDate = values.objectEntrustDateRange[1].format('YYYY-MM-DD');
      }

      // -- 16. 建立日期區間 (createDateStart, createDateEnd)
      if (values.createDateRange && values.createDateRange.length === 2) {
        newParams.createDateStart = values.createDateRange[0].format('YYYY-MM-DD');
        newParams.createDateEnd = values.createDateRange[1].format('YYYY-MM-DD');
      }

      // -- 17. 是否廣告
      newParams.ad = !!values.ad; // checkbox -> boolean

      // -- 18. 物件狀態
      if (values.objectStatus !== undefined) {
        newParams.objectStatus = values.objectStatus;
      }

      // -- 19. 是否主推
      newParams.isMain = !!values.isMain; // checkbox -> boolean

      // -- 20. 產品類型
      if (values.productType !== undefined) {
        newParams.productType = values.productType;
      }

      // 放入 state，並重置分頁
      setFilterParams(newParams);
      setPageNumber(1);
      setPageSize(20); // 若您想同時重置 pageSize

      // 關閉 Drawer
      setIsFilterVisible(false);
    } catch (error) {
      // 驗證失敗時，不做任何處理
    }
  };

  // **重點**：新增物件法律資訊按鈕事件
  const handleManageLawInfo = (record) => {
    setCurrentRecord(record);
    setLawInfoModalVisible(true);
  };

  return (
    <PageContainer>
      <HeaderRow justify="space-between" align="middle">
        <Col>
          <Title>物件列表</Title>
        </Col>
        <Col>
          <ActionButtonsContainer>
            <Button type="primary" onClick={handleOpenModal}>
              新增物件
            </Button>
            <Button onClick={openFilter}>篩選</Button>
          </ActionButtonsContainer>
        </Col>
      </HeaderRow>

      <StyledTable
        columns={basicColumns}
        dataSource={data}
        rowKey="id"
        loading={loading}
        pagination={{
          current: pageNumber,
          pageSize: pageSize,
          total: totalRecords,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '50'],
        }}
        onChange={handleTableChange}
        scroll={{ x: '100%' }}
        expandable={{
          expandedRowRender,
          // 如要條件判斷可加 rowExpandable: (record) => !!record, 
        }}
      />

      {/* 新增/編輯 Modal */}
      <Modal
        title={isEditMode ? '編輯物件' : '新增物件'}
        open={isModalVisible}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        okText="確定"
        cancelText="取消"
        width={800} // 根據需要調整寬度
        style={{ overflow: 'visible' }}
        bodyStyle={{ overflow: 'visible' }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            detailedInformation: '',
            memo: '',
            // 添加其他欄位的初始值，如果有的話
          }}
          onFinish={handleOkModal}
        >
          {/* 基本資料 */}
          <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="objectName"
                  label="案件名稱"
                  rules={[{ required: true, message: '輸入案件名稱' }]}
                >
                  <Input placeholder="例如:富邦人壽精選" />
                </Form.Item>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  name="productType"
                  label="產品類型"
                  rules={[{ required: true, message: '請選擇產品類型' }]}
                >
                  <Select placeholder="選擇產品類型">
                    {ProductType_OPTIONS?.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="objectTypeId"
                  label="物件類型"
                  rules={[{ required: true, message: '請選擇物件類型' }]}
                >
                  <Select placeholder="選擇物件類型">
                    {objectTypes?.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.objectTypeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="areaId"
                  label="地區"
                  rules={[{ required: true, message: '請選擇地區/大樓類型' }]}
                >
                  <Select placeholder="選擇地區/大樓類型" onChange={handleAreaChange}>
                    {areas?.map((area) => (
                      <Option key={area.id} value={area.id}>
                        {area.areaName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="areaDetailId"
                  label="地區詳細資訊"
                  rules={[{ required: true, message: '請確保該地區有詳細資訊可選' }]}
                >
                  <Select
                    placeholder="請確保該地區有詳細資訊可選"
                    disabled={!form.getFieldValue('areaId') || areaDetails.length === 0}
                    allowClear
                  >
                    {areaDetails?.map((detail) => (
                      <Option key={detail.id} value={detail.id}>
                        {detail.areaDetailDescription}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={5}>
                <Form.Item
                  name="showStartDate"
                  label="展示起始日期"
                  rules={[{ required: true, message: '請選擇展示起始日期' }]}
                >
                  <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="showEndDate"
                  label="展示結束日期"
                  rules={[{ required: true, message: '請選擇展示結束日期' }]}
                >
                  <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="objectNo"
                  label="物件編號"
                  rules={[{ required: true, message: '請輸入物件編號' }]}
                >
                  <Input placeholder="至少三位數" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="obfuscatedAddress"
                  label="模糊地址"
                  rules={[{ required: true, message: '請輸入模糊地址' }]}
                >
                  <Input placeholder="網站上顯示使用" />
                </Form.Item>
              </Col>
            </Row>

            {/* 其他欄位 */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="detailedAddress"
                  label="詳細地址"
                  rules={[{ required: true, message: '請輸入詳細地址' }]}
                >
                  <Input placeholder="後台上顯示使用" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="gooleMapAddress"
                  label="GOOGLE地址"
                  rules={[{ required: true, message: '請輸入GOOGLE地址' }]}
                >
                  <Input placeholder="地圖上顯示使用" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  name="partitionTypeId"
                  label="使用分區"
                  rules={[{ required: true, message: '選擇分區' }]}
                >
                  <Select placeholder="選擇分區">
                    {partitionTypes?.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.partitionTypeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="floorPlan"
                  label="樓層規劃"
                  rules={[{ required: true, message: '格式 => ?F/?B' }]}
                >
                  <Input placeholder="3B/5F" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="distance"
                  label="相鄰幾米"
                  rules={[{ required: true, message: '請輸入數字' }]}
                >
                  <InputNumber
                    placeholder="100"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="buildingStructure"
                  label="建築結構"
                  rules={[{ required: true, message: '請選擇建築結構' }]}
                >
                  <Select placeholder="建築結構">
                    {BuildingStructure_OPTIONS?.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="completionYear"
                  label="完工年份"
                  rules={[{ required: true, message: '請輸入西元' }]}
                >
                  <InputNumber
                    placeholder="YYYY"
                    style={{ width: '100%' }}
                    min={1900}
                    max={2100}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  name="passengerElevator"
                  label="幾部客梯"
                  rules={[{ required: true, message: '客梯數' }]}
                >
                  <InputNumber
                    placeholder="沒有則輸0"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="passengerAmount"
                  label="載客數"
                  rules={[{ required: true, message: '載客數' }]}
                >
                  <InputNumber
                    placeholder="沒有則輸0"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="passengerElevatorMemo"
                  label="客梯備註"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="freightElevator"
                  label="幾部貨梯"
                  rules={[{ required: true, message: '貨梯數' }]}
                >
                  <InputNumber
                    placeholder="沒有則輸0"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="freightAmount"
                  label="載貨頓數"
                  rules={[{ required: true, message: '載貨頓數' }]}
                >
                  <InputNumber
                    placeholder="沒有則輸0"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="freightElevatorMemo"
                  label="貨梯備註"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  name="buildingFace"
                  label="大樓座向"
                  rules={[{ required: true, message: '大樓座向' }]}
                >
                  <Select placeholder="大樓座向">
                    {Face_OPTIONS?.map((type) => (
                      <Option key={type.name} value={type.name}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="upstairsFace"
                  label="樓上座向"
                  rules={[{ required: true, message: '樓上座向' }]}
                >
                  <Select placeholder="樓上座向">
                    {Face_OPTIONS?.map((type) => (
                      <Option key={type.name} value={type.name}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="managementFee"
                  label="管理費"
                  rules={[{ required: true, message: '管理費' }]}
                >
                  <InputNumber
                    placeholder="沒有則輸0"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="managementFeeUnitType"
                  label="管理費單位"
                  rules={[{ required: true, message: '管理費單位' }]}
                >
                  <Select placeholder="管理費單位">
                    {ManagementFeeUnitType_OPTIONS?.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="managementFeeIsContainPublicWateAndElectricity"
                  label="含公共用電"
                  valuePropName="checked"
                  rules={[{ required: false, message: '是否含公共用電' }]}
                >
                  <Checkbox>是</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  name="currentSituation"
                  label="現況"
                  rules={[{ required: true, message: '現況' }]}
                >
                  <Select placeholder="現況">
                    {CurrentSituation_OPTIONS?.map((type) => (
                      <Option key={type.name} value={type.name}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="bulidingSituation"
                  label="屋況"
                  rules={[{ required: true, message: '屋況' }]}
                >
                  <Select placeholder="屋況">
                    {BulidingSituation_OPTIONS?.map((type) => (
                      <Option key={type.name} value={type.name}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="mechanicalParkingAmount"
                  label="機械車位數"
                  rules={[{ required: true, message: '機械車位數' }]}
                >
                  <InputNumber
                    placeholder="沒有則輸0"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="flatParkingAmount"
                  label="平面車位數"
                  rules={[{ required: true, message: '平面車位數' }]}
                >
                  <InputNumber
                    placeholder="沒有則輸0"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="parkingRent"
                  label="車位租金"
                  rules={[{ required: true, message: '車位租金' }]}
                >
                  <InputNumber
                    placeholder="沒有則輸0"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="parkingPrice"
                  label="車位售價"
                  rules={[{ required: true, message: '車位售價' }]}
                >
                  <InputNumber
                    placeholder="沒有則輸0"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="rent"
                  label="租金"
                  rules={[{ required: true, message: '請輸入租金' }]}
                >
                  <InputNumber
                    placeholder="輸入租金"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="rentUnit"
                  label="租金單位"
                  rules={[{ required: true, message: '租金單位' }]}
                >
                  <Select placeholder="租金單位">
                    {ManagementFeeUnitType_OPTIONS?.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="hasRentTax"
                  label="含稅"
                  rules={[{ required: true, message: '請選擇是否含稅' }]}
                >
                  <Select placeholder="選擇是否含稅">
                    <Option value={true}>是</Option>
                    <Option value={false}>否</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="rentTaxRate"
                  label="稅率"
                  rules={[{ required: true, message: '請輸入稅率' }]}
                >
                  <InputNumber
                    placeholder="輸入稅率"
                    style={{ width: '100%' }}
                    min={0}
                    max={100}
                    addonAfter="%"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="sellPrice"
                  label="售價"
                  rules={[{ required: true, message: '請輸入售價' }]}
                >
                  <InputNumber
                    placeholder="輸入售價"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="sellPriceUnit"
                  label="售價單位"
                  rules={[{ required: true, message: '售價單位' }]}
                >
                  <Select placeholder="售價單位">
                    {SellPriceUnitType_OPTIONS?.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="hasSellPriceTax"
                  label="含稅"
                  rules={[{ required: true, message: '請選擇是否含稅' }]}
                >
                  <Select placeholder="選擇是否含稅">
                    <Option value={true}>是</Option>
                    <Option value={false}>否</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="sellPriceTaxRate"
                  label="稅率"
                  rules={[{ required: true, message: '請輸入稅率' }]}
                >
                  <InputNumber
                    placeholder="輸入稅率"
                    style={{ width: '100%' }}
                    min={0}
                    max={100}
                    addonAfter="%"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="depositMonths"
                  label="押金幾個月"
                  rules={[{ required: true, message: '押金幾個月' }]}
                >
                  <InputNumber
                    placeholder="押金幾個月"
                    style={{ width: '100%' }}
                    min={0}
                    addonAfter="個月"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="depositMonthsMemo"
                  label="押金備註"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="freeDaysForDecoration"
                  label="免租裝潢期天數"
                  rules={[{ required: true, message: '天數' }]}
                >
                  <InputNumber
                    placeholder="免租裝潢期天數"
                    style={{ width: '100%' }}
                    min={0}
                    addonAfter="天"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="freeDaysForDecorationMemo"
                  label="免租裝潢期備註"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="rentCommission"
                  label="出租付佣條件"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="sellCommission"
                  label="出售付佣條件"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="homeowner"
                  label="屋主"
                  rules={[{ required: true, message: '請輸入屋主' }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="homeownerPhone"
                  label="屋主手機"
                  rules={[{ required: true, message: '請輸入屋主手機' }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="homeownerNumber"
                  label="屋主電話"
                  rules={[{ required: true, message: '請輸入屋主電話' }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
            </Row>
            {/* 聯絡人1 */}
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="contactPersonName1"
                  label="聯絡人1姓名"
                  rules={[{ required: false, message: '請輸入聯絡人1姓名' }]}
                >
                  <Input placeholder="聯絡人1姓名" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="contactPersonPosition1"
                  label="聯絡人1職位"
                  rules={[{ required: false, message: '請輸入聯絡人1職位' }]}
                >
                  <Input placeholder="聯絡人1職位" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="contactPersonPhone1"
                  label="聯絡人1手機"
                  rules={[{ required: false, message: '請輸入聯絡人1手機' }]}
                >
                  <Input placeholder="聯絡人1手機" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="contactPersonNumber1"
                  label="聯絡人1電話"
                  rules={[{ required: false, message: '請輸入聯絡人1電話' }]}
                >
                  <Input placeholder="聯絡人1電話" />
                </Form.Item>
              </Col>
            </Row>

            {/* 聯絡人2 */}
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="contactPersonName2"
                  label="聯絡人2姓名"
                  rules={[{ required: false, message: '請輸入聯絡人2姓名' }]}
                >
                  <Input placeholder="聯絡人2姓名" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="contactPersonPosition2"
                  label="聯絡人2職位"
                  rules={[{ required: false, message: '請輸入聯絡人2職位' }]}
                >
                  <Input placeholder="聯絡人2職位" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="contactPersonPhone2"
                  label="聯絡人2手機"
                  rules={[{ required: false, message: '請輸入聯絡人2手機' }]}
                >
                  <Input placeholder="聯絡人2手機" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="contactPersonNumber2"
                  label="聯絡人2電話"
                  rules={[{ required: false, message: '請輸入聯絡人2電話' }]}
                >
                  <Input placeholder="聯絡人2電話" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  name="showWay"
                  label="代看方式"
                  rules={[{ required: true, message: '代看方式必須選擇' }]}
                >
                  <Select placeholder="代看方式">
                    {ShowWay_OPTIONS?.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="objectEntrustType"
                  label="委託類型"
                  rules={[{ required: true, message: '委託類型必須選擇' }]}
                >
                  <Select placeholder="委託類型">
                    {ObjectEntrustType_OPTIONS?.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="objectEntrustStartDate"
                  label="委託起始日期"
                  rules={[{ required: true, message: '請選擇委託起始日期' }]}
                >
                  <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="objectEntrustEndDate"
                  label="委託結束日期"
                  rules={[{ required: true, message: '請選擇委託結束日期' }]}
                >
                  <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="ad"
                  label="廣告"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  name="keyWords"
                  label="關鍵字"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="backstageMemo"
                  label="後台備註說明"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="objectStatus"
                  label="結案狀態"
                  rules={[{ required: true, message: '結案狀態' }]}
                >
                  <Select placeholder="結案狀態">
                    {ObjectStatus_OPTIONS?.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="isMain"
                  label="是否精選"
                  valuePropName="checked"
                >
                  <Checkbox>是</Checkbox>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="isHot"
                  label="是否熱搜"
                  valuePropName="checked"
                >
                  <Checkbox>是</Checkbox>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="mustSee"
                  label="是否必看"
                  valuePropName="checked"
                >
                  <Checkbox>是</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

      {/* 篩選 Drawer */}
      <FilterDrawer
        title="篩選條件"
        placement="right"
        closable={false}
        onClose={closeFilter}
        open={isFilterVisible}
      >
      <Form form={filterForm} layout="vertical">
          {/* 1. 物件編號 */}
          <Form.Item label="物件編號" name="objectNo">
            <Input placeholder="輸入物件編號" />
          </Form.Item>

          {/* 2. 物件類型 */}
          <Form.Item label="物件類型" name="objectTypeId">
            <Select placeholder="選擇物件類型" allowClear>
              {objectTypes.map((ot) => (
                <Option key={ot.id} value={ot.id}>
                  {ot.objectTypeName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* 3. 區域 */}
          <Form.Item label="區域" name="areaId">
            <Select placeholder="選擇區域" allowClear>
              {areas?.map((ar) => (
                <Option key={ar.id} value={ar.id}>
                  {ar.areaName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* 4. 分隔類型 */}
          <Form.Item label="分隔類型" name="partitionTypeId">
            <Select placeholder="選擇分隔類型" allowClear>
              {partitionTypes.map((pt) => (
                <Option key={pt.id} value={pt.id}>
                  {pt.partitionTypeName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* 5. 展示日期區間 (showStartDate, showEndDate) */}
          <Form.Item label="展示日期" name="showDateRange">
            <RangePicker format="YYYY-MM-DD" />
          </Form.Item>

          {/* 6. 電梯類型 */}
          <Form.Item label="電梯類型" name="elevatorType">
            <Select placeholder="選擇電梯類型" allowClear>
              <Option value={0}>有貨梯</Option>
              <Option value={1}>有客梯</Option>
              <Option value={2}>不限制</Option>
            </Select>
          </Form.Item>

          {/* 7. 大樓朝向 */}
          <Form.Item label="大樓朝向" name="buildingFace">
            <Select placeholder="大樓座向">
              {Face_OPTIONS?.map((type) => (
                <Option key={type.name} value={type.name}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* 8. 樓上朝向 */}
          <Form.Item label="樓上朝向" name="upstairsFace">
            <Select placeholder="大樓座向">
              {Face_OPTIONS?.map((type) => (
                <Option key={type.name} value={type.name}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* 9. 聯繫關鍵字 */}
          <Form.Item label="聯繫關鍵字" name="contactKeywords">
            <Input placeholder="電話、Line、Email..." />
          </Form.Item>

          {/* 10. 屋主關鍵字 */}
          <Form.Item label="屋主關鍵字" name="houseOwnerKeywords">
            <Input placeholder="屋主姓名或其他資訊" />
          </Form.Item>

          {/* 12. 使用坪數上限 */}
          <Form.Item label="使用坪數上限" name="useAreaAmountUpperLimit">
            <InputNumber placeholder="最大坪數" style={{ width: '100%' }} min={0} />
          </Form.Item>
          {/* 11. 使用坪數下限 */}
          <Form.Item label="使用坪數下限" name="useAreaAmountBottomLimit">
            <InputNumber placeholder="最小坪數" style={{ width: '100%' }} min={0} />
          </Form.Item>

          {/* 13. 業務 */}
          <Form.Item label="業務" name="businessManId">
            <Select placeholder="選擇業務員" allowClear>
              {businessMans.map((bm) => (
                <Option key={bm.id} value={bm.id}>
                  {bm.name} - {bm.businessNo}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* 14. 委託類型 */}
          <Form.Item label="委託類型" name="objectEntrustType">
            <Select placeholder="選擇委託類型" allowClear>
              {ObjectEntrustType_OPTIONS?.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* 15. 委託日期區間 */}
          <Form.Item label="委託日期區間" name="objectEntrustDateRange">
            <RangePicker format="YYYY-MM-DD" />
          </Form.Item>

          {/* 16. 建立日期區間 */}
          <Form.Item label="建立日期區間" name="createDateRange">
            <RangePicker format="YYYY-MM-DD" />
          </Form.Item>

          {/* 17. 是否廣告 */}
          <Form.Item label="是否廣告" name="ad" valuePropName="checked">
            <Checkbox>需要廣告</Checkbox>
          </Form.Item>

          {/* 18. 物件狀態 */}
          <Form.Item label="物件狀態" name="objectStatus">
            <Select placeholder="選擇物件狀態" allowClear>
              {ObjectStatus_OPTIONS?.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* 19. 是否主推 */}
          <Form.Item label="是否主推" name="isMain" valuePropName="checked">
            <Checkbox>主推物件</Checkbox>
          </Form.Item>

          {/* 20. 產品類型 */}
          <Form.Item label="產品類型" name="productType">
            <Select placeholder="選擇產品類型" allowClear>
              {ProductType_OPTIONS?.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>

        <div style={{ textAlign: 'right', marginTop: '16px' }}>
          <Button style={{ marginRight: 8 }} onClick={closeFilter}>
            取消
          </Button>
          <Button type="primary" onClick={handleFilterSubmit}>
            篩選
          </Button>
        </div>
      </FilterDrawer>

      {currentRecord && (
        <BindBusinessModal
          options = {businessMans}
          businessMans={currentRecord.businessManBindObjectInformation}
          visible={businessModalVisible}
          onClose={() => setBusinessModalVisible(false)}
          objectInformationId={currentRecord.id}
          refreshBindings={() => {
             fetchData();
          }}
        />
      )}

      {currentRecord && (
        <ManageImagesModal
          visible={imageModalVisible}
          onClose={() => setImageModalVisible(false)}
          objectInformationId={currentRecord.id}
          refreshImages={() => {
            // 根據需要重新加載圖片
          }}
        />
      )}

      {/* 物件法律資訊管理視窗 */}
      {currentRecord && (
        <ManageLawInfoModal
          visible={lawInfoModalVisible}
          onClose={() => setLawInfoModalVisible(false)}
          objectInformationId={currentRecord.id} 
        />
      )}
    </PageContainer>
  );
};

export default ObjectInformationSubPage;
