// src/components/Sidebar.jsx

import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

// 從 API 撈取選單
import { getAuth } from '../api/totalApi';

// 引入所有可能用到的 Icon
import {
  FolderOpenOutlined,    // 預設/找不到時用
  TeamOutlined,          // /businessMan、父層：人員管理
  UserOutlined,          // /admin
  SettingOutlined,       // /super、父層：系統設定
  InfoCircleOutlined,    // /about、父層：基本設定
  PictureOutlined,       // /carousel
  ContactsOutlined,      // /customer、父層：顧客管理
  SolutionOutlined,      // /demand
  DatabaseOutlined,      // /objectInformation、父層：物件管理
  AppstoreOutlined,      // /objectType
  EnvironmentOutlined,   // /area
  FormOutlined,          // /form
  ReadOutlined,          // /page
} from '@ant-design/icons';

// Sidebar Wrapper 樣式
const SidebarWrapper = styled.div`
  width: 15%;
  background: #f9f9f9;
  height: calc(100vh - 60px); /* 扣除 TopBar 的高度 */
  padding: 10px;
  box-sizing: border-box;
  border-right: 1px solid #ddd;
  overflow: auto;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: row;
    overflow-x: auto;
    border-right: none;
  }
`;

/**
 * 父層 Icon 對照表：依據 pageName 決定圖示
 * 若後端回傳的 pageName 與對照表吻合，即使用指定的 Icon
 */
const parentIconMap = {
  '人員管理': <TeamOutlined />,
  '系統設定': <SettingOutlined />,
  '顧客管理': <ContactsOutlined />,
  '基本設定': <InfoCircleOutlined />,
  '物件管理': <DatabaseOutlined />,
};

/**
 * 小工具：傳入父層名稱，若有對應就回傳對應 Icon，否則使用 FolderOpenOutlined
 */
function getParentIconByName(name) {
  return parentIconMap[name] || <FolderOpenOutlined />;
}

/**
 * 子層 Icon 對照表：依據 subPageUrl 決定圖示
 */
const childIconMap = {
  '/businessMan': <TeamOutlined />,
  '/admin': <UserOutlined />,
  '/super': <SettingOutlined />,
  '/about': <InfoCircleOutlined />,
  '/carousel': <PictureOutlined />,
  '/customer': <ContactsOutlined />,
  '/demand': <SolutionOutlined />,
  '/objectInformation': <DatabaseOutlined />,
  '/objectType': <AppstoreOutlined />,
  '/area': <EnvironmentOutlined />,
  '/form': <FormOutlined />,
  '/page': <ReadOutlined />,
};

/**
 * 小工具：傳入子層的路由（subPageUrl），回傳對應 Icon，若無則預設 FolderOpenOutlined
 */
function getChildIconByRoute(routeUrl) {
  return childIconMap[routeUrl] || <FolderOpenOutlined />;
}

export function Sidebar() {
  const { user } = useContext(AuthContext);
  const [menuData, setMenuData] = useState([]);

  // 無條件撈取後端資料（user 改變時撈取）
  useEffect(() => {
    if (user) {
      fetchMenuData();
    }
  }, [user]);

  const fetchMenuData = async () => {
    try {
      // 假設後端 API 根據 user.role 回傳選單資料
      const result = await getAuth(user.role);
      setMenuData(result.data || []);
    } catch (error) {
      console.error('fetchMenuData error:', error);
    }
  };

  // 若未登入，不渲染側邊欄
  if (!user) {
    return null;
  }

  /**
   * 將後端回傳的 menuData 組裝成 antd Menu 所需的 items
   * 結構參考：
   *  pageItem: { id, pageName, subPage: [{ id, subPageName, subPageUrl, ... }] }
   */
  const menuItems = menuData.map((pageItem) => ({
    key: `page-${pageItem.id}`,
    // 父層 icon 根據 pageName 對應 (若未匹配到則預設 FolderOpenOutlined)
    icon: getParentIconByName(pageItem.pageName),
    label: pageItem.pageName || `頁面 ${pageItem.id}`,
    children: pageItem.subPage?.map((sub) => ({
      key: `subPage-${sub.id}`,
      // 子層 icon 根據 subPageUrl 對應
      icon: getChildIconByRoute(sub.subPageUrl),
      label: <Link to={sub.subPageUrl}>{sub.subPageName}</Link>,
    })),
  }));

  return (
    <SidebarWrapper>
      <Menu
        mode="inline"
        items={menuItems}
        style={{
          height: '100%',
          border: 'none',
          background: 'transparent',
        }}
      />
    </SidebarWrapper>
  );
}
