// src/pages/HomePage.jsx
import React, { useContext } from 'react';
import styled from 'styled-components';
import { TopBar } from '../components/TopBar';
import { Sidebar } from '../components/Sidebar';
import { AuthContext } from '../context/AuthContext';
import { Typography, Card, Avatar } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const PageWrapper = styled.div`
  display:flex;
  flex-direction:column;
  height:100vh;
`;

const MainLayout = styled.div`
  display:flex;
  flex:1;
  overflow:hidden;
`;

const MainContent = styled.div`
  width:85%;
  padding:10px;
  overflow:auto;
  background:#f0f2f5;

  @media(max-width:768px) {
    width:100%;
    padding:10px;
  }
`;

const InfoCard = styled(Card)`
  max-width:600px;
  background:#fff;
  border-radius:8px;
  box-shadow:0 2px 8px rgba(0,0,0,0.1);
  margin-bottom:20px;

  .ant-typography {
    margin:0;
  }
`;

export default function HomePage() {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const isHomeRoute = location.pathname === '/';

  return (
    <PageWrapper>
      <TopBar />
      <MainLayout>
        <Sidebar />
        <MainContent>
          {isHomeRoute && (
            <InfoCard>
              <div style={{display:'flex', alignItems:'center', gap:'20px', marginBottom:'20px'}}>
                <Avatar 
                  src={user.avatar || 'https://via.placeholder.com/80'} 
                  size={80} 
                  alt="avatar" 
                />
                <div>
                  <Title level={1} style={{marginBottom:'10px'}}>儀表板</Title>
                  <Paragraph>歡迎, <strong>{user.name}</strong>！</Paragraph>
                  <Paragraph>職位: {user.position}</Paragraph>
                  <Paragraph>角色: {user.role}</Paragraph>
                </div>
              </div>
            </InfoCard>
          )}
          {/* 使用Outlet切換子頁面內容 */}
          <Outlet />
        </MainContent>
      </MainLayout>
    </PageWrapper>
  );
}
