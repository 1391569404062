// src/App.jsx
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { MessageProvider } from './context/MessageContext';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import {GlobalStyle} from './css/GlobalStyles';
// 業務子頁面
import BusinessManCustomerManagementSubPage from './pages/subpage/totalSubpages/CustomerManagementSubPage';
import BusinessDemandManagementSubPage from './pages/subpage/totalSubpages/DemandManagementSubPage';

// Admin 子頁面
import AdminObjectInformationSubPage from './pages/subpage/totalSubpages/ObjectInformationSubPage';
import AdminObjectTypesSubPage from './pages/subpage/totalSubpages/ObjectTypesSubPage';
import AdminAreaManagementSubPage from './pages/subpage/totalSubpages/AreaManagementSubPage';
import AdminInquiryFormsSubPage from './pages/subpage/totalSubpages/InquiryFormsSubPage';

// Super 子頁面
import SuperBusinessManManagementPage from './pages/subpage/totalSubpages/BusinessManManagementSubPage';
import SuperAdminManagementSubPage from './pages/subpage/totalSubpages/AdminManagementSubPage';
import SuperManagementSubPage from './pages/subpage/totalSubpages/SuperManagementSubPage';
import SuperAboutImageManagementSubPage from './pages/subpage/totalSubpages/AboutImageManagementSubPage';
import SuperTeammateManagementSubPage from './pages/subpage/totalSubpages/TeammateManagementSubPage';
import SuperIndexCarouselManagementSubPage from './pages/subpage/totalSubpages/IndexCarouselManagementSubPage';
import PageControllSubPage from './pages/subpage/totalSubpages/PageControllSubPage';
import PartitionManagementSubPage from './pages/subpage/totalSubpages/PartitionManagementSubPage';

// 僅判斷「是否登入」的 ProtectedRoute
import { ProtectedRoute } from './components/ProtectedRoute';


export default function App() {
  return (
    <>
      <GlobalStyle />     
      <MessageProvider>
        <AuthProvider>
          <GlobalStyle />
          <BrowserRouter>      
            <Routes>
              {/* 未登入可進入的路由 */}
              <Route path="/login" element={<LoginPage />} />

              {/* 所有需登入才能進入的路由通通放這裡，不再前端判斷角色 */}
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<HomePage />}>
                  {/* 業務(BusinessMan)區域 */}
                  <Route path="customer" element={<BusinessManCustomerManagementSubPage />} />
                  <Route path="demand" element={<BusinessDemandManagementSubPage />} />

                  {/* Admin 區域 */}
                  <Route path="objectInformation" element={<AdminObjectInformationSubPage />} />
                  <Route path="objectType" element={<AdminObjectTypesSubPage />} />
                  <Route path="area" element={<AdminAreaManagementSubPage />} />
                  <Route path="form" element={<AdminInquiryFormsSubPage />} />
                  <Route path="partition" element={<PartitionManagementSubPage />} />

                  {/* Super 區域 */}
                  <Route path="businessMan" element={<SuperBusinessManManagementPage />} />
                  <Route path="admin" element={<SuperAdminManagementSubPage />} />
                  <Route path="super" element={<SuperManagementSubPage />} />
                  <Route path="about" element={<SuperAboutImageManagementSubPage />} />
                  <Route path="teammate" element={<SuperTeammateManagementSubPage />} />
                  <Route path="carousel" element={<SuperIndexCarouselManagementSubPage />} />
                  <Route path="page" element={<PageControllSubPage />} />
                </Route>
              </Route>

              {/* 404 Not Found */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </MessageProvider>
    </>
  );
}
