// src/pages/AdminManagementSubPage.jsx

import React, { useEffect, useState, useContext } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Upload,
  message,
  Row,
  Col,
  Space,
  Avatar,
  Divider,
  Switch,
  Select
} from 'antd';
import {
  UploadOutlined,
  PlusOutlined,
  EditOutlined,
  UserOutlined
} from '@ant-design/icons';
import { AuthContext } from '../../../context/AuthContext';

// 假設您有以下 API 方法
import {
  getAdmins,
  createAdmin,
  updateAdmin,
  uploadAdminImg,
  getAllOptions,
  resetPassword  // 新增重設密碼的API
} from '../../../api/totalApi';

const { Option } = Select;

const AdminManagementSubPage = () => {
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);        // 管理員列表
  const [authGroups, setAuthGroups] = useState([]);// 群組下拉選單
  const [searchName, setSearchName] = useState('');// 搜尋用
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRecord, setEditRecord] = useState(null);

  // 重設密碼相關
  const [resetModalVisible, setResetModalVisible] = useState(false);
  const [resetForm] = Form.useForm();
  const [selectedUserId, setSelectedUserId] = useState(0);

  const { user } = useContext(AuthContext);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchAdmins();         // 取得管理員列表
    fetchDropdownOptions(); // 取得群組下拉選單
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 取得管理員列表
  const fetchAdmins = async (name = '') => {
    setLoading(true);
    try {
      const result = await getAdmins(user.role, name);
      setAdmins(result.data || []);
    } catch (error) {
      console.error(error);
      message.error(error.response?.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  // 取得群組下拉選單
  const fetchDropdownOptions = async () => {
    try {
      const allOptions = await getAllOptions();
      setAuthGroups(allOptions.authGroups || []);
    } catch (error) {
      console.error(error);
      message.error(error.response?.data || '操作失敗');
    }
  };

  // 搜尋
  const handleSearch = () => {
    fetchAdmins(searchName);
  };

  // 開啟「新增管理員」對話框
  const openCreateModal = () => {
    setIsEditMode(false);
    setEditRecord(null);
    form.resetFields();

    form.setFieldsValue({
      id: 0,
      account: '',
      password: '',
      authType: 2, // 管理者
      name: '',
      position: '',
      status: false,
      authGroupId: 1
    });

    setIsModalVisible(true);
  };

  // 開啟「編輯管理員」對話框
  const openEditModal = (record) => {
    setIsEditMode(true);
    setEditRecord(record);

    const formValues = {
      id: record.id,
      name: record.name || '',
      position: record.position || '',
      status: record.status || false,
      authType: 2,
      account: '',
      password: '',
      authGroupId: record.authGroupId || 1
    };
    form.setFieldsValue(formValues);
    setIsModalVisible(true);
  };

  // Modal「確定」按鈕
  const handleOkModal = async () => {
    try {
      const values = await form.validateFields();
      if (isEditMode && editRecord) {
        // 編輯模式 -> updateAdmin
        const adminRequest = {
          id: values.id,
          name: values.name,
          position: values.position,
          status: values.status,
          authGroupId: values.authGroupId
        };
        await updateAdmin(user.role, adminRequest);
        message.success('修改管理員成功');
      } else {
        // 新增模式 -> createAdmin
        const userRequest = {
          id: 0,
          account: values.account,
          password: values.password,
          authType: 2, // 管理者
          adminRequest: {
            id: 0,
            name: values.name,
            position: values.position,
            status: values.status,
            authGroupId: values.authGroupId
          }
        };
        await createAdmin(user.role, userRequest);
        message.success('新增管理員成功');
      }
      setIsModalVisible(false);
      fetchAdmins(searchName);
    } catch (error) {
      console.error(error);
      message.error(error.response?.data || '操作失敗');
    }
  };

  // 上傳大頭貼
  const handleUploadImg = async (record, file) => {
    try {
      await uploadAdminImg(user.role, file, record.id);
      message.success(`上傳大頭貼成功: ${record.name}`);
      fetchAdmins(searchName);
    } catch (error) {
      console.error(error);
      message.error(error.response?.data || '操作失敗');
    }
  };

  // ============== 重設密碼相關 ==============

  // 打開重設密碼對話框
  const openResetPwModal = (record) => {
    setSelectedUserId(record.userId); // 或者看後端如何定義, 可能 record.account, 也可能 record.userAccount
    resetForm.resetFields();
    setResetModalVisible(true);
  };

  // 提交重設密碼
  const handleResetPwOk = async () => {
    try {
      const values = await resetForm.validateFields();
      const payload = {
        userId: selectedUserId,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      };
      const res = await resetPassword(user.role, payload);
      if (res.success) {
        message.success(res.successMessage || '重設密碼成功');
        setResetModalVisible(false);
      } else {
        message.error(res.errorMessages?.join(',') || '重設密碼失敗');
      }
    } catch (error) {
      console.error(error);
      message.error(error.response?.data || '操作失敗');
    }
  };

  // Table 欄位
  const columns = [
    {
      title: '大頭貼',
      dataIndex: 'imgUrl',
      key: 'imgUrl',
      width: 100,
      fixed: 'left',
      render: (imgUrl) => (
        <Avatar
          size={48}
          src={imgUrl}
          icon={<UserOutlined />}
          style={{ backgroundColor: '#87d068' }}
        />
      )
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      width: 120
    },
    {
      title: '職位',
      dataIndex: 'position',
      key: 'position',
      width: 120
    },
    {
      title: '狀態',
      dataIndex: 'status',
      key: 'status',
      width: 80,
      render: (val) => (val ? '啟用' : '停用')
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 220,
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => openEditModal(record)}
          >
            編輯
          </Button>
          <Upload
            beforeUpload={(file) => {
              handleUploadImg(record, file);
              return false;
            }}
            showUploadList={false}
          >
            <Button type="link" icon={<UploadOutlined />}>
              頭貼
            </Button>
          </Upload>
          {/* 重設密碼 */}
          <Button
            type="link"
            onClick={() => openResetPwModal(record)}
            danger
          >
            重設密碼
          </Button>
        </Space>
      )
    }
  ];

  return (
    <div style={{ padding: 24 }}>
      {/* 搜尋欄位 */}
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col xs={24} sm={12} md={6}>
          <Input
            placeholder="輸入姓名搜尋"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            onPressEnter={handleSearch}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={handleSearch}>
            查詢
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={openCreateModal}
          >
            新增管理員
          </Button>
        </Col>
      </Row>

      {/* 資料表格 */}
      <Table
        rowKey="id"
        columns={columns}
        dataSource={admins}
        loading={loading}
      />

      {/* 新增 / 編輯 Modal */}
      <Modal
        title={isEditMode ? '編輯管理員' : '新增管理員'}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleOkModal}
        destroyOnClose
        width={600}
      >
        <Form form={form} layout="vertical" name="adminForm">
          {!isEditMode && (
            <>
              <Divider>使用者 (UserRequest)</Divider>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="帳號(Account)"
                    name="account"
                    rules={[{ required: true, message: '請輸入帳號' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="密碼(Password)"
                    name="password"
                    rules={[{ required: true, message: '請輸入密碼' }]}
                  >
                    <Input type="password" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Divider>管理員 (AdminRequest)</Divider>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="姓名(Name)"
                name="name"
                rules={[{ required: true, message: '請輸入姓名' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="職位(Position)" name="position">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="狀態(Status)"
                name="status"
                valuePropName="checked"
              >
                <Switch checkedChildren="啟用" unCheckedChildren="停用" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="群組(AuthGroupId)"
                name="authGroupId"
                rules={[{ required: true, message: '請選擇群組' }]}
              >
                <Select placeholder="選擇群組">
                  {authGroups.map((group) => (
                    <Option key={group.id} value={group.id}>
                      {group.groupName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="authType" hidden>
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* 重設密碼 Modal */}
      <Modal
        title="重設密碼"
        open={resetModalVisible}
        onCancel={() => setResetModalVisible(false)}
        onOk={handleResetPwOk}
        destroyOnClose
      >
        <Form form={resetForm} layout="vertical">
          <Form.Item
            label="舊密碼(OldPassword)"
            name="oldPassword"
            rules={[{ required: true, message: '請輸入舊密碼' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="新密碼(NewPassword)"
            name="newPassword"
            rules={[{ required: true, message: '請輸入新密碼' }]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminManagementSubPage;
