// src/pages/DemandManagementSubPage.jsx
import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  DatePicker,
  Row,
  Col,
  message,
  Descriptions,
} from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';

// 假設在 /utils/constants.js 中定義了 Demand_Type_OPTIONS
import { Demand_Type_OPTIONS } from '../../../utils/constants';

// 假設在 totalApi.js 中定義了以下函式
import {
  getAllDemands,
  createDemand,
  updateDemand,
  getAllCustomers,
  getAllOptions,
  getDemandAreas,
  createDemandArea,
  deleteDemandArea,
  getDemandObjectTypes,
  createDemandObjectType,
  deleteDemandObjectType,
  getGoodObject,
} from '../../../api/totalApi';
import { AuthContext } from '../../../context/AuthContext';

const { RangePicker } = DatePicker;
const { Option } = Select;

/* --------------------- styled-components --------------------- */
const PageContainer = styled.div`
  padding: 24px;
  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const HeaderRow = styled(Row)`
  margin-bottom: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const Title = styled.h2`
  margin: 0;
`;

/* --------------------- Modal 內表單容器 --------------------- */
const FormContainer = styled.div`
  /* 調整表單內元素寬度，避免左右滾動 */
  .ant-form-item-label > label {
    white-space: normal;
  }
`;

/* --------------------- 分區需求 Modal --------------------- */
const ManageAreaModal = ({ visible, onClose, demandLogId, areas }) => {
  const [loading, setLoading] = useState(false);
  const [demandAreas, setDemandAreas] = useState([]);
  const [form] = Form.useForm();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (visible && demandLogId) {
      fetchDemandAreasList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, demandLogId]);

  const fetchDemandAreasList = async () => {
    setLoading(true);
    try {
      const result = await getDemandAreas(user.role, demandLogId);
      if (result.success) {
        setDemandAreas(result.data || []);
      } else {
        message.error(result.errors.join(', '));
      }
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleAddArea = async () => {
    try {
      const values = await form.validateFields();
      const payload = { id: 0, areaId: values.areaId, demandLogId };
      const result = await createDemandArea(user.role, payload);
      if (result.success) {
        message.success('新增分區需求成功');
        form.resetFields();
        fetchDemandAreasList();
      } else {
        message.error(result.errors.join(', '));
      }
    } catch (error) {
            message.error(error.response.data || '操作失敗');
    }
  };

  const handleDeleteArea = async (id) => {
    try {
      const result = await deleteDemandArea(user.role, id);
      if (result.success) {
        message.success('刪除分區需求成功');
        fetchDemandAreasList();
      } else {
        message.error(result.errors.join(', '));
      }
    } catch (error) {
            message.error(error.response.data || '操作失敗');
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', width: 60 },
    {
      title: '分區 (AreaId)',
      dataIndex: 'areaId',
      render: (val) => {
        const found = areas.find((x) => x.id === val);
        return found ? found.areaName : val;
      },
    },
    {
      title: '操作',
      width: 80,
      render: (_, record) => (
        <Button type="link" danger onClick={() => handleDeleteArea(record.id)}>
          刪除
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title="分區需求管理"
      visible={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      width={600}
    >
      <Form layout="inline" form={form}>
        <Form.Item
          label="分區"
          name="areaId"
          rules={[{ required: true, message: '請選擇分區' }]}
        >
          <Select style={{ width: 200 }} placeholder="選擇分區">
            {areas.map((a) => (
              <Option key={a.id} value={a.id}>
                {a.areaName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleAddArea}>
            新增
          </Button>
        </Form.Item>
      </Form>
      <Table
        style={{ marginTop: 16 }}
        columns={columns}
        dataSource={demandAreas}
        rowKey="id"
        loading={loading}
        pagination={false}
      />
    </Modal>
  );
};

/* --------------------- 物件類型需求 Modal --------------------- */
const ManageObjectTypeModal = ({ visible, onClose, demandLogId, objectTypes }) => {
  const [loading, setLoading] = useState(false);
  const [demandObjectTypes, setDemandObjectTypes] = useState([]);
  const { user } = useContext(AuthContext);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible && demandLogId) {
      fetchObjectTypesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, demandLogId]);

  const fetchObjectTypesList = async () => {
    setLoading(true);
    try {
      const result = await getDemandObjectTypes(user.role, demandLogId);
      if (result.success) {
        setDemandObjectTypes(result.data || []);
      } else {
        message.error(result.errors.join(', '));
      }
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleAddObjectType = async () => {
    try {
      const values = await form.validateFields();
      const payload = { id: 0, objectTypeId: values.objectTypeId, demandLogId };
      const result = await createDemandObjectType(user.role, payload);
      if (result.success) {
        message.success('新增物件類型需求成功');
        form.resetFields();
        fetchObjectTypesList();
      } else {
        message.error(result.errors.join(', '));
      }
    } catch (error) {
            message.error(error.response.data || '操作失敗');
    }
  };

  const handleDeleteObjectType = async (id) => {
    try {
      const result = await deleteDemandObjectType(user.role, id);
      if (result.success) {
        message.success('刪除物件類型需求成功');
        fetchObjectTypesList();
      } else {
        message.error(result.errors.join(', '));
      }
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', width: 60 },
    {
      title: '物件類型 (ObjectTypeId)',
      dataIndex: 'objectTypeId',
      render: (val) => {
        const found = objectTypes.find((x) => x.id === val);
        return found ? found.objectTypeName : val;
      },
    },
    {
      title: '操作',
      width: 80,
      render: (_, record) => (
        <Button type="link" danger onClick={() => handleDeleteObjectType(record.id)}>
          刪除
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title="物件類型需求管理"
      visible={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      width={600}
    >
      <Form layout="inline" form={form}>
        <Form.Item
          label="物件類型"
          name="objectTypeId"
          rules={[{ required: true, message: '請選擇物件類型' }]}
        >
          <Select style={{ width: 200 }} placeholder="選擇類型">
            {objectTypes.map((ot) => (
              <Option key={ot.id} value={ot.id}>
                {ot.objectTypeName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleAddObjectType}>
            新增
          </Button>
        </Form.Item>
      </Form>
      <Table
        style={{ marginTop: 16 }}
        columns={columns}
        dataSource={demandObjectTypes}
        rowKey="id"
        loading={loading}
        pagination={false}
      />
    </Modal>
  );
};

/* --------------------- 物件媒合 Modal --------------------- */
const MatchObjectModal = ({ visible, onClose, demandLogId }) => {
  const [loading, setLoading] = useState(false);
  const [objectList, setObjectList] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (visible && demandLogId) {
      fetchMatchedObjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, demandLogId]);

  const fetchMatchedObjects = async () => {
    setLoading(true);
    try {
      const result = await getGoodObject(user.role, demandLogId);
      if (result.success) {
        setObjectList(result.data || []);
      } else {
        message.error(result.errors.join(', '));
      }
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', width: 60 },
    { title: '物件編號(ObjectNo)', dataIndex: 'objectNo' },
    { title: '物件類型ID(ObjectTypeId)', dataIndex: 'objectTypeId', width: 120 },
    { title: '分區ID(AreaId)', dataIndex: 'areaId', width: 120 },
    { title: '模糊地址(ObfuscatedAddress)', dataIndex: 'obfuscatedAddress' },
    { title: '詳細地址(DetailedAddress)', dataIndex: 'detailedAddress' },
    { title: '地圖地址(GooleMapAddress)', dataIndex: 'gooleMapAddress' },
  ];

  return (
    <Modal
      title="物件媒合結果"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={900}
      destroyOnClose
    >
      <Table
        columns={columns}
        dataSource={objectList}
        rowKey="id"
        loading={loading}
        scroll={{ x: '100%' }}
      />
    </Modal>
  );
};

/* ====================== 主組件：DemandManagementSubPage ====================== */
const DemandManagementSubPage = () => {
  // 日期與客戶篩選
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));
  const [customerId, setCustomerId] = useState(null);

  // 分頁
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  // 需求列表
  const [demands, setDemands] = useState([]);
  const [loading, setLoading] = useState(false);

  // 下拉資料
  const [customers, setCustomers] = useState([]);
  const [objectTypes, setObjectTypes] = useState([]);
  const [areas, setAreas] = useState([]);
  const [businessMans, setBusinessMans] = useState([]);

  // 新增/編輯需求 Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [form] = Form.useForm();

  // Modal for 分區需求
  const [areaModalVisible, setAreaModalVisible] = useState(false);
  const [currentDemandLogIdForArea, setCurrentDemandLogIdForArea] = useState(null);
  // Modal for 物件類型需求
  const [objectTypeModalVisible, setObjectTypeModalVisible] = useState(false);
  const [currentDemandLogIdForObjectType, setCurrentDemandLogIdForObjectType] = useState(null);
  // Modal for 物件媒合
  const [matchObjectModalVisible, setMatchObjectModalVisible] = useState(false);
  const [currentDemandLogIdForMatch, setCurrentDemandLogIdForMatch] = useState(null);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchAllOptions();
    fetchDemands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize]);

  /** 取得下拉式資料 */
  const fetchAllOptions = async () => {
    try {
      const allOptions = await getAllOptions();
      setObjectTypes(allOptions.objectTypes || []);
      setAreas(allOptions.areas || []);
      setBusinessMans(allOptions.businessMans || []);

      const custResult = await getAllCustomers(user.role);
      if (custResult.success) {
        setCustomers(custResult.data || []);
      } else {
        message.error(custResult.errors.join(', '));
      }
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    }
  };

  /** 取得需求列表 */
  const fetchDemands = async () => {
    setLoading(true);
    try {
      const params = {
        start: startDate.format('YYYY-MM-DD'),
        end: endDate.format('YYYY-MM-DD'),
        customerId: customerId || null,
        pageNumber,
        pageSize,
      };
      const result = await getAllDemands(user.role, params);
      // 假設後端回傳 { items, totalRecords }
      setDemands(result.items || []);
      setTotalCount(result.totalRecords || 0);
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  /** 篩選查詢 */
  const handleSearch = () => {
    setPageNumber(1);
    fetchDemands();
  };

  /** 分頁事件 */
  const handleTableChange = (pagination) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };

  /** 新增需求 */
  const openCreateModal = () => {
    setIsEditMode(false);
    setEditRecord(null);
    form.resetFields();
    // 設定預設值：對於必填欄位如果後端不接受 null
    // 此處您可以根據後端需求調整預設值：
    form.setFieldsValue({
      // 請依您後端物件結構設定預設值：
      demandNo: '尚未編輯',
      status: false,
      customerId: null,
      businessManId: null,
      demandType: 0, // 例如 0 代表「都可以」
      needParkingAmount: 0,
      needAreaBottom: 0,
      needAreaUpper: 0,
      budgetBottom: 0,
      budgetTop: 0,
      memo: '尚未編輯',
    });
    setIsModalVisible(true);
  };

  /** 編輯需求 */
  const openEditModal = (record) => {
    setIsEditMode(true);
    setEditRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  /** 需求 Modal 取消 */
  const handleCancelModal = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  /**
   * 需求 Modal 確定
   * 根據後端物件的設計，對不能為 null 的欄位預設給 0（數值型）或 "尚未編輯"（字串）
   */
  const handleOkModal = async () => {
    try {
      const values = await form.validateFields();
      // 為確保新增時不因 null 造成失敗，這裡以三元運算子處理預設值
      const payload = {
        demandNo: values.demandNo || '尚未編輯',
        status: values.status !== undefined ? values.status : false,
        customerId: values.customerId || 0,
        businessManId: values.businessManId || 0,
        demandType: values.demandType !== undefined ? values.demandType : 0,
        needParkingAmount: values.needParkingAmount !== undefined ? values.needParkingAmount : 0,
        needAreaBottom: values.needAreaBottom !== undefined ? values.needAreaBottom : 0,
        needAreaUpper: values.needAreaUpper !== undefined ? values.needAreaUpper : 0,
        budgetBottom: values.budgetBottom !== undefined ? values.budgetBottom : 0,
        budgetTop: values.budgetTop !== undefined ? values.budgetTop : 0,
        memo: values.memo || '尚未編輯',
      };

      if (!isEditMode) {
        const result = await createDemand(user.role, { ...payload, id: 0 });
        if (result.success) {
          message.success('新增需求成功');
          fetchDemands();
        } else {
          message.error(result.errors.join(', '));
        }
      } else {
        const result = await updateDemand(user.role, { ...payload, id: editRecord.id });
        if (result.success) {
          message.success('修改需求成功');
          fetchDemands();
        } else {
          message.error(result.errors.join(', '));
        }
      }
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error(error);
            message.error(error.response.data || '操作失敗');
    }
  };

  /* ------------------- 表格欄位設定 ------------------- */
  // 基本欄位 (主表格內顯示)
  const basicColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 60,
    },
    {
      title: '需求編號',
      dataIndex: 'demandNo',
      width: 100,
    },
    {
      title: '狀態',
      dataIndex: 'status',
      width: 80,
      render: (val) => (val ? '完成' : '處理中'),
    },
    {
      title: '操作',
      width: 320,
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => openEditModal(record)}>
            編輯
          </Button>
          <Button
            type="link"
            onClick={() => {
              setCurrentDemandLogIdForArea(record.id);
              setAreaModalVisible(true);
            }}
          >
            分區需求
          </Button>
          <Button
            type="link"
            onClick={() => {
              setCurrentDemandLogIdForObjectType(record.id);
              setObjectTypeModalVisible(true);
            }}
          >
            物件類型需求
          </Button>
          <Button
            type="link"
            onClick={() => {
              setCurrentDemandLogIdForMatch(record.id);
              setMatchObjectModalVisible(true);
            }}
          >
            物件媒合
          </Button>
        </>
      ),
    },
  ];

  // 展開列：將其他所有欄位折疊起來顯示（例如：屋主、備註等）
  const expandedRowRender = (record) => {
    return (
      <Descriptions bordered size="small" column={1} style={{ backgroundColor: '#fafafa' }}>
        <Descriptions.Item label="業務">
          {(() => {
            const found = businessMans.find((b) => b.id === record.businessManId);
            return found ? found.name : record.businessManId;
          })()}
        </Descriptions.Item>
        <Descriptions.Item label="需求車位">
          {record.needParkingAmount}
        </Descriptions.Item>
        <Descriptions.Item label="需求坪數">
          {record.needAreaBottom} ~ {record.needAreaUpper}
        </Descriptions.Item>
        <Descriptions.Item label="需求預算">
          {record.budgetBottom} ~ {record.budgetTop}
        </Descriptions.Item>
        <Descriptions.Item label="備註">{record.memo || '無'}</Descriptions.Item>
        {/* 若需要顯示更多細節欄位，可在此逐一添加 */}
      </Descriptions>
    );
  };

  return (
    <PageContainer>
      <HeaderRow justify="space-between" align="middle">
        <Col>
          <Title>需求管理</Title>
        </Col>
        <Col>
          <Row gutter={[8, 8]}>
            <Col>
              <RangePicker
                value={[startDate, endDate]}
                onChange={(dates) => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate(dayjs().startOf('month'));
                    setEndDate(dayjs().endOf('month'));
                  }
                }}
              />
            </Col>
            <Col>
              <Select
                placeholder="選擇客戶"
                style={{ width: 200 }}
                allowClear
                value={customerId || undefined}
                onChange={(val) => setCustomerId(val || null)}
              >
                {customers.map((c) => (
                  <Option key={c.id} value={c.id}>
                    {c.companyName}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Button type="primary" onClick={handleSearch} style={{ marginRight: 8 }}>
                查詢
              </Button>
              <Button type="primary" onClick={openCreateModal}>
                新增需求
              </Button>
            </Col>
          </Row>
        </Col>
      </HeaderRow>

      {/* 主表格，使用基本欄位加上展開列 */}
      <Table
        columns={basicColumns}
        dataSource={demands}
        rowKey="id"
        loading={loading}
        pagination={{
          current: pageNumber,
          pageSize,
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
        onChange={handleTableChange}
        scroll={{ x: '100%' }}
        expandable={{
          expandedRowRender,
          rowExpandable: () => true,
        }}
      />

      {/* 新增/編輯需求 Modal */}
      <Modal
        title={isEditMode ? '編輯需求' : '新增需求'}
        open={isModalVisible}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        destroyOnClose
        width={700}
        okText="確定"
        cancelText="取消"
      >
        <FormContainer>
          <Form form={form} layout="vertical">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="需求編號(DemandNo)"
                  name="demandNo"
                  rules={[{ required: true, message: '請輸入需求編號' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="需求狀態(Status)" name="status" valuePropName="checked">
                  <Checkbox>完成</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="客戶(CustomerId)"
                  name="customerId"
                  rules={[{ required: true, message: '請選擇客戶' }]}
                >
                  <Select placeholder="選擇客戶">
                    {customers.map((c) => (
                      <Option key={c.id} value={c.id}>
                        {c.companyName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="業務(BusinessManId)"
                  name="businessManId"
                  rules={[{ required: true, message: '請選擇業務' }]}
                >
                  <Select placeholder="選擇業務">
                    {businessMans.map((b) => (
                      <Option key={b.id} value={b.id}>
                        {b.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="需求類型(DemandType)"
                  name="demandType"
                  rules={[{ required: true, message: '請選擇需求類型' }]}
                >
                  <Select placeholder="選擇需求類型">
                    {Demand_Type_OPTIONS.map((opt) => (
                      <Option key={opt.value} value={opt.value}>
                        {opt.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="需求車位(NeedParkingAmount)"
                  name="needParkingAmount"
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="需要的最小坪數(NeedAreaBottom)"
                  name="needAreaBottom"
                  rules={[{ required: true, message: '請輸入需求最小坪數' }]}
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="需要的最大坪數(NeedAreaUpper)"
                  name="needAreaUpper"
                  rules={[{ required: true, message: '請輸入需求最大坪數' }]}
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="需求預算下限(BudgetBottom)"
                  name="budgetBottom"
                  rules={[{ required: true, message: '請輸入需求預算下限' }]}
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="需求預算上限(BudgetTop)"
                  name="budgetTop"
                  rules={[{ required: true, message: '請輸入需求預算上限' }]}
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item label="備註(Memo)" name="memo">
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </FormContainer>
      </Modal>

      {/* 分區需求 Modal */}
      <ManageAreaModal
        visible={areaModalVisible}
        onClose={() => setAreaModalVisible(false)}
        demandLogId={currentDemandLogIdForArea}
        areas={areas}
      />

      {/* 物件類型需求 Modal */}
      <ManageObjectTypeModal
        visible={objectTypeModalVisible}
        onClose={() => setObjectTypeModalVisible(false)}
        demandLogId={currentDemandLogIdForObjectType}
        objectTypes={objectTypes}
      />

      {/* 物件媒合 Modal */}
      <MatchObjectModal
        visible={matchObjectModalVisible}
        onClose={() => setMatchObjectModalVisible(false)}
        demandLogId={currentDemandLogIdForMatch}
      />
    </PageContainer>
  );
};

export default DemandManagementSubPage;
