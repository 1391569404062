import React, { createContext, useContext, useState } from 'react';
import { MessageModal } from '../components/MessageModal'; // 假設 MessageModal 在同一目錄
import PropTypes from 'prop-types';

const MessageContext = createContext();

export const useMessage = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
  const [messageInfo, setMessageInfo] = useState({
    visible: false,
    title: '',
    message: '',
    isError: false
  });

  const showMessage = ({ title, message, isError }) => {
    setMessageInfo({
      visible: true,
      title,
      message,
      isError
    });
  };

  const handleClose = () => {
    setMessageInfo({
      ...messageInfo,
      visible: false
    });
  };

  return (
    <MessageContext.Provider value={{ showMessage }}>
      {children}
      <MessageModal
        open={messageInfo.visible}
        onClose={handleClose}
        title={messageInfo.title}
        message={messageInfo.message}
        isError={messageInfo.isError}
        visible={messageInfo.visible}
      />
    </MessageContext.Provider>
  );
};

MessageProvider.propTypes = {
  children: PropTypes.node.isRequired
};
