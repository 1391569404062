import React, { useEffect, useState, forwardRef,useContext } from 'react';
import { Table, Button, Modal, Form, Input, Space, Row, Col, message } from 'antd';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  getAllObjectTypes,
  createObjectType,
  updateObjectType,
  deleteObjectTypes,
  sortObjectTypes,
} from '../../../api/totalApi';
import { AuthContext } from '../../../context/AuthContext';

const DraggableBodyRow = forwardRef(({ children, ...restProps }, ref) => {
  return <tr ref={ref} {...restProps}>{children}</tr>;
});

const ObjectTypesSubPage = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingObject, setEditingObject] = useState(null);
    const { user } = useContext(AuthContext);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData(user.role);
  }, []);

  // 取得所有物件類型資料
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAllObjectTypes(user.role);
      if (response.success) {
        // 根據 sort 欄位排序資料
        const sortedData = [...response.data].sort((a, b) => a.sort - b.sort);
        setDataSource(sortedData);
      } else {
        message.error(response.errorMessages.join(','));
      }
    } catch (error) {
            message.error(error.response.data || '操作失敗');
    } finally {
      setLoading(false);
    }
  };

  // 開啟新增對話框
  const handleAdd = () => {
    setEditingObject(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  // 開啟編輯對話框
  const handleEdit = (record) => {
    setEditingObject(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  // 刪除物件類型
  const handleDelete = async (id) => {
    try {
      const response = await deleteObjectTypes(user.role,id);
      if (response.success) {
        message.success(response.successMessage);
        fetchData(user.role);
      } else {
        message.error(response.errorMessages.join(','));
      }
    } catch (error) {
            message.error(error.response.data || '操作失敗');
    }
  };

  // Modal 確定送出（新增或編輯）
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      let response;

      if (editingObject) {
        // 編輯模式
        const payload = { 
          ...editingObject, 
          objectTypeName: values.objectTypeName,
          isShow: values.isShow || false
        };
        response = await updateObjectType(user.role,payload);
      } else {
        // 新增模式
        const payload = {
          id: 0,
          sort: dataSource.length + 1,
          objectTypeName: values.objectTypeName,
          isShow: values.isShow || false
        };
        response = await createObjectType(user.role,payload);
      }

      if (response.success) {
        message.success(response.successMessage);
        fetchData(user.role);
        setIsModalVisible(false);
      } else {
        message.error(response.errorMessages.join(','));
      }
    } catch (error) {
            message.error(error.response.data || '操作失敗');
    }
  };

  // Modal 取消
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // 拖曳結束後更新排序
  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedList = Array.from(dataSource);
    const [movedItem] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, movedItem);

    // 重新設定 sort 值
    const updatedList = reorderedList.map((item, index) => ({
      ...item,
      sort: index + 1,
    }));

    setDataSource(updatedList);

    try {
      const response = await sortObjectTypes(user.role,updatedList);
      if (response.success) {
        message.success(response.successMessage);
      } else {
        message.error(response.errorMessages.join(','));
      }
    } catch (error) {
            message.error(error.response.data || '操作失敗');
    }
  };

  // 定義 Table 欄位
  const columns = [
    {
      title: '拖曳',
      dataIndex: 'drag',
      key: 'drag',
      width: '5%',
      render: () => <MenuOutlined style={{ cursor: 'grab' }} />,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: '名稱',
      dataIndex: 'objectTypeName',
      key: 'objectTypeName',
      width: '25%',
    },
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      width: '10%',
    },
    {
      title: '顯示',
      dataIndex: 'isShow',
      key: 'isShow',
      width: '10%',
      render: (value) => (value ? '是' : '否'),
    },
    {
      title: '操作',
      key: 'action',
      width: '20%',
      render: (text, record) => (
        <Space>
          <Button onClick={() => handleEdit(record)}>編輯</Button>
          <Button danger onClick={() => handleDelete(record.id)}>刪除</Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <h2>物件類型管理</h2>
      <Button 
        type="primary" 
        icon={<PlusOutlined />} 
        style={{ marginBottom: 16 }} 
        onClick={handleAdd}
      >
        新增物件類型
      </Button>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <Table
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              rowKey="id"
              pagination={false}
              components={{
                body: {
                  wrapper: (props) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                      {props.children}
                      {provided.placeholder}
                    </tbody>
                  ),
                  row: ({ children, ...restProps }) => {
                    const index = restProps['data-row-key'] 
                      ? dataSource.findIndex(item => item.id === restProps['data-row-key']) 
                      : -1;
                    return (
                      <Draggable key={restProps['data-row-key']} draggableId={String(restProps['data-row-key'])} index={index}>
                        {(provided, snapshot) => (
                          <DraggableBodyRow
                            {...restProps}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? '#e6f7ff' : '#fff',
                              boxShadow: snapshot.isDragging ? '0 2px 8px rgba(0,0,0,0.15)' : 'none',
                            }}
                          >
                            {children}
                          </DraggableBodyRow>
                        )}
                      </Draggable>
                    );
                  }
                }
              }}
            />
          )}
        </Droppable>
      </DragDropContext>

      <Modal 
        title={editingObject ? '編輯物件類型' : '新增物件類型'}
        visible={isModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        destroyOnClose
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                label="名稱"
                name="objectTypeName"
                rules={[{ required: true, message: '請輸入名稱' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="是否顯示"
                name="isShow"
                valuePropName="checked"
              >
                <Input type="checkbox" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ObjectTypesSubPage;
